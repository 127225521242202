import max from "lodash/max";
import { useState } from "react";

import {
  CookingApplianceUnit,
  CookingApplianceUnitLabel,
} from "../../../__generated__/globalTypes";
import { DataStore, DataStoreProvider } from "../../../data-store";
import * as useUserInfo from "../../../data-store/useUserInfo";
import { UserInfoProvider } from "../../../data-store/useUserInfo";
import UserVisibleError from "../../../util/UserVisibleError";
import * as AddCollectionModal from "../../collections/AddCollectionModal";
import GraphQLProvider from "../../graphql/GraphQLProvider";
import { FixedOrganizationProvider } from "../../organizations/OrganizationProvider";
import { packagingComponentV2Stub } from "../../packaging/stubs";
import * as RecipeSelect from "../../recipes/RecipeSelect";
import { CookingMethod } from "../RecipeCard";
import RecipeEditor from "./RecipeEditor";
import {
  RecipeEditor_Collection as Collection,
  RecipeEditor_Recipe as Recipe,
} from "./RecipeEditor.graphql";

const stories = {
  name: "recipes/RecipeEditor",
  children: [
    {
      name: "New Recipe",
      render: () => <RecipeEditorExample />,
    },
    {
      name: "Edit Recipe",
      render: () => (
        <RecipeEditorExample
          recipe={stubRecipe({
            id: 1,
            name: "Spaghetti",
            collectionIds: [2],
            ingredients: [
              stubIngredient({
                foodClassId: 4,
                id: 1,
                name: "Spaghetti",
                quantity: 200,
                unit: "g",
              }),
            ],
            cookingPenalties: [
              {
                id: 1,
                quantity: 20,
                cookingApplianceId: cookingMethods[0].applianceId,
                energySource: cookingMethods[0].energySource,
                isPreheated: cookingMethods[0].isPreheated,
                cookingMethodName: cookingMethods[0].cookingMethodName,
                unit: cookingMethods[0].unit,
                unitLabel: cookingMethods[0].unitLabel,
              },
            ],
            hasCompleteCookingPenalties: true,
            postPreparationStoragePenalties: [
              {
                id: 1,
                daysInStorage: 2,
                storageAppliance: storageAppliances[0],
                usesRenewableElectricity: false,
              },
            ],
            hasCompletePostPreparationStoragePenalties: true,
          })}
        />
      ),
    },
  ],
};

interface RecipeEditorExampleProps {
  recipe?: Recipe;
}

const cookingMethods: CookingMethod[] = [
  {
    id: 1,
    cookingMethodName: "Kettle",
    unit: CookingApplianceUnit.USE,
    unitLabel: CookingApplianceUnitLabel.uses,
    applianceId: 1,
    energySource: "UNKNOWN",
    isPreheated: false,
  },
  {
    id: 2,
    cookingMethodName: "Gas Grill",
    unit: CookingApplianceUnit.MINUTE,
    unitLabel: CookingApplianceUnitLabel.mins,
    applianceId: 2,
    energySource: "UNKNOWN",
    isPreheated: false,
  },
  {
    id: 3,
    cookingMethodName: "Gas Hob",
    unit: CookingApplianceUnit.MINUTE,
    unitLabel: CookingApplianceUnitLabel.mins,
    applianceId: 3,
    energySource: "UNKNOWN",
    isPreheated: false,
  },
];

const packagingComponents = [
  packagingComponentV2Stub({ name: "Custom Component 1" }),
  packagingComponentV2Stub({ name: "Custom Component 2" }),
  packagingComponentV2Stub({ name: "Custom Component 3" }),
  packagingComponentV2Stub({ name: "Standard Component 1" }),
  packagingComponentV2Stub({ name: "Standard Component 2" }),
  packagingComponentV2Stub({ name: "Standard Component 3" }),
];

const storageAppliances = [
  { id: 1, name: "Fridge/Freezer (chilled)" },
  { id: 2, name: "Walk-in (chilled)" },
];

function RecipeEditorExample(props: RecipeEditorExampleProps) {
  const { recipe } = props;

  const availableIngredients = [
    { id: 1, name: "Beef", supportsEach: true, synonyms: [] },
    { id: 2, name: "Cabbage", supportsEach: true, synonyms: [] },
    { id: 3, name: "Chicken", supportsEach: true, synonyms: [] },
    { id: 4, name: "Pasta", supportsEach: true, synonyms: [] },
  ];

  const [possibleCollections, setPossibleCollections] = useState([
    stubCollection({ id: 1, name: "Breakfast" }),
    stubCollection({ id: 2, name: "Lunch" }),
    stubCollection({ id: 3, name: "Dinner" }),
  ]);

  const allSubrecipes = [
    { id: 1, name: "Baked potato" },
    { id: 2, name: "Boiled potato" },
    { id: 3, name: "Roast potato" },
  ];

  const dataStore: Partial<DataStore> = {
    fetchGraphQL: async ({ query, variables }) => {
      if (query === AddCollectionModal.addRecipeCollectionMutation) {
        const { name } = (variables as any).input;
        const collection = stubCollection({
          id: max(possibleCollections.map((collection) => collection.id))!! + 1,
          name,
        });

        setPossibleCollections([...possibleCollections, collection]);

        return {
          addRecipeCollection: {
            recipeCollection: collection,
            success: true,
          },
        } as any;
      } else if (query === RecipeSelect.query) {
        const matchingRecipes = allSubrecipes.filter((recipe) =>
          recipe.name
            .toLowerCase()
            .includes((variables as any).recipeFilter.search.toLowerCase())
        );
        return {
          recipes: {
            edges: matchingRecipes.map((subrecipe) => ({
              node: subrecipe,
            })),
          },
        };
      } else if (query === useUserInfo.viewerQuery()) {
        return {
          viewer: {
            hasRecipeCollectionPermissionAdd: true,
            featureFlags: [],
            id: 1,
            organizationMemberships: [
              {
                organization: {
                  id: "ORG",
                  features: {
                    accurateCookingImpacts: true,
                    accuratePostPreparationStorageImpacts: true,
                  },
                },
              },
            ],
          },
        };
      } else {
        throw new UserVisibleError("not supported");
      }
    },
  };

  return (
    <DataStoreProvider value={dataStore as DataStore}>
      <GraphQLProvider>
        <UserInfoProvider>
          <FixedOrganizationProvider value={["ORG", () => null]}>
            <RecipeEditor
              availableIngredients={availableIngredients}
              cookingMethods={cookingMethods}
              packagingComponents={packagingComponents}
              recipe={recipe}
              storageAppliances={storageAppliances}
              onDiscard={() => alert("discard")}
              onSave={async (newRecipe) =>
                alert(JSON.stringify(newRecipe, undefined, 4))
              }
              possibleCollections={possibleCollections}
              refreshPossibleCollections={async () => {}}
            />
          </FixedOrganizationProvider>
        </UserInfoProvider>
      </GraphQLProvider>
    </DataStoreProvider>
  );
}

function stubCollection({
  id,
  name,
}: {
  id: number;
  name: string;
}): Collection {
  return { viewerHasPermissionUpdate: true, id, name };
}

function stubIngredient({
  foodClassId,
  id,
  name,
  quantity,
  unit,
}: {
  foodClassId: number;
  id: number;
  name: string;
  quantity: number;
  unit: string;
}): Recipe["ingredients"][0] {
  return {
    id,
    name,
    quantity,
    unit,
    foodClassId,
    useRecipe: null,
  };
}

function stubRecipe({
  code = null,
  id,
  name,
  collectionIds,
  ingredients,
  cookingPenalties,
  hasCompleteCookingPenalties,
  postPreparationStoragePenalties,
  hasCompletePostPreparationStoragePenalties,
}: {
  code?: string | null;
  id: number;
  name: string;
  collectionIds: Array<number>;
  ingredients: Array<Recipe["ingredients"][0]>;
  cookingPenalties: Array<Recipe["cookingPenalties"][0]>;
  hasCompleteCookingPenalties: boolean;
  postPreparationStoragePenalties: Array<
    Recipe["postPreparationStoragePenalties"][0]
  >;
  hasCompletePostPreparationStoragePenalties: boolean;
}): Recipe {
  return {
    code,
    id,
    isHotDrink: false,
    name,
    ingredients,
    collections: collectionIds.map((collectionId) => ({ id: collectionId })),
    cookingPenalties,
    hasCompleteCookingPenalties,
    packagingComponentsV2: [],
    postPreparationStoragePenalties,
    hasCompletePostPreparationStoragePenalties,
    serves: 2,
    servingsPerCookingBatch: null,
  };
}

export default stories;
