import { useCallbackOne } from "use-memo-one";

import { useDashboardOrganization } from "../components/dashboard/DashboardOrganizationProvider";
import usePromise from "../components/utils/usePromise";
import { useDataStore } from "./index";

export default function useProductsDashboardData() {
  const dataStore = useDataStore();
  const [dashboardOrganization] = useDashboardOrganization();

  const load = useCallbackOne(async () => {
    return await dataStore.fetchProductsDashboardData(dashboardOrganization.id);
  }, [dataStore, dashboardOrganization]);

  return usePromise(load);
}
