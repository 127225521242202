import { useIntl } from "react-intl";

import assertNever from "../../util/assertNever";
import InlineTitleToggle from "../utils/InlineTitleToggle";

export enum Option {
  CATEGORY = "CATEGORY",
  FOOD_TYPE = "FOOD_TYPE",
  PROCURED_ITEM = "PROCURED_ITEM",
  SUPPLIER = "SUPPLIER",
}

export type TableOption =
  | Option.CATEGORY
  | Option.PROCURED_ITEM
  | Option.SUPPLIER
  | Option.FOOD_TYPE;

export const allTableOptions: Array<TableOption> = [
  Option.CATEGORY,
  Option.PROCURED_ITEM,
  Option.SUPPLIER,
  Option.FOOD_TYPE,
];

interface TableOptionToggleProps {
  option: TableOption;
  options?: Array<TableOption>;
  onChange: (option: TableOption) => void;
}

export default function TableOptionToggle(props: TableOptionToggleProps) {
  const { option, options = allTableOptions, onChange } = props;

  const intl = useIntl();

  const renderOption = (option: TableOption) => {
    if (option === Option.CATEGORY) {
      return intl.formatMessage({
        id: "components/scope-3/TableOptionToggle/category",
        defaultMessage: "Category",
      });
    } else if (option === Option.FOOD_TYPE) {
      return intl.formatMessage({
        id: "components/scope-3/TableOptionToggle/foodType",
        defaultMessage: "Food Type",
      });
    } else if (option === Option.PROCURED_ITEM) {
      return intl.formatMessage({
        id: "components/scope-3/TableOptionToggle/procuredItem",
        defaultMessage: "Procured Item",
      });
    } else if (option === Option.SUPPLIER) {
      return intl.formatMessage({
        id: "components/scope-3/TableOptionToggle/supplier",
        defaultMessage: "Supplier",
      });
    } else {
      assertNever(option, "Unsupported table type");
    }
  };

  return (
    <InlineTitleToggle<TableOption>
      options={options}
      onChange={onChange}
      selectedOption={option}
      renderOption={renderOption}
    />
  );
}
