import { sum } from "lodash";
import { Bar as BarChart } from "react-chartjs-2";
import { useIntl } from "react-intl";

import * as comparators from "../../util/comparators";
import {
  darkGreyCol,
  foodstepsTurquoiseCol,
  gridLineGreyCol,
  midGreyCol,
} from "./colors";

const fontSize = 12;
const maxLabelCharacters = 10;
const truncateLongLabelsAfterNumberOfBars = 10;

export interface Bar {
  label: string;
  value: Array<number | null>;
  color?: string;
}

interface BarChart2Props {
  bars: Array<Bar>;
  dependentAxisLabel: string;
  height?: number | string;
  horizontal?: boolean;
  maxBars?: number;
  hideFinalBar?: boolean;
  sorted?: boolean;
  maintainAspectRatio?: boolean;
  truncateLabels?: boolean;
}

export default function BarChart2(props: BarChart2Props) {
  const {
    bars,
    dependentAxisLabel,
    height,
    horizontal = false,
    maxBars,
    hideFinalBar = false,
    sorted = false,
    maintainAspectRatio = true,
    truncateLabels = true,
  } = props;

  const intl = useIntl();

  let formattedBars = bars;

  if (sorted) {
    formattedBars.sort(
      comparators.map(
        (bar) => sum(bar.value),
        comparators.reversed(comparators.number)
      )
    );
  }

  if (maxBars !== undefined && formattedBars.length > maxBars) {
    const fullBars = formattedBars.slice(0, maxBars);
    const finalBarValue = hideFinalBar
      ? [null]
      : formattedBars.slice(maxBars).flatMap((bar) => bar.value);
    const finalBarLabel = intl.formatMessage(
      {
        id: "components/frontend/src/components/graphs/BarChart2:nMore",
        defaultMessage: "{n} more",
      },
      { n: formattedBars.length - maxBars }
    );
    formattedBars = [
      ...fullBars,
      { label: finalBarLabel, value: finalBarValue },
    ];
  }

  // This is a string identifier that is used to stack data. It can be any string.
  const stack = "stack";

  const maxStackDepth = Math.max(
    ...formattedBars.map((bar: Bar) => {
      if (typeof bar.value === "number") {
        return 0;
      }
      return bar.value.length;
    })
  );

  let datasets = [];

  // Stacks in chart.js are made up of datasets. Each stack layer is a dataset.
  // So to achieve the stacking effect we want, we add the layers to each bar one by one.
  for (let i = 0; i < maxStackDepth; i++) {
    datasets.push({
      data: formattedBars.map((bar) => {
        if (bar.value.length < i) {
          return null;
        }
        return bar.value[i];
      }),
      stack,
      backgroundColor: formattedBars.map(
        (bar) => bar.color ?? foodstepsTurquoiseCol
      ),
    });
  }

  const labels = formattedBars.map((bar) => bar.label);

  const data = {
    labels,
    datasets,
  };

  const indexAxis = horizontal ? ("y" as const) : ("x" as const);

  const dependentAxisOptions = {
    border: { display: false },
    title: {
      color: darkGreyCol,
      display: true,
      text: dependentAxisLabel,
      font: { size: fontSize },
    },
    grid: { drawTicks: false, color: gridLineGreyCol },
    ticks: { color: midGreyCol, font: { size: fontSize } },
  };

  const formatLabel = (label: string) =>
    truncateLabels &&
    label.length > maxLabelCharacters &&
    bars.length > truncateLongLabelsAfterNumberOfBars
      ? `${label.substring(0, maxLabelCharacters)}...`
      : label;

  const independentAxisOptions = {
    grid: { display: false },
    ticks: {
      font: { size: fontSize },
      callback: (value: number | string, index: number) =>
        formatLabel(labels[index]),
    },
  };

  const scales = {
    x: {
      ...(horizontal ? dependentAxisOptions : independentAxisOptions),
    },
    y: {
      ...(horizontal ? independentAxisOptions : dependentAxisOptions),
    },
  };

  const options = {
    maintainAspectRatio,
    borderRadius: 4,
    borderColor: "white",
    borderWidth: 2,
    barPercentage: 1.0,
    indexAxis,
    maxBarThickness: 24,
    plugins: {
      datalabels: {
        display: false,
      },
    },
    scales,
    skipNull: true,
  };

  // Chart js can't figure out that the container has padding on it, so we need
  // to wrap it in a div so that it auto-scales to this size, but the div respects the padding
  return (
    <div>
      <BarChart height={height} data={data} options={options} />
    </div>
  );
}
