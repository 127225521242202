import { ImpactCategory } from "../../domain/impactCategories";
import { ImpactRating } from "../../domain/impactRatings";
import assertNever from "../../util/assertNever";
import CarbonLabel from "../labels/carbon-label/CarbonLabel";
import { WaterUse } from "../utils/Vectors/illustrations";
import LetterRating from "../utils/Vectors/labels/LetterRating";

export function impactIntensityLetterRatingLabel(
  impactCategory: ImpactCategory,
  impactRating: ImpactRating
) {
  if (impactCategory === ImpactCategory.GHG) {
    return (
      <CarbonLabel
        impactRating={impactRating}
        size="small"
        type="letterRating"
        width={46}
      />
    );
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    return (
      <LetterRating
        impactCategory={impactCategory}
        impactRating={impactRating}
        width={46}
      />
    );
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    return <WaterUse width={32} />;
  } else {
    assertNever(impactCategory, "Unsupported impact category");
  }
}

export function averageIntensity(
  impact: {
    averageIntensityGhg: number | null;
    averageIntensityLandUse: number | null;
    averageIntensityWaterUse: number | null;
  },
  impactCategory: ImpactCategory
) {
  if (impactCategory === ImpactCategory.GHG) {
    return impact.averageIntensityGhg;
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    return impact.averageIntensityLandUse;
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    return impact.averageIntensityWaterUse;
  } else {
    assertNever(impactCategory, "Unexpected impact category");
  }
}
