import classNames from "classnames";
import { FormattedMessage } from "react-intl";

import { SecondaryButton } from "../utils/Button";
import { Close, FilledCircle, Filter } from "../utils/Vectors";

interface FilterButtonProps {
  className?: string;
  currentNumberOfFilters: number;
  handleClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  menuIsOpen: boolean;
  onRemoveAppliedFiltersClick: () => void;
  removeAppliedFiltersRef: React.RefObject<HTMLDivElement>;
}

export default function FilterButton(props: FilterButtonProps) {
  const {
    className = "",
    currentNumberOfFilters,
    handleClick,
    menuIsOpen,
    onRemoveAppliedFiltersClick,
    removeAppliedFiltersRef,
  } = props;

  return (
    <SecondaryButton
      className={classNames("ProductFilterButton", className, {
        ProductFilterButton__MenuOpen: menuIsOpen,
        ProductFilterButton__Filtered: currentNumberOfFilters > 0,
      })}
      onClick={(e) => handleClick(e)}
    >
      <Filter width={20} className="filter-icon" />
      <FormattedMessage
        id="components/product-filter/ProductFilterSelect:FilterButton"
        defaultMessage="Filter"
      />
      {currentNumberOfFilters > 0 && (
        <>
          <FilledCircle
            width="24px"
            fill="var(--accent-yellow)"
            className="number-of-filters"
            textColor="black"
            content={currentNumberOfFilters}
          />
          <div
            ref={removeAppliedFiltersRef}
            onClick={onRemoveAppliedFiltersClick}
            className="CloseButton"
          >
            <Close width={16} />
          </div>
        </>
      )}
    </SecondaryButton>
  );
}
