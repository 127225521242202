import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { ProcuredItem } from "../../data-store";
import { impactCategoryToEffectType } from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import { useImpactRatingToLongName } from "../../domain/impactRatings";
import ImpactRatingDisplay from "../impacts/ImpactRatingDisplay";
import { buildPath, usePages } from "../pages";
import { impactMagnitude, impactRating } from "../scope-3/helperFunctions";
import { ArrowRight } from "../utils/Vectors";
import { DashboardCard } from "./DashboardCard";
import "./HighestImpactProcuredItems.css";
import { useImpactCategoryUrl } from "./helperFunctions";
interface HighestImpactProcuredItemsProps {
  assessmentId: string;
  impactCategory: ImpactCategory;
  items: Array<Item>;
}

export function HighestImpactProcuredItems(
  props: HighestImpactProcuredItemsProps
) {
  const { assessmentId, impactCategory, items } = props;
  return (
    <DashboardCard className="AssessmentOverviewChartCard">
      <h4 className="m-0">
        <FormattedMessage
          id="components/corporate-reporting-dashboards/HighestImpactProcuredItems:title"
          defaultMessage="Highest Impact Procured Items"
        />
      </h4>
      <ItemImpactsDisplay
        assessmentId={assessmentId}
        impactCategory={impactCategory}
        items={items}
      />
    </DashboardCard>
  );
}

interface ItemImpactsDisplayProps {
  assessmentId: string;
  impactCategory: ImpactCategory;
  items: Array<Item>;
}

function ItemImpactsDisplay(props: ItemImpactsDisplayProps) {
  const { assessmentId, impactCategory, items } = props;

  const impactCategoryUrl = useImpactCategoryUrl(impactCategory);
  const pages = usePages();

  const path = buildPath(
    impactCategoryUrl,
    pages.Scope3Page.stringifyQueryParams({
      assessmentId,
      viewHighestImpactItems: true,
    })
  );

  return (
    <div className="ItemImpactsDisplay">
      {items.map((item) => (
        <div key={`item-impact-card-${item.index}`}>
          <ItemImpactCard impactCategory={impactCategory} item={item} />
        </div>
      ))}
      <Link to={path} className="p-0">
        <div className="ViewMoreButtonContainer">
          <FormattedMessage
            id="components/corporate-reporting-dashboards/ItemImpactsDisplay:viewMoreButton"
            defaultMessage="View more"
          />
          <ArrowRight width={16} />
        </div>
      </Link>
    </div>
  );
}

type Item = ProcuredItem & {
  index: number;
};

interface ItemImpactCardProps {
  impactCategory: ImpactCategory;
  item: Item;
}

function ItemImpactCard(props: ItemImpactCardProps) {
  const { impactCategory, item } = props;
  const itemImpactMagnitude = impactMagnitude(item, impactCategory);
  const itemImpactMagnitudeInLargeUnits =
    itemImpactMagnitude === null
      ? null
      : itemImpactMagnitude /
        impactCategoryToEffectType(impactCategory).largeUnitConversionFactor;
  const itemImpactRating = impactRating(item, impactCategory);
  const impactRatingToLongName = useImpactRatingToLongName;
  return (
    <div className="ItemImpactCard">
      <div className="NameAndRank medium-font">
        <div className="Rank">
          <>{item.index}#</>
        </div>
        <div className="Name">{item.name}</div>
      </div>
      <div className="NumberAndRatingLabel">
        {itemImpactMagnitudeInLargeUnits != null && (
          <ImpactCardTotalImpact
            impactCategory={impactCategory}
            impactValue={itemImpactMagnitudeInLargeUnits}
          />
        )}
        {itemImpactRating !== null && (
          <div className="ImpactRatingDisplayContainer">
            <ImpactRatingDisplay
              value={itemImpactRating}
              text={impactRatingToLongName(itemImpactRating)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

interface ImpactCardTotalImpactProps {
  impactCategory: ImpactCategory;
  impactValue: number;
}

function ImpactCardTotalImpact(props: ImpactCardTotalImpactProps) {
  const { impactCategory, impactValue } = props;
  return (
    <div className="ImpactCardTotalImpact">
      <h4 className="m-0">{impactValue.toFixed(1)}</h4>
      <div className="ImpactCardTotalImpactUnit">
        {impactCategoryToEffectType(impactCategory).largeUnit}
      </div>
    </div>
  );
}
