import { FormattedMessage, useIntl } from "react-intl";

import { AssessmentTotals } from "../../data-store";
import useAssessmentTotals from "../../data-store/useAssessmentTotals";
import { impactCategoryToEffectType } from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import BarChart2 from "../graphs/BarChart2";
import { impactMagnitude } from "../scope-3/helperFunctions";
import StatusDisplay from "../StatusDisplay";
import { DashboardCard } from "./DashboardCard";
import "./ImpactByAssessmentChartCard.css";
interface ImpactByAssessmentChartCardProps {
  impactCategory: ImpactCategory;
  assessmentIdToColourMap: Map<string, string>;
}

export function ImpactByAssessmentChartCard(
  props: ImpactByAssessmentChartCardProps
) {
  const { assessmentIdToColourMap, impactCategory } = props;

  const [assessmentTotalsStatus] = useAssessmentTotals();
  const intl = useIntl();

  const effectType = impactCategoryToEffectType(impactCategory);

  const impact = (assessmentTotals: AssessmentTotals): number | null => {
    const totalImpact = impactMagnitude(assessmentTotals, impactCategory);
    return totalImpact === null
      ? null
      : totalImpact / effectType.largeUnitConversionFactor;
  };

  const bars = (allAssessmentTotals: Array<AssessmentTotals>) => {
    return allAssessmentTotals
      .filter((total) => assessmentIdToColourMap.has(total.assessmentId))
      .map((assessmentTotals) => {
        return {
          label: assessmentTotals.name,
          value: [impact(assessmentTotals)],
          color: assessmentIdToColourMap.get(assessmentTotals.assessmentId)!,
        };
      });
  };

  const dependentAxisLabel = `${effectType.title(
    intl
  )} ${effectType.largeUnitString(intl)}`;

  return (
    <DashboardCard className="ImpactByAssessmentChartCard">
      <h3>
        <FormattedMessage
          id="components/corporate-reporting-dashboards/ImpactByAssessmentChartCard:title"
          defaultMessage="Total Impact by Assessment"
        />
      </h3>
      <StatusDisplay status={assessmentTotalsStatus}>
        {(assessmentTotals) => {
          return (
            <BarChart2
              bars={bars(assessmentTotals)}
              dependentAxisLabel={dependentAxisLabel}
              horizontal
              height={170}
              maintainAspectRatio={false}
            />
          );
        }}
      </StatusDisplay>
    </DashboardCard>
  );
}
