import { useIntl } from "react-intl";

import assertNever from "../util/assertNever";

export enum ImpactCategory {
  GHG = "GHG",
  LAND_USE = "LAND_USE",
  WATER_USE = "WATER_USE",
}

export type LabelImpactCategory = ImpactCategory.GHG | ImpactCategory.LAND_USE;

export function useImpactCategoryLabel(impactCategory: ImpactCategory): string {
  const intl = useIntl();

  if (impactCategory === ImpactCategory.GHG) {
    return intl.formatMessage({
      defaultMessage: "GHG",
      id: "domain/impactCategories:ghg",
    });
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    return intl.formatMessage({
      defaultMessage: "Land use",
      id: "domain/impactCategories:landUse",
    });
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    return intl.formatMessage({
      defaultMessage: "Water use",
      id: "domain/impactCategories:waterUse",
    });
  } else {
    assertNever(impactCategory, "Unsupported impact category.");
  }
}
