import VectorProps from "../VectorProps";

export default function Products(props: VectorProps) {
  const { className = "", handleClick = () => null, height, width } = props;
  // from https://www.figma.com/design/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Design-Library?node-id=3271-4085&node-type=frame&t=gxcTnZqmjlcISXIg-0
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height ?? width}
      viewBox="0 0 32 32"
      fill="none"
      onClick={handleClick}
    >
      <g clipPath="url(#clip0_3425_3997)">
        <path
          d="M10.72 21.28C16.5079 21.28 21.2 16.588 21.2 10.8C21.2 5.01206 16.5079 0.320007 10.72 0.320007C4.93205 0.320007 0.23999 5.01206 0.23999 10.8C0.23999 16.588 4.93205 21.28 10.72 21.28Z"
          fill="#19DEBF"
        />
        <path d="M29.76 6.43039V31.04H22.6624" fill="#00A68F" />
        <path
          d="M29.7601 31.92H22.6625C22.1761 31.92 21.7825 31.5264 21.7825 31.04C21.7825 30.5536 22.1761 30.16 22.6625 30.16H28.8801V6.43038C28.8801 5.94398 29.2737 5.55038 29.7601 5.55038C30.2465 5.55038 30.6401 5.94398 30.6401 6.43038V31.04C30.6401 31.5264 30.2465 31.92 29.7601 31.92Z"
          fill="black"
        />
        <path
          d="M23.1424 31.04L26.2832 19.2448L30.96 1.67999H17.0192L14.448 11.3376"
          fill="#00A68F"
        />
        <path
          d="M23.144 31.92C23.0688 31.92 22.9936 31.9104 22.9168 31.8896C22.4464 31.7648 22.168 31.2832 22.2928 30.8128L29.8144 2.55999H17.696L15.2992 11.5632C15.1744 12.0336 14.6928 12.312 14.2224 12.1872C13.752 12.0624 13.4736 11.5808 13.5984 11.1104L16.1696 1.45279C16.272 1.06719 16.6208 0.799988 17.0192 0.799988H30.96C31.2336 0.799988 31.4912 0.926388 31.6576 1.14399C31.824 1.35999 31.88 1.64319 31.8096 1.90719L23.992 31.2672C23.8864 31.6608 23.5312 31.9216 23.1424 31.9216L23.144 31.92Z"
          fill="black"
        />
        <path
          d="M17.48 12.3616L19.5136 4.78879H26.7344L23.784 15.7712L22.6624 21.0048L17.48 21.5952L16 16.6416L17.48 12.3616Z"
          fill="#DCFFD1"
        />
        <path
          d="M17.4799 22.4752C17.0959 22.4752 16.7503 22.2224 16.6367 21.8464L15.1567 16.8928C15.1039 16.7168 15.1087 16.528 15.1679 16.3536L16.6383 12.1024L18.6639 4.5616C18.7679 4.1776 19.1151 3.9104 19.5135 3.9104H26.7343C27.0079 3.9104 27.2655 4.0384 27.4319 4.2544C27.5983 4.472 27.6543 4.7536 27.5839 5.0176L24.6399 15.9776L23.5231 21.1888C23.4447 21.5584 23.1375 21.8368 22.7615 21.8784L17.5791 22.4688C17.5455 22.472 17.5119 22.4752 17.4783 22.4752H17.4799ZM16.9247 16.6624L18.1135 20.6384L21.9359 20.2032L22.9247 15.5872C22.9279 15.5728 22.9311 15.5584 22.9359 15.544L25.5871 5.6704H20.1887L18.3295 12.5904C18.3247 12.6096 18.3183 12.6304 18.3119 12.6496L16.9247 16.6624Z"
          fill="black"
        />
        <path
          d="M24.7935 18.728C24.8063 16.8816 23.3903 15.2768 21.5551 15.0656C20.9071 14.9904 20.2847 15.088 19.7279 15.3184C19.2895 15.4992 18.8111 15.2512 18.6879 14.7936C18.6687 14.7216 18.6335 14.6592 18.6095 14.5968C18.3567 13.9376 18.2719 13.6224 17.9711 13.216C17.3263 12.3408 16.3087 11.7504 15.1375 11.6864C13.3727 11.5904 11.8287 12.7248 11.3311 14.3408C11.2255 14.6832 10.9023 14.912 10.5439 14.8928H10.5375C9.61912 14.8432 8.76632 15.1584 8.11832 15.7104C7.89592 15.8992 7.58232 15.9376 7.31832 15.8112C6.97272 15.6448 6.59032 15.5424 6.18392 15.52C4.40472 15.424 2.90232 16.8864 3.00632 18.7168C3.01592 18.88 3.03832 19.0416 3.07352 19.1984"
          fill="#DCFFD1"
        />
        <path
          d="M3.0736 20.0768C2.672 20.0768 2.3088 19.8 2.216 19.3904C2.1696 19.1856 2.1408 18.9744 2.128 18.7648C2.0656 17.6512 2.4784 16.5632 3.2608 15.7792C4.0432 14.9952 5.1264 14.5792 6.2336 14.64C6.7168 14.6656 7.1824 14.7808 7.6208 14.9792C8.44 14.312 9.4608 13.9696 10.5152 14.0096C11.168 12.0064 13.0784 10.6928 15.1888 10.808C16.5808 10.8832 17.8544 11.5712 18.6816 12.6928C19 13.1248 19.1376 13.4928 19.312 13.9584C19.3488 14.056 19.3888 14.1632 19.4336 14.28L19.4432 14.3008C19.4608 14.3408 19.4848 14.392 19.5072 14.4576C20.1872 14.1984 20.928 14.1056 21.6576 14.1888C23.9264 14.4512 25.6912 16.4464 25.6752 18.7312C25.672 19.2144 25.2784 19.6048 24.7952 19.6048C24.7936 19.6048 24.7904 19.6048 24.7888 19.6048C24.3024 19.6016 23.912 19.2048 23.9152 18.7184C23.9248 17.3184 22.8448 16.0976 21.456 15.936C20.9744 15.88 20.5072 15.9456 20.0672 16.128C19.6288 16.3104 19.1344 16.2944 18.7104 16.0864C18.2896 15.8784 17.976 15.4992 17.8464 15.0416L17.8384 15.0224C17.8208 14.984 17.8048 14.9472 17.7904 14.9104C17.7424 14.7872 17.7008 14.6752 17.6624 14.5728C17.5072 14.1584 17.4368 13.9696 17.2656 13.736C16.7504 13.0384 15.9584 12.6096 15.0928 12.5632C13.7664 12.4912 12.5664 13.328 12.1744 14.5968C11.9488 15.328 11.2592 15.8112 10.4976 15.768C9.8336 15.7328 9.1936 15.9488 8.6912 16.3776C8.2064 16.7904 7.5184 16.8784 6.9408 16.6016C6.6896 16.48 6.4192 16.4112 6.1392 16.3968C5.5328 16.3632 4.9376 16.592 4.5088 17.0224C4.0736 17.4592 3.8528 18.0416 3.888 18.6656C3.8944 18.7792 3.9104 18.8928 3.936 19.0032C4.0432 19.4768 3.7456 19.9488 3.272 20.056C3.2064 20.0704 3.1424 20.0784 3.0768 20.0784L3.0736 20.0768Z"
          fill="black"
        />
        <path
          d="M20.7233 31.04H7.27207C5.00967 31.04 3.07847 29.4064 2.70247 27.1744L0.886475 19.3488H26.2097L24.3937 27.1744C24.0193 29.4064 22.9841 31.04 20.7217 31.04H20.7233Z"
          fill="#10675B"
        />
        <path
          d="M20.7232 31.92H7.27195C4.57915 31.92 2.29915 29.9984 1.83995 27.3472L0.0303544 19.5472C-0.0304456 19.2864 0.0303544 19.0112 0.198354 18.8016C0.364754 18.592 0.619154 18.4688 0.887954 18.4688H26.2112C26.48 18.4688 26.7328 18.592 26.9008 18.8016C27.0672 19.0112 27.1296 19.2864 27.0688 19.5472L25.2592 27.3472C24.6144 31.1264 22.4384 31.92 20.7232 31.92ZM1.99515 20.2288L3.55995 26.976C3.56475 26.9936 3.56795 27.0112 3.57115 27.0288C3.87515 28.8432 5.43195 30.16 7.27195 30.16H20.7216C21.6992 30.16 23.0544 29.8352 23.5264 27.0288C23.5296 27.0112 23.5328 26.9936 23.5376 26.976L25.1024 20.2288H1.99515Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_3425_3997">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
