import { ImpactRating } from "../../../../__generated__/globalTypes";
import { ImpactCategory } from "../../../../domain/impactCategories";
import { useImpactRatingToColor } from "../../../../domain/impactRatings";
import { graphikSemiboldWebCssString } from "../../../../fonts/graphik-web/graphikSemiboldWebCssString";
import {
  brightTurquoise,
  darkTurquoise,
  foodstepsTurquoise,
  mint,
} from "../../../graphs/colors";
import { impactRatingLetterColor } from "../../impactRatingLetterColor";
import {
  impactRatingToLetter,
  impactRatingToName,
  impactCategoryTitle,
} from "./translations";

interface DescriptiveRatingScaleProps {
  greyscale?: boolean;
  impactCategory: ImpactCategory;
  impactRating: ImpactRating;
  locale: string | null;
  width: number;
}

export default function DescriptiveRatingScale(
  props: DescriptiveRatingScaleProps
) {
  const {
    greyscale = false,
    impactCategory,
    impactRating,
    locale,
    width,
  } = props;

  return (
    <svg
      width={width}
      viewBox="0 0 98 158"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>{graphikSemiboldWebCssString}</style>
      <rect
        x="1"
        y="27"
        width="96"
        height="130"
        rx="9"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M2 36C2 31.5817 5.58172 28 10 28H88C92.4183 28 96 31.5817 96 36V92H2V36Z"
        fill="white"
      />
      <text
        x={49}
        y={64}
        textAnchor="middle"
        fontFamily="Graphik Web"
        fontWeight={600}
        fontSize={impactRatingFontSize(impactRating, locale)}
        fill="black"
      >
        {impactRatingToName(impactRating, locale)}
      </text>
      <text
        x={49}
        y={82}
        textAnchor="middle"
        fontFamily="Graphik Web"
        fontWeight={500}
        fontSize={impactCategoryTitleFontSize(impactCategory, locale)}
        fill="black"
      >
        {impactCategoryTitle(impactCategory, locale)}
      </text>
      <rect width="94" height="40" transform="translate(2 92)" fill="white" />
      <ImpactRatingScale greyscale={greyscale} impactRating={impactRating} />
      <path
        d="M2 132H96V148C96 152.418 92.4183 156 88 156H10C5.58172 156 2 152.418 2 148V132Z"
        fill={foodstepsTurquoise(greyscale)}
      />
      <path
        d="M12.9453 147.672H15.0639V144.05H18.1372V142.454H15.0639V139.983H18.948V138.322H12.9453V147.672Z"
        fill="white"
      />
      <path
        d="M23.0543 146.378C21.9819 146.378 21.3803 145.593 21.3803 144.285V144.18C21.3803 142.873 22.0081 142.114 23.0543 142.114C24.1136 142.114 24.7282 142.899 24.7282 144.207V144.298C24.7282 145.593 24.1136 146.378 23.0543 146.378ZM23.0412 147.816C25.0944 147.816 26.6506 146.443 26.6506 144.285V144.18C26.6506 142.049 25.1075 140.676 23.0543 140.676C21.0011 140.676 19.4448 142.075 19.4448 144.22V144.324C19.4448 146.456 21.0011 147.816 23.0412 147.816Z"
        fill="white"
      />
      <path
        d="M31.1129 146.378C30.0405 146.378 29.4389 145.593 29.4389 144.285V144.18C29.4389 142.873 30.0667 142.114 31.1129 142.114C32.1722 142.114 32.7868 142.899 32.7868 144.207V144.298C32.7868 145.593 32.1722 146.378 31.1129 146.378ZM31.0998 147.816C33.153 147.816 34.7092 146.443 34.7092 144.285V144.18C34.7092 142.049 33.1661 140.676 31.1129 140.676C29.0597 140.676 27.5034 142.075 27.5034 144.22V144.324C27.5034 146.456 29.0597 147.816 31.0998 147.816Z"
        fill="white"
      />
      <path
        d="M38.5438 147.816C39.5508 147.816 40.3877 147.228 40.7408 146.534V147.672H42.624V137.733H40.7408V141.853C40.3616 141.16 39.6815 140.676 38.583 140.676C36.8698 140.676 35.5751 141.996 35.5751 144.246V144.35C35.5751 146.626 36.8829 147.816 38.5438 147.816ZM39.1061 146.338C38.1645 146.338 37.5106 145.671 37.5106 144.311V144.207C37.5106 142.847 38.0991 142.127 39.1584 142.127C40.1916 142.127 40.7931 142.82 40.7931 144.18V144.285C40.7931 145.671 40.1 146.338 39.1061 146.338Z"
        fill="white"
      />
      <path
        d="M46.7921 147.816C48.5968 147.816 49.6692 147.044 49.6692 145.567C49.6692 144.102 48.7276 143.67 47.0144 143.422C46.0205 143.278 45.6936 143.108 45.6936 142.677C45.6936 142.258 46.0467 141.97 46.6613 141.97C47.3152 141.97 47.6291 142.232 47.7337 142.794H49.473C49.303 141.225 48.1653 140.676 46.6483 140.676C45.2489 140.676 43.9412 141.382 43.9412 142.807C43.9412 144.141 44.6474 144.664 46.4652 144.939C47.446 145.083 47.8514 145.279 47.8514 145.75C47.8514 146.221 47.5114 146.495 46.779 146.495C45.9551 146.495 45.6544 146.142 45.5759 145.527H43.8104C43.8758 147.005 44.9482 147.816 46.7921 147.816Z"
        fill="white"
      />
      <path
        d="M53.3696 147.803C53.8796 147.803 54.2589 147.711 54.5204 147.62V146.155C54.2981 146.247 54.0889 146.286 53.8011 146.286C53.3173 146.286 53.0426 146.024 53.0426 145.475V142.18H54.4812V140.833H53.0426V139.355H51.1595V140.833H50.2832V142.18H51.1595V145.645C51.1595 147.071 51.931 147.803 53.3696 147.803Z"
        fill="white"
      />
      <path
        d="M58.9531 147.816C60.8232 147.816 62.0002 146.992 62.2226 145.514H60.4309C60.3132 146.09 59.8817 146.469 59.0055 146.469C57.9723 146.469 57.3577 145.815 57.3054 144.677H62.2356V144.154C62.2356 141.709 60.6663 140.676 58.9008 140.676C56.913 140.676 55.3829 142.075 55.3829 144.233V144.337C55.3829 146.521 56.8869 147.816 58.9531 147.816ZM57.3315 143.513C57.4754 142.546 58.0508 141.983 58.9008 141.983C59.8032 141.983 60.3263 142.48 60.3917 143.513H57.3315Z"
        fill="white"
      />
      <path
        d="M63.4769 150H65.3732V146.626C65.7263 147.293 66.4978 147.816 67.5571 147.816C69.2572 147.816 70.5389 146.548 70.5389 144.285V144.18C70.5389 141.918 69.2311 140.676 67.5571 140.676C66.5502 140.676 65.7524 141.251 65.3732 141.905V140.833H63.4769V150ZM66.9948 146.338C65.9617 146.338 65.3078 145.671 65.3078 144.298V144.194C65.3078 142.82 65.9878 142.127 66.9817 142.127C67.9364 142.127 68.6164 142.807 68.6164 144.194V144.298C68.6164 145.632 68.041 146.338 66.9948 146.338Z"
        fill="white"
      />
      <path
        d="M74.2885 147.816C76.0932 147.816 77.1656 147.044 77.1656 145.567C77.1656 144.102 76.224 143.67 74.5108 143.422C73.5169 143.278 73.1899 143.108 73.1899 142.677C73.1899 142.258 73.543 141.97 74.1577 141.97C74.8116 141.97 75.1254 142.232 75.2301 142.794H76.9694C76.7994 141.225 75.6616 140.676 74.1446 140.676C72.7453 140.676 71.4375 141.382 71.4375 142.807C71.4375 144.141 72.1437 144.664 73.9615 144.939C74.9424 145.083 75.3478 145.279 75.3478 145.75C75.3478 146.221 75.0077 146.495 74.2754 146.495C73.4515 146.495 73.1507 146.142 73.0722 145.527H71.3068C71.3721 147.005 72.4445 147.816 74.2885 147.816Z"
        fill="white"
      />
      <path
        d="M81.6594 140.848L81.6591 140.611C81.6592 140.475 81.7241 140.381 81.8534 140.33C82.3715 140.125 82.6021 139.528 82.3508 139.044C82.3036 138.953 82.2427 138.871 82.1695 138.799C81.9584 138.59 81.7455 138.383 81.5343 138.174C81.5114 138.152 81.4974 138.149 81.4726 138.172C81.3439 138.291 81.2137 138.408 81.0837 138.526C80.9907 138.61 80.8958 138.692 80.804 138.777C80.6159 138.952 80.5118 139.165 80.5002 139.419C80.4876 139.697 80.5844 139.936 80.7845 140.133C80.8726 140.22 80.9768 140.285 81.0939 140.33C81.2219 140.379 81.2922 140.48 81.2923 140.613C81.2924 140.778 81.2925 140.943 81.2922 141.108C81.2922 141.13 81.2975 141.156 81.2679 141.165C81.2386 141.174 81.2279 141.15 81.2139 141.132C81.0416 140.915 80.8181 140.789 80.5353 140.767C80.4535 140.76 80.4537 140.762 80.4615 140.84L80.4618 140.843C80.4793 141.018 80.5388 141.177 80.6458 141.319C80.7651 141.476 80.9221 141.584 81.1118 141.646C81.2294 141.685 81.2919 141.769 81.2923 141.891C81.2926 141.979 81.2923 142.068 81.2923 142.157C81.2923 142.415 81.1188 142.571 80.8599 142.518C80.9766 142.625 81.061 142.693 81.1715 142.72C81.4565 142.79 81.6728 142.879 82.0895 142.523C81.874 142.564 81.6815 142.371 81.6803 142.157C81.6799 142.054 81.6803 141.985 81.6803 141.882C81.6803 141.767 81.7423 141.683 81.8578 141.646C82.0279 141.591 82.1505 141.498 82.2657 141.365C82.3966 141.213 82.472 141.038 82.4862 140.839C82.4918 140.762 82.4915 140.761 82.4129 140.767C82.2011 140.782 82.0174 140.861 81.8597 140.999C81.8102 141.043 81.767 141.092 81.7272 141.143C81.715 141.159 81.7025 141.171 81.6803 141.164C81.6593 141.157 81.6593 141.14 81.6594 141.123V140.848Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.4875 142.58C83.221 142.58 84.6262 141.201 84.6262 139.5C84.6262 137.799 83.221 136.42 81.4875 136.42C79.7541 136.42 78.3489 137.799 78.3489 139.5C78.3489 141.201 79.7541 142.58 81.4875 142.58ZM81.4875 143C83.4573 143 85.0542 141.433 85.0542 139.5C85.0542 137.567 83.4573 136 81.4875 136C79.5177 136 77.9209 137.567 77.9209 139.5C77.9209 141.433 79.5177 143 81.4875 143Z"
        fill="white"
      />
      <ImpactCategoryIllustration
        greyscale={greyscale}
        impactCategory={impactCategory}
      />
      <defs>
        <clipPath id="clip0_148_945">
          <rect width="46" height="46" fill="white" transform="translate(26)" />
        </clipPath>
      </defs>
    </svg>
  );
}

interface ImpactRatingScaleProps {
  greyscale: boolean;
  impactRating: ImpactRating;
}

function ImpactRatingScale(props: ImpactRatingScaleProps) {
  const { greyscale, impactRating } = props;

  const impactRatingToColor = useImpactRatingToColor(greyscale);
  const ratingLetter = impactRatingToLetter(impactRating);
  const ratingLetterColor = impactRatingLetterColor(impactRating, greyscale);

  const ratingLetterY = 115;
  const ratingLetterXA = 25;
  const ratingLetterFontSize = 20;
  const distanceBetweenRatingLetters = 12;

  switch (impactRating) {
    case ImpactRating.VLOW:
      return (
        <>
          <rect
            x="12"
            y="93"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor(ImpactRating.VLOW)}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <path
            d="M42 98H51V118H42V98Z"
            fill={impactRatingToColor(ImpactRating.LOW)}
          />
          <rect
            x="54"
            y="98"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
          />
          <rect
            x="66"
            y="98"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.HIGH)}
          />
          <path
            d="M78 98H83C85.2091 98 87 99.7909 87 102V114C87 116.209 85.2091 118 83 118H78V98Z"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
          />
        </>
      );
    case ImpactRating.LOW:
      return (
        <>
          <path
            d="M11 102C11 99.7909 12.7909 98 15 98H20V118H15C12.7909 118 11 116.209 11 114V102Z"
            fill={impactRatingToColor(ImpactRating.VLOW)}
          />
          <rect
            x="24"
            y="93"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor(ImpactRating.LOW)}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <rect
            x="54"
            y="98"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
          />
          <rect
            x="66"
            y="98"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.HIGH)}
          />
          <path
            d="M78 98H83C85.2091 98 87 99.7909 87 102V114C87 116.209 85.2091 118 83 118H78V98Z"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
          />
        </>
      );
    case ImpactRating.MEDIUM:
      return (
        <>
          <path
            d="M11 102C11 99.7909 12.7909 98 15 98H20V118H15C12.7909 118 11 116.209 11 114V102Z"
            fill={impactRatingToColor(ImpactRating.VLOW)}
          />
          <rect
            x="23"
            y="98"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.LOW)}
          />
          <rect
            x="36"
            y="93"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 2 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <rect
            x="66"
            y="98"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.HIGH)}
          />
          <path
            d="M78 98H83C85.2091 98 87 99.7909 87 102V114C87 116.209 85.2091 118 83 118H78V98Z"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
          />
        </>
      );
    case ImpactRating.HIGH:
      return (
        <>
          <path
            d="M11 102C11 99.7909 12.7909 98 15 98H20V118H15C12.7909 118 11 116.209 11 114V102Z"
            fill={impactRatingToColor(ImpactRating.VLOW)}
          />
          <rect
            x="23"
            y="98"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.LOW)}
          />
          <rect
            x="35"
            y="98"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
          />
          <rect
            x="48"
            y="93"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor(ImpactRating.HIGH)}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 3 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <path
            d="M78 98H83C85.2091 98 87 99.7909 87 102V114C87 116.209 85.2091 118 83 118H78V98Z"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
          />
        </>
      );
    case ImpactRating.VHIGH:
      return (
        <>
          <path
            d="M11 102C11 99.7909 12.7909 98 15 98H20V118H15C12.7909 118 11 116.209 11 114V102Z"
            fill={impactRatingToColor(ImpactRating.VLOW)}
          />
          <rect
            x="23"
            y="98"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.LOW)}
          />
          <rect
            x="35"
            y="98"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
          />
          <path
            d="M47 98H56V118H47V98Z"
            fill={impactRatingToColor(ImpactRating.HIGH)}
          />
          <rect
            x="60"
            y="93"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 4 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
        </>
      );
  }
}

interface ImpactCategoryIllustrationProps {
  greyscale: boolean;
  impactCategory: ImpactCategory;
}

function ImpactCategoryIllustration(props: ImpactCategoryIllustrationProps) {
  const { greyscale, impactCategory } = props;

  switch (impactCategory) {
    case ImpactCategory.GHG:
      return (
        <>
          <path
            d="M44.1178 33.3156C53.3176 33.3156 60.7756 25.8577 60.7756 16.6578C60.7756 7.45795 53.3176 0 44.1178 0C34.9179 0 27.46 7.45795 27.46 16.6578C27.46 25.8577 34.9179 33.3156 44.1178 33.3156Z"
            fill={brightTurquoise(greyscale)}
          />
          <path
            d="M51.2999 44.7775C61.9557 44.7775 70.5939 36.1393 70.5939 25.4835C70.5939 14.8277 61.9557 6.18945 51.2999 6.18945C40.6441 6.18945 32.0059 14.8277 32.0059 25.4835C32.0059 36.1393 40.6441 44.7775 51.2999 44.7775Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M67.6523 21.7774C67.041 21.4718 66.4297 21.4718 65.5128 22.3887C65.1231 22.7784 65.2072 23.1529 65.2072 23.917C65.2072 24.1554 64.9653 25.2583 64.6825 25.4452C63.6371 26.1364 61.8496 25.7937 61.0809 25.4452C60.384 25.1289 59.3204 25.2389 58.9414 25.9037C58.3301 26.9735 58.7885 28.0432 59.8583 28.5017C60.4864 28.7707 61.9269 28.75 62.0689 30.3182C62.1799 31.5446 60.164 31.711 60.0111 33.3921C59.8583 35.0731 58.6525 35.8189 58.1085 37.1271C57.5644 38.4352 57.3566 39.1596 56.704 40.0109C55.8253 41.1555 54.242 41.7057 52.8422 41.3527C52.3195 41.2197 51.8167 40.966 51.453 40.5671C50.3695 39.3828 50.8478 37.5214 50.6614 35.9259C50.5101 34.6422 49.8606 33.4226 48.881 32.5806C47.8066 31.6575 46.4129 31.2143 45.1796 30.519C43.9463 29.8236 43.2204 28.8471 43.202 27.4304C43.1837 26 43.6987 24.2685 45.0359 23.7626C46.3731 23.2568 49.1499 23.1101 50.5376 23.457C52.3715 23.9154 54.8411 24.0622 55.8864 22.6929C56.325 22.1182 56.4351 21.1677 55.7336 20.5533C54.511 19.4836 52.8299 20.859 51.6074 20.4005C50.7026 20.0612 50.478 18.5467 49.0093 18.5666C47.5407 18.5865 46.6253 18.6583 45.6472 19.6364C44.7303 20.5533 42.9468 20.535 42.4379 19.1779C41.9794 17.9553 42.8903 17.3379 43.5077 16.8855C44.1251 16.4332 44.1143 15.9034 44.2962 15.1591C44.3955 14.7511 44.5775 12.9121 45.8 12.3008C47.6996 11.3518 47.8357 14.2875 49.4678 13.6762C50.6904 13.2178 50.3481 11.983 50.6904 11.3839C51.3017 10.3141 52.0567 10.1842 52.8299 9.85565C54.4713 9.15724 55.5655 8.50927 56.3479 6.85571C64.145 8.96315 69.9844 15.8341 70.5499 24.1554C70.5499 24.1554 68.2652 22.0816 67.6539 21.7759L67.6523 21.7774Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.7344 17.4937C33.7665 17.4907 33.8001 17.4891 33.8322 17.4846C35.4261 17.2798 36.7572 17.1361 38.0058 16.1229C39.2727 15.0944 39.9956 13.7083 40.451 12.4551C40.7673 11.584 40.2615 10.8046 39.7648 10.0176C37.176 11.9523 35.0899 14.5213 33.7359 17.4937H33.7344Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M36.6288 33.8506C36.4316 32.9 36.2116 32.1588 35.8647 31.2526C35.4658 30.2134 35.5926 28.9098 35.5697 27.7972C35.559 27.2792 35.4062 26.0566 34.9477 25.4453C34.1561 24.3893 33.4195 23.7642 32.148 23.1392C32.0548 23.9079 32.0059 24.6888 32.0059 25.4835C32.0059 30.1018 33.6304 34.3412 36.3369 37.6635C36.4164 37.5596 36.4958 37.4542 36.5707 37.3472C37.3211 36.279 36.8947 35.1282 36.6288 33.8506Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
    case ImpactCategory.LAND_USE:
      return (
        <g clipPath="url(#clip0_150_3303)">
          <path
            d="M41.75 31.5C50.4485 31.5 57.5 24.4485 57.5 15.75C57.5 7.05152 50.4485 0 41.75 0C33.0515 0 26 7.05152 26 15.75C26 24.4485 33.0515 31.5 41.75 31.5Z"
            fill={brightTurquoise(greyscale)}
          />
          fill={darkTurquoise(greyscale)}
          <path
            d="M51.1202 44.96C62.0057 44.96 70.8302 36.1356 70.8302 25.25C70.8302 14.3645 62.0057 5.54004 51.1202 5.54004C40.2346 5.54004 31.4102 14.3645 31.4102 25.25C31.4102 36.1356 40.2346 44.96 51.1202 44.96Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M47.5002 5.87988C38.3402 7.57988 31.4102 15.5999 31.4102 25.2499C31.4102 36.1399 40.2402 44.9599 51.1202 44.9599C54.0002 44.9599 56.7402 44.3399 59.2002 43.2199L47.5002 5.87988Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M34.9604 36.5301H67.2804C69.3504 33.5801 70.6304 30.0301 70.8104 26.2001C69.8904 26.3401 69.0304 26.6301 68.2704 27.0501C67.9604 27.2201 67.5704 27.2201 67.2604 27.0501C66.1904 26.4501 64.9304 26.1101 63.5904 26.1101C62.2504 26.1101 60.9904 26.4601 59.9204 27.0501C59.6104 27.2201 59.2204 27.2201 58.9004 27.0501C57.8304 26.4601 56.5704 26.1101 55.2304 26.1101C53.8904 26.1101 52.6304 26.4601 51.5604 27.0501C51.2504 27.2201 50.8604 27.2201 50.5404 27.0501C49.4704 26.4501 48.2104 26.1101 46.8704 26.1101C45.5304 26.1101 44.2704 26.4601 43.2004 27.0501C42.8904 27.2201 42.5004 27.2201 42.1804 27.0501C41.1104 26.4601 39.8504 26.1101 38.5104 26.1101C37.1704 26.1101 35.9104 26.4601 34.8404 27.0501C34.5304 27.2201 34.1404 27.2201 33.8204 27.0501C33.1004 26.6501 32.3004 26.3701 31.4404 26.2201C31.6304 30.0501 32.9004 33.5801 34.9704 36.5301H34.9604Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M35.5093 36.3C34.7193 36.3 34.0793 35.66 34.0793 34.87V29.53C34.0793 29.45 34.0193 29.39 33.9393 29.39H28.7493C28.0593 29.39 27.4393 28.97 27.1893 28.33C26.9393 27.69 27.0993 26.96 27.6093 26.49L31.0193 23.34H30.2793C29.5893 23.34 28.9693 22.92 28.7193 22.28C28.4693 21.64 28.6293 20.91 29.1393 20.44L32.1293 17.68H31.9493C31.2593 17.68 30.6393 17.26 30.3893 16.62C30.1393 15.97 30.2993 15.25 30.8093 14.78L35.7693 10.19C36.0793 9.89999 36.4793 9.73999 36.9093 9.73999C37.3393 9.73999 37.7393 9.89999 38.0493 10.19L43.0093 14.78C43.5193 15.25 43.6893 15.97 43.4293 16.62C43.1693 17.27 42.5593 17.68 41.8693 17.68H41.6893L44.6793 20.44C45.1893 20.91 45.3593 21.63 45.0993 22.28C44.8493 22.93 44.2293 23.34 43.5393 23.34H42.7993L46.2093 26.49C46.7193 26.96 46.8893 27.68 46.6293 28.33C46.3693 28.98 45.7593 29.39 45.0693 29.39H39.8793C39.7993 29.39 39.7393 29.45 39.7393 29.53V34.87C39.7393 35.66 39.0993 36.3 38.3093 36.3H35.4993H35.5093Z"
            fill={mint(greyscale)}
          />
          <path
            d="M36.9097 10.73C37.0697 10.73 37.2397 10.79 37.3697 10.91L42.3297 15.5C42.7797 15.92 42.4897 16.67 41.8697 16.67H40.8597C40.2497 16.67 39.9497 17.42 40.3997 17.84L43.9997 21.17C44.4497 21.59 44.1597 22.34 43.5397 22.34H41.9697C41.3597 22.34 41.0597 23.09 41.5097 23.51L45.5297 27.22C45.9797 27.64 45.6897 28.39 45.0697 28.39H39.8797C39.2497 28.39 38.7397 28.9 38.7397 29.53V34.87C38.7397 35.11 38.5497 35.3 38.3097 35.3H35.4997C35.2597 35.3 35.0697 35.11 35.0697 34.87V29.53C35.0697 28.9 34.5597 28.39 33.9297 28.39H28.7497C28.1397 28.39 27.8397 27.64 28.2897 27.22L32.3097 23.51C32.7597 23.09 32.4697 22.34 31.8497 22.34H30.2797C29.6697 22.34 29.3697 21.59 29.8197 21.17L33.4197 17.84C33.8697 17.42 33.5797 16.67 32.9597 16.67H31.9497C31.3397 16.67 31.0397 15.92 31.4897 15.5L36.4497 10.91C36.5797 10.79 36.7397 10.73 36.9097 10.73ZM36.9097 8.72998C36.2297 8.72998 35.5897 8.97998 35.0897 9.43998L30.1297 14.03C29.3197 14.78 29.0497 15.94 29.4597 16.97C29.6297 17.4 29.8997 17.77 30.2397 18.06L28.4597 19.7C27.6497 20.45 27.3797 21.61 27.7897 22.64C28.0097 23.2 28.3997 23.65 28.8797 23.95L26.9297 25.76C26.1197 26.51 25.8497 27.67 26.2597 28.7C26.6597 29.73 27.6397 30.4 28.7497 30.4H33.0797V34.88C33.0797 36.22 34.1697 37.31 35.5097 37.31H38.3197C39.6597 37.31 40.7497 36.22 40.7497 34.88V30.4H45.0797C46.1897 30.4 47.1697 29.73 47.5697 28.7C47.9697 27.67 47.7097 26.51 46.8897 25.76L44.9397 23.95C45.4297 23.65 45.8197 23.2 46.0297 22.64C46.4297 21.61 46.1697 20.45 45.3497 19.7L43.5697 18.06C43.9097 17.78 44.1797 17.41 44.3497 16.97C44.7497 15.94 44.4897 14.78 43.6797 14.03L38.7197 9.43998C38.2197 8.97998 37.5797 8.72998 36.8997 8.72998H36.9097Z"
            fill="black"
          />
        </g>
      );
    case ImpactCategory.WATER_USE:
      return <></>;
  }
}

function impactCategoryTitleFontSize(
  impactCategory: ImpactCategory,
  locale: string | null
): number {
  if (locale === "de-DE" && impactCategory === ImpactCategory.WATER_USE) {
    return 10;
  } else if (
    locale === "fr-FR" &&
    (impactCategory === ImpactCategory.LAND_USE ||
      impactCategory === ImpactCategory.WATER_USE)
  ) {
    return 9;
  } else if (
    locale === "nl-NL" &&
    (impactCategory === ImpactCategory.LAND_USE ||
      impactCategory === ImpactCategory.WATER_USE)
  ) {
    return 12;
  }
  return 13;
}

function impactRatingFontSize(
  impactRating: ImpactRating,
  locale: string | null
): number {
  if (locale === "de-DE" && impactRating === ImpactRating.VLOW) {
    return 14;
  }
  return 16;
}
