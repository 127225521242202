import { FormattedMessage, useIntl } from "react-intl";

import { DashboardProductTag } from "../../data-store";
import { impactCategoryToEffectType } from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import BarChart2 from "../graphs/BarChart2";
import { averageIntensity } from "./helperFunctions";

interface AverageImpactIntensityByTagProps {
  impactCategory: ImpactCategory;
  tags: Array<DashboardProductTag>;
}

export function AverageImpactIntensityByTag(
  props: AverageImpactIntensityByTagProps
) {
  const { tags, impactCategory } = props;

  const intl = useIntl();

  const bars = tags.map((tag) => ({
    label: tag.name,
    value: [averageIntensity(tag, impactCategory)],
  }));

  const effectType = impactCategoryToEffectType(impactCategory);
  const dependentAxisLabel = `${effectType.title(
    intl
  )} ${effectType.largeUnitString(intl)}`;

  return (
    <div className="w-100">
      <h4 className="m-0">
        <FormattedMessage
          id="components/AverageImpactIntensityByTag/title"
          defaultMessage="Average Impact Intensity by Tag"
        />
      </h4>
      <BarChart2
        bars={bars}
        dependentAxisLabel={dependentAxisLabel}
        height={250}
        sorted
        maxBars={32}
        hideFinalBar
        maintainAspectRatio={false}
      />
    </div>
  );
}
