import { createContext, useContext, useState } from "react";

import { useOrganization } from "../organizations/OrganizationProvider";

export interface DashboardOrganization {
  name: string;
  id: string;
}
const DashboardOrganizationContext = createContext<
  | [
      DashboardOrganization | undefined,
      (organizationId: DashboardOrganization) => void
    ]
  | undefined
>(undefined);

interface DashboardOrganizationProviderProps {
  children: React.ReactNode;
}

export function DashboardOrganizationProvider(
  props: DashboardOrganizationProviderProps
) {
  const { children } = props;
  const [organization] = useOrganization();
  const dashboardOrganizationState =
    useState<DashboardOrganization>(organization);

  return (
    <DashboardOrganizationContext.Provider value={dashboardOrganizationState}>
      {children}
    </DashboardOrganizationContext.Provider>
  );
}

export function useDashboardOrganization(): [
  DashboardOrganization,
  (organization: DashboardOrganization) => void
] {
  const result = useContext(DashboardOrganizationContext);

  if (result === undefined) {
    throw new Error("missing provider for OrganizationContext");
  }

  const [dashboardOrganization, setDashboardOrganization] = result;

  if (dashboardOrganization === undefined) {
    throw new Error("No dashboard organizationId has been set");
  }

  return [dashboardOrganization, setDashboardOrganization];
}
