import { FormattedMessage } from "react-intl";

import {
  ImpactCategory,
  useImpactCategoryLabel,
} from "../../domain/impactCategories";
import ImpactRatingProportionsChart, {
  Proportions,
} from "../scope-3/ImpactRatingProportionsChart";
import { DashboardCard } from "./DashboardCard";

interface ProcuredItemsByRatingProps {
  impactCategory: ImpactCategory;
  proportions: Proportions | null;
}

export function ProcuredItemsByRating(props: ProcuredItemsByRatingProps) {
  const { impactCategory, proportions } = props;

  return (
    <DashboardCard className="AssessmentOverviewChartCard">
      <h4 className="m-0">
        <FormattedMessage
          id="components/corporate-reporting-dashboards/ProcuredItemsByRating:title"
          defaultMessage="Procured Items by {impactCategory} Rating"
          values={{
            impactCategory: useImpactCategoryLabel(impactCategory),
          }}
        />
      </h4>
      {proportions !== null ? (
        <ImpactRatingProportionsChart proportions={proportions} />
      ) : (
        <FormattedMessage
          id="components/corporate-reporting-dashboards/ProcuredItemsByRating:noData"
          defaultMessage="No data available"
        />
      )}
    </DashboardCard>
  );
}
