import React from "react";

import { ImpactCategory } from "../../domain/impactCategories";
import { ProcurementDashboardContent } from "./ProcurementDashboard";

const assessments = [
  {
    id: "5ae701fb-4d0f-483d-af63-029c336b6f12",
    name: "2024",
    period_covered_start: "2024-01-01T00:00:00+00:00",
    created_at: "2024-10-30T13:27:58.200325+00:00",
    spend_currency: "EUR",
    number_of_items_assessed: 2035,
    show_scope3_report: true,
    show_land_use_report: true,
    show_water_use_report: true,
    is_default: false,
  },
  {
    id: "32dcbfa4-a784-42aa-a739-e6765e70a3bf",
    name: "2025",
    period_covered_start: "2025-01-01T00:00:00+00:00",
    created_at: "2024-10-30T14:42:13.098712+00:00",
    spend_currency: "EUR",
    number_of_items_assessed: 2035,
    show_scope3_report: true,
    show_land_use_report: true,
    show_water_use_report: true,
    is_default: true,
  },
  {
    id: "6ff7a23b-6cbe-4fd1-b11c-15661a877973",
    name: "Demo",
    period_covered_start: "2023-01-01T00:00:00+00:00",
    created_at: "2024-10-28T14:20:14.789535+00:00",
    spend_currency: "GBP",
    number_of_items_assessed: 521,
    show_scope3_report: false,
    show_land_use_report: false,
    show_water_use_report: false,
    is_default: false,
  },
];

const stories = {
  name: "procurementDashboard",
  children: [
    {
      name: "Procurement Dashboard",
      render: () => (
        <ProcurementDashboardContent
          assessments={assessments}
          impactCategory={ImpactCategory.LAND_USE}
        />
      ),
    },
  ],
};

export default stories;
