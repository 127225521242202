import { ImpactRating } from "../../../../__generated__/globalTypes";
import { ImpactCategory } from "../../../../domain/impactCategories";
import { useImpactRatingToColor } from "../../../../domain/impactRatings";
import { graphikSemiboldWebCssString } from "../../../../fonts/graphik-web/graphikSemiboldWebCssString";
import {
  darkTurquoise,
  foodstepsTurquoise,
  mint,
} from "../../../graphs/colors";
import { impactRatingLetterColor } from "../../impactRatingLetterColor";
import {
  impactCategoryTitle,
  impactRatingToLetter,
  impactRatingToName,
} from "./translations";

interface DescriptiveRatingProps {
  greyscale?: boolean;
  impactCategory: ImpactCategory;
  impactRating: ImpactRating;
  locale: string | null;
  width: number;
}

export default function DescriptiveRating(props: DescriptiveRatingProps) {
  const {
    greyscale = false,
    impactCategory,
    impactRating,
    locale,
    width,
  } = props;

  const impactRatingToColor = useImpactRatingToColor(greyscale);

  return (
    <svg
      width={width}
      viewBox="0 0 141 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>{graphikSemiboldWebCssString}</style>
      <ImpactCategoryIllustration
        greyscale={greyscale}
        impactCategory={impactCategory}
      />
      <path
        d="M30 8C30 3.58172 33.9363 0 38.7921 0H132.208C137.064 0 141 3.58172 141 8V34C141 38.4183 137.064 42 132.208 42H38.7921C33.9363 42 30 38.4183 30 34V8Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M132.208 2H38.7921C35.1503 2 32.198 4.68629 32.198 8V34C32.198 37.3137 35.1503 40 38.7921 40H132.208C135.85 40 138.802 37.3137 138.802 34V8C138.802 4.68629 135.85 2 132.208 2ZM38.7921 0C33.9363 0 30 3.58172 30 8V34C30 38.4183 33.9363 42 38.7921 42H132.208C137.064 42 141 38.4183 141 34V8C141 3.58172 137.064 0 132.208 0H38.7921Z"
        fill="black"
      />
      <path
        d="M38 14C38 10.6863 40.6863 8 44 8H54C57.3137 8 60 10.6863 60 14V28C60 31.3137 57.3137 34 54 34H44C40.6863 34 38 31.3137 38 28V14Z"
        fill={impactRatingToColor(impactRating)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54 10H44C41.7909 10 40 11.7909 40 14V28C40 30.2091 41.7909 32 44 32H54C56.2091 32 58 30.2091 58 28V14C58 11.7909 56.2091 10 54 10ZM44 8C40.6863 8 38 10.6863 38 14V28C38 31.3137 40.6863 34 44 34H54C57.3137 34 60 31.3137 60 28V14C60 10.6863 57.3137 8 54 8H44Z"
        fill="black"
      />
      <text
        x={49}
        y={26.5}
        textAnchor="middle"
        fontFamily="Graphik Web"
        fontWeight={600}
        fontSize={16}
        fill={impactRatingLetterColor(impactRating, greyscale)}
      >
        {impactRatingToLetter(impactRating)}
      </text>
      <text
        x={66}
        y={19}
        color="black"
        fontFamily="Graphik Web"
        fontWeight={600}
        fontSize={impactRatingFontSize(impactRating, locale)}
        fill="black"
      >
        {impactRatingToName(impactRating, locale)}
      </text>
      <text
        x={66}
        y={32}
        color="black"
        fontFamily="Graphik Web"
        fontWeight={500}
        fontSize={impactCategoryFontSize(impactCategory, locale)}
        fill="black"
      >
        {impactCategoryTitle(impactCategory, locale)}
      </text>
      <defs>
        <clipPath id="clip0_914_1726">
          <rect width="46" height="42" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

interface ImpactCategoryIllustrationProps {
  greyscale: boolean;
  impactCategory: ImpactCategory;
}

function ImpactCategoryIllustration(props: ImpactCategoryIllustrationProps) {
  const { greyscale, impactCategory } = props;

  switch (impactCategory) {
    case ImpactCategory.GHG:
      return (
        <>
          <path
            d="M21.2093 40.772C31.9114 40.772 40.5872 32.0962 40.5872 21.3941C40.5872 10.692 31.9114 2.01617 21.2093 2.01617C10.5071 2.01617 1.83136 10.692 1.83136 21.3941C1.83136 32.0962 10.5071 40.772 21.2093 40.772Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M37.6327 17.6721C37.0188 17.3651 36.4048 17.3651 35.4839 18.2861C35.0925 18.6775 35.1769 19.0535 35.1769 19.8209C35.1769 20.0604 34.9339 21.1681 34.65 21.3558C33.6 22.05 31.8048 21.7058 31.0327 21.3558C30.3328 21.0381 29.2645 21.1486 28.8839 21.8163C28.2699 22.8907 28.7304 23.9651 29.8048 24.4256C30.4356 24.6957 31.8824 24.675 32.025 26.25C32.1365 27.4817 30.1118 27.6488 29.9583 29.3372C29.8048 31.0256 28.5938 31.7746 28.0474 33.0885C27.5009 34.4023 27.2922 35.1299 26.6368 35.9848C25.7542 37.1344 24.1641 37.687 22.7582 37.3324C22.2332 37.1989 21.7282 36.9441 21.3629 36.5435C20.2747 35.354 20.7551 33.4845 20.5679 31.8821C20.4159 30.5928 19.7636 29.3679 18.7797 28.5222C17.7007 27.5951 16.3009 27.15 15.0622 26.4516C13.8236 25.7533 13.0945 24.7725 13.0761 23.3496C13.0577 21.913 13.5749 20.174 14.918 19.6659C16.261 19.1579 19.0499 19.0105 20.4436 19.3589C22.2854 19.8194 24.7658 19.9668 25.8156 18.5915C26.2562 18.0144 26.3667 17.0597 25.6622 16.4427C24.4342 15.3682 22.7459 16.7496 21.518 16.2892C20.6093 15.9484 20.3837 14.4274 18.9087 14.4473C17.4336 14.4673 16.5142 14.5394 15.5319 15.5217C14.611 16.4427 12.8198 16.4242 12.3087 15.0613C11.8482 13.8334 12.763 13.2133 13.3831 12.7589C14.0032 12.3046 14.4263 11.7039 14.609 10.9564C14.7087 10.5466 14.4575 8.76824 15.6854 8.15429C17.5933 7.20113 17.7299 10.1496 19.3691 9.53569C20.597 9.07522 20.2532 7.83504 20.597 7.23336C21.211 6.15894 21.9692 6.02848 22.7459 5.69848C24.3943 4.99704 25.4933 4.34625 26.2792 2.6855C34.1102 4.80211 39.9749 11.7029 40.5429 20.0604C40.5429 20.0604 38.2482 17.9775 37.6342 17.6706L37.6327 17.6721Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.56836 13.3698C3.60059 13.3667 3.63436 13.3652 3.66659 13.3606C5.26748 13.1549 6.60436 13.0106 7.85836 11.993C9.13078 10.96 9.85678 9.56787 10.3142 8.30927C10.6319 7.43439 10.1238 6.65159 9.62501 5.86113C7.02492 7.80429 4.9298 10.3844 3.56989 13.3698H3.56836Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.47438 29.7977C6.27638 28.843 6.05536 28.0986 5.70694 27.1884C5.30634 26.1447 5.43373 24.8354 5.41071 23.718C5.39997 23.1977 5.24648 21.9698 4.78601 21.3558C3.99094 20.2952 3.25113 19.6675 1.9741 19.0397C1.88048 19.8117 1.83136 20.5961 1.83136 21.3942C1.83136 26.0326 3.46294 30.2904 6.18122 33.6272C6.18122 33.6272 6.26103 33.5213 6.41606 33.3095C7.16969 32.2366 6.74145 31.0809 6.47438 29.7977Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
    case ImpactCategory.LAND_USE:
      return (
        <g clipPath="url(#clip0_914_1726)">
          <path
            d="M24.9098 40.8599C35.8782 40.8599 44.7698 31.9683 44.7698 20.9999C44.7698 10.0315 35.8782 1.13989 24.9098 1.13989C13.9414 1.13989 5.0498 10.0315 5.0498 20.9999C5.0498 31.9683 13.9414 40.8599 24.9098 40.8599Z"
            fill={darkTurquoise(greyscale)}
          />
          <path
            d="M24.9098 41.8599C13.4098 41.8599 4.0498 32.4999 4.0498 20.9999C4.0498 9.49989 13.4098 0.139893 24.9098 0.139893C36.4098 0.139893 45.7698 9.49989 45.7698 20.9999C45.7698 32.4999 36.4098 41.8599 24.9098 41.8599ZM24.9098 2.13989C14.5098 2.13989 6.0498 10.5999 6.0498 20.9999C6.0498 31.3999 14.5098 39.8599 24.9098 39.8599C35.3098 39.8599 43.7698 31.3999 43.7698 20.9999C43.7698 10.5999 35.3098 2.13989 24.9098 2.13989Z"
            fill="black"
          />
          <path
            d="M21.2598 1.47998C12.0398 3.19998 5.0498 11.28 5.0498 21C5.0498 31.97 13.9398 40.86 24.9098 40.86C27.8098 40.86 30.5698 40.23 33.0498 39.11L21.2598 1.47998Z"
            fill={foodstepsTurquoise(greyscale)}
          />
          <path
            d="M24.9098 41.86C13.4098 41.86 4.0498 32.5 4.0498 21C4.0498 10.96 11.2098 2.33996 21.0798 0.49996C21.5798 0.40996 22.0698 0.69996 22.2198 1.17996L34.0198 38.8C34.1698 39.28 33.9398 39.8 33.4798 40.01C30.7798 41.23 27.8998 41.85 24.9298 41.85L24.9098 41.86ZM20.5798 2.64996C12.1098 4.63996 6.0598 12.22 6.0598 21C6.0598 31.4 14.5198 39.86 24.9198 39.86C27.3098 39.86 29.6298 39.42 31.8398 38.55L20.5798 2.64996Z"
            fill="black"
          />
          <path
            d="M8.63008 32.3599H41.1901C43.2701 29.3799 44.5601 25.8099 44.7401 21.9499C43.8201 22.0899 42.9501 22.3799 42.1801 22.8099C41.8701 22.9799 41.4701 22.9799 41.1601 22.8099C40.0801 22.2099 38.8201 21.8599 37.4601 21.8599C36.1001 21.8599 34.8401 22.2099 33.7601 22.8099C33.4501 22.9799 33.0501 22.9799 32.7401 22.8099C31.6601 22.2099 30.4001 21.8599 29.0401 21.8599C27.6801 21.8599 26.4201 22.2099 25.3401 22.8099C25.0301 22.9799 24.6301 22.9799 24.3201 22.8099C23.2401 22.2099 21.9801 21.8599 20.6201 21.8599C19.2601 21.8599 18.0001 22.2099 16.9201 22.8099C16.6001 22.9799 16.2101 22.9799 15.9001 22.8099C14.8201 22.2099 13.5601 21.8599 12.2001 21.8599C10.8401 21.8599 9.58008 22.2099 8.50008 22.8099C8.18008 22.9799 7.79008 22.9799 7.48008 22.8099C6.75008 22.4099 5.94008 22.1199 5.08008 21.9699C5.27008 25.8199 6.56008 29.3799 8.63008 32.3599Z"
            fill={mint(greyscale)}
          />
          <path
            d="M41.1902 33.3599H8.63017C8.30017 33.3599 8.00017 33.1999 7.81017 32.9299C5.56017 29.7099 4.27017 25.9399 4.08017 22.0199C4.07017 21.7199 4.19017 21.4199 4.42017 21.2199C4.65017 21.0199 4.95017 20.9299 5.25017 20.9799C6.22017 21.1399 7.13017 21.4599 7.97017 21.9299C10.6102 20.5299 13.8602 20.5299 16.3902 21.9299C19.0302 20.5299 22.2802 20.5299 24.8102 21.9299C27.4502 20.5299 30.7002 20.5299 33.2302 21.9299C35.8702 20.5299 39.1202 20.5299 41.6502 21.9299C42.5902 21.4399 43.5602 21.1099 44.6002 20.9599C44.9002 20.9199 45.2002 21.0099 45.4202 21.2099C45.6402 21.4099 45.7602 21.6999 45.7502 21.9999C45.5602 25.9299 44.2702 29.7099 42.0202 32.9299C41.8302 33.1999 41.5302 33.3599 41.2002 33.3599H41.1902ZM9.16017 31.3599H40.6602C42.2702 28.9199 43.2802 26.1499 43.6302 23.2499C43.3002 23.3699 42.9702 23.5099 42.6702 23.6799C42.0602 24.0199 41.2902 24.0199 40.6802 23.6799C38.7402 22.5999 36.1902 22.5999 34.2502 23.6799C33.6402 24.0199 32.8702 24.0199 32.2602 23.6799C30.3202 22.6099 27.7702 22.5999 25.8302 23.6799C25.2202 24.0199 24.4502 24.0199 23.8402 23.6799C21.9002 22.5999 19.3502 22.5999 17.4102 23.6799C16.8002 24.0199 16.0302 24.0199 15.4202 23.6799C13.4802 22.5999 10.9302 22.5999 8.99017 23.6799C8.38017 24.0199 7.61017 24.0199 7.00017 23.6799C6.74017 23.5399 6.48017 23.4099 6.20017 23.3099C6.55017 26.1799 7.56017 28.9299 9.16017 31.3599Z"
            fill="black"
          />
          <path
            d="M9.63013 32.2099C8.84013 32.2099 8.20013 31.5699 8.20013 30.7799V25.3999C8.20013 25.3199 8.13013 25.2499 8.05013 25.2499H2.82013C2.12013 25.2499 1.51013 24.8299 1.26013 24.1799C1.01013 23.5299 1.17013 22.8099 1.68013 22.3299L5.13013 19.1399H4.37013C3.67013 19.1399 3.06013 18.7199 2.81013 18.0699C2.56013 17.4199 2.72013 16.6999 3.23013 16.2199L6.25013 13.4199H6.05013C5.35013 13.4199 4.74013 12.9999 4.49013 12.3499C4.24013 11.6999 4.40013 10.9799 4.91013 10.4999L9.91013 5.87993C10.2201 5.58993 10.6301 5.42993 11.0501 5.42993C11.4701 5.42993 11.8801 5.58993 12.1901 5.87993L17.1901 10.4999C17.7001 10.9699 17.8701 11.6999 17.6101 12.3499C17.3601 12.9999 16.7401 13.4199 16.0501 13.4199H15.8501L18.8701 16.2199C19.3801 16.6899 19.5501 17.4199 19.2901 18.0699C19.0401 18.7199 18.4201 19.1399 17.7301 19.1399H16.9701L20.4201 22.3299C20.9301 22.7999 21.1001 23.5299 20.8401 24.1799C20.5901 24.8299 19.9701 25.2499 19.2801 25.2499H14.0501C13.9701 25.2499 13.9001 25.3199 13.9001 25.3999V30.7799C13.9001 31.5699 13.2601 32.2099 12.4701 32.2099H9.64013H9.63013Z"
            fill={mint(greyscale)}
          />
          <path
            d="M11.0502 6.45996C11.2202 6.45996 11.3802 6.51996 11.5102 6.63996L16.5102 11.26C16.9602 11.68 16.6702 12.44 16.0502 12.44H15.0302C14.4102 12.44 14.1102 13.2 14.5702 13.62L18.1902 16.97C18.6402 17.39 18.3502 18.15 17.7302 18.15H16.1502C15.5302 18.15 15.2302 18.91 15.6902 19.33L19.7302 23.07C20.1802 23.49 19.8902 24.25 19.2702 24.25H14.0402C13.4102 24.25 12.8902 24.76 12.8902 25.4V30.78C12.8902 31.02 12.7002 31.21 12.4602 31.21H9.63019C9.39019 31.21 9.20019 31.02 9.20019 30.78V25.4C9.20019 24.77 8.69019 24.25 8.05019 24.25H2.82019C2.20019 24.25 1.90019 23.49 2.36019 23.07L6.40019 19.33C6.85019 18.91 6.56019 18.15 5.94019 18.15H4.36019C3.74019 18.15 3.44019 17.39 3.90019 16.97L7.52019 13.62C7.97019 13.2 7.68019 12.44 7.06019 12.44H6.04019C5.42019 12.44 5.12019 11.68 5.58019 11.26L10.5802 6.63996C10.7102 6.51996 10.8802 6.45996 11.0402 6.45996M11.0502 4.45996C10.3702 4.45996 9.73019 4.70996 9.23019 5.16996L4.23019 9.78996C3.41019 10.54 3.15019 11.7 3.55019 12.73C3.72019 13.17 4.00019 13.54 4.34019 13.83L2.54019 15.5C1.72019 16.25 1.46019 17.41 1.86019 18.44C2.08019 19.01 2.48019 19.47 2.97019 19.76L0.99019 21.59C0.17019 22.34 -0.0898104 23.5 0.31019 24.53C0.71019 25.56 1.69019 26.23 2.81019 26.23H7.19019V30.75C7.19019 32.09 8.28019 33.18 9.62019 33.18H12.4502C13.7902 33.18 14.8802 32.09 14.8802 30.75V26.23H19.2602C20.3702 26.23 21.3502 25.56 21.7602 24.53C22.1602 23.49 21.9002 22.34 21.0802 21.59L19.1002 19.76C19.6002 19.46 19.9902 19.01 20.2102 18.44C20.6102 17.41 20.3502 16.25 19.5302 15.5L17.7302 13.83C18.0802 13.55 18.3502 13.17 18.5202 12.73C18.9202 11.7 18.6602 10.54 17.8402 9.78996L12.8402 5.16996C12.3402 4.70996 11.7002 4.45996 11.0202 4.45996H11.0502Z"
            fill="black"
          />
        </g>
      );
    case ImpactCategory.WATER_USE:
      return <></>;
  }
}

function impactCategoryFontSize(
  impactCategory: ImpactCategory,
  locale: string | null
): number {
  if (locale === "es-ES" && impactCategory === ImpactCategory.LAND_USE) {
    return 9;
  } else if (
    locale === "fr-FR" &&
    (impactCategory === ImpactCategory.LAND_USE ||
      impactCategory === ImpactCategory.WATER_USE)
  ) {
    return 7;
  } else if (
    locale === "nl-NL" &&
    (impactCategory === ImpactCategory.LAND_USE ||
      impactCategory === ImpactCategory.WATER_USE)
  ) {
    return 10;
  }
  return 11;
}

function impactRatingFontSize(
  impactRating: ImpactRating,
  locale: string | null
): number {
  if (locale === "de-DE" && impactRating === ImpactRating.VLOW) {
    return 11;
  }
  return 12;
}
