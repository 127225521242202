import { AssessmentSummary, ImpactRatingProportions } from "../../data-store";
import { ImpactCategory } from "../../domain/impactCategories";
import { ImpactRating } from "../../domain/impactRatings";
import assertNever from "../../util/assertNever";
import { usePages } from "../pages";
import { Proportions } from "../scope-3/ImpactRatingProportionsChart";

export function getImpactCategoryImpactRatingProportions(
  assessment: AssessmentSummary,
  impactCategory: ImpactCategory
) {
  if (impactCategory === ImpactCategory.GHG) {
    return assessment.impactRatingProportions.ghg;
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    return assessment.impactRatingProportions.landUse;
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    return null;
  } else {
    assertNever(impactCategory, "Unsupported impact category");
  }
}

export function getImpactCategoryProportions(
  assessment: AssessmentSummary,
  impactCategory: ImpactCategory
): Proportions | null {
  const impactRatingProportions = getImpactCategoryImpactRatingProportions(
    assessment,
    impactCategory
  );
  return impactRatingProportions
    ? {
        veryLow: impactRatingProportions.veryLow.value,
        low: impactRatingProportions.low.value,
        medium: impactRatingProportions.medium.value,
        high: impactRatingProportions.high.value,
        veryHigh: impactRatingProportions.veryHigh.value,
      }
    : null;
}

export function getImpactRatingProportion(
  impactRatingProportions: ImpactRatingProportions,
  impactRating: ImpactRating
) {
  if (impactRating === "VLOW") {
    return impactRatingProportions.veryLow;
  } else if (impactRating === "LOW") {
    return impactRatingProportions.low;
  } else if (impactRating === "MEDIUM") {
    return impactRatingProportions.medium;
  } else if (impactRating === "HIGH") {
    return impactRatingProportions.high;
  } else if (impactRating === "VHIGH") {
    return impactRatingProportions.veryHigh;
  } else {
    assertNever(impactRating, "Unsupported impact rating");
  }
}

export function getImpactCategoryImpactRatingProportion(
  assessment: AssessmentSummary,
  impactCategory: ImpactCategory,
  impactRating: ImpactRating
) {
  const impactRatingProportions = getImpactCategoryImpactRatingProportions(
    assessment,
    impactCategory
  );
  return impactRatingProportions
    ? getImpactRatingProportion(impactRatingProportions, impactRating)
    : null;
}

export function useImpactCategoryUrl(impactCategory: ImpactCategory) {
  const pages = usePages();
  if (impactCategory === ImpactCategory.GHG) {
    return pages.Scope3.url;
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    return pages.LandUse.url;
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    return pages.WaterUse.url;
  } else {
    assertNever(impactCategory, "Invalid impact category");
  }
}
