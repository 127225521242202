import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";

import { Assessment, AssessmentSummary, ProcuredItem } from "../../data-store";
import useAssessmentSummary from "../../data-store/useAssessmentSummary";
import useProcuredItems from "../../data-store/useProcuredItems";
import { ImpactCategory } from "../../domain/impactCategories";
import assertNever from "../../util/assertNever";
import { buildPath, usePages } from "../pages";
import StatusDisplay from "../StatusDisplay";
import { SecondaryButton } from "../utils/Button";
import { Panel } from "../utils/Panel";
import Select from "../utils/Select";
import { Apple } from "../utils/Vectors/illustrations";
import Report from "../utils/Vectors/Report";
import { AssessmentOverviewSummary } from "./AssessmentOverviewSummary";
import {
  getImpactCategoryProportions,
  useImpactCategoryUrl,
} from "./helperFunctions";
import { HighestImpactProcuredItems } from "./HighestImpactProcuredItems";
import { ImpactByFoodType } from "./ImpactByFoodType";
import { ProcuredItemsByRating } from "./ProcuredItemsByRating";
import { ProcurementImpactBreakdownChart } from "./ProcurementImpactBreakdownChart";
import "./AssessmentOverview.css";

interface AssessmentOverviewProps {
  assessments: Array<Assessment>;
  impactCategory: ImpactCategory;
  assessmentIdToColourMap: Map<string, string>;
}

export function AssessmentOverview(props: AssessmentOverviewProps) {
  const { assessmentIdToColourMap, assessments, impactCategory } = props;

  const defaultAssessment = assessments.find(
    (assessment) => assessment.is_default
  );
  const [chosenAssessment, setChosenAssessment] =
    React.useState<Assessment | null>(defaultAssessment ?? null);
  const assessmentId = chosenAssessment ? chosenAssessment.id : "";

  return (
    <Panel className="AssessmentOverview_Panel">
      <AssessmentOverviewHeader
        headerRow={
          <AssessmentOverviewHeaderRow
            assessmentId={assessmentId}
            impactCategory={impactCategory}
            versionInfoSection={
              <AssessmentOverviewHeaderVersionInfo
                assessmentIdToColourMap={assessmentIdToColourMap}
                assessments={assessments}
                chosenAssessment={chosenAssessment}
                setChosenAssessment={setChosenAssessment}
              />
            }
            viewAssessmentButtonDisabled={chosenAssessment === null}
          />
        }
      />
      {chosenAssessment !== null && (
        <AssessmentOverviewContent
          assessmentId={assessmentId}
          impactCategory={impactCategory}
        />
      )}
    </Panel>
  );
}

interface AssessmentOverviewHeaderProps {
  headerRow: React.ReactNode;
}

function AssessmentOverviewHeader(props: AssessmentOverviewHeaderProps) {
  const { headerRow } = props;
  return (
    <div className="AssessmentOverviewHeader">
      <h3 className="m-0">
        <FormattedMessage
          id="components/corporate-reporting-dashboards/AssessmentOverview:title"
          defaultMessage="Assessment Overview"
        />
      </h3>
      {headerRow}
    </div>
  );
}

interface AssessmentOverviewHeaderRowProps {
  assessmentId: string;
  impactCategory: ImpactCategory;
  versionInfoSection: React.ReactNode;
  viewAssessmentButtonDisabled: boolean;
}

function AssessmentOverviewHeaderRow(props: AssessmentOverviewHeaderRowProps) {
  const {
    assessmentId,
    impactCategory,
    versionInfoSection,
    viewAssessmentButtonDisabled,
  } = props;

  const history = useHistory();
  const impactCategoryUrl = useImpactCategoryUrl(impactCategory);
  const pages = usePages();

  function openAssessmentPage() {
    const path = buildPath(
      impactCategoryUrl,
      pages.Scope3Page.stringifyQueryParams({
        assessmentId,
        viewHighestImpactItems: false,
      })
    );
    return (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      history.push(path);
    };
  }

  return (
    <div className="AssessmentOverviewHeaderRow">
      {versionInfoSection}
      <SecondaryButton
        onClick={openAssessmentPage()}
        disabled={viewAssessmentButtonDisabled}
      >
        <div className="ViewAssessmentButtonContent">
          <Report width={20} />
          <FormattedMessage
            id="components/corporate-reporting-dashboards/AssessmentOverviewHeaderRow:viewAssessment"
            defaultMessage="View Assessment"
          />
        </div>
      </SecondaryButton>
    </div>
  );
}

interface AssessmentOverviewHeaderVersionInfoProps {
  assessmentIdToColourMap: Map<string, string>;
  assessments: Array<Assessment>;
  chosenAssessment: Assessment | null;
  setChosenAssessment: (assessment: Assessment | null) => void;
}

function AssessmentOverviewHeaderVersionInfo(
  props: AssessmentOverviewHeaderVersionInfoProps
) {
  const {
    assessmentIdToColourMap,
    assessments,
    chosenAssessment,
    setChosenAssessment,
  } = props;
  return (
    <div className="AssessmentOverviewHeaderVersionInfo">
      <div className="AssessmentOverviewHeaderVersionSelector">
        <FormattedMessage
          id="components/corporate-reporting-dashboards/AssessmentOverviewHeaderVersionInfo:assessment"
          defaultMessage="Assessment"
        />
        <Select<Assessment>
          className="AssessmentOverviewHeader_AssessmentSelect"
          iconFirst={true}
          iconForOption={(assessment) =>
            rectangle(assessmentIdToColourMap.get(assessment.id)!)
          }
          isClearable={false}
          onChange={(reportName) => setChosenAssessment(reportName)}
          optionKey={(reportName) => reportName.id}
          options={assessments}
          renderOption={(reportName) => reportName.name}
          dropdownArrow="upDown"
          value={chosenAssessment}
        />
      </div>

      {chosenAssessment !== null && (
        <>
          <hr className="Divider" />
          <div className="AssessmentOverviewHeaderNumberOfItems">
            <Apple width={24} />
            <FormattedMessage
              id="components/corporate-reporting-dashboards/AssessmentOverviewHeaderVersionInfo:numberOfItems"
              defaultMessage="{numberOfItems} items assessed"
              values={{
                numberOfItems: chosenAssessment.number_of_items_assessed,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}

function rectangle(color: string) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <rect width="20" height="20" rx="4" fill={color} />
    </svg>
  );
}

interface AssessmentOverviewContentProps {
  assessmentId: string;
  impactCategory: ImpactCategory;
}

function AssessmentOverviewContent(props: AssessmentOverviewContentProps) {
  const { assessmentId, impactCategory } = props;
  const sortColumn = impactSortColumn(impactCategory);
  const [itemsStatus] = useProcuredItems(assessmentId, 3, sortColumn, "desc");
  const [assessmentSummaryStatus] = useAssessmentSummary(assessmentId);
  return (
    <StatusDisplay.Many<[Array<ProcuredItem> | null, AssessmentSummary | null]>
      statuses={[itemsStatus, assessmentSummaryStatus]}
    >
      {(procuredItems, assessmentSummary) =>
        assessmentSummary !== null ? (
          <div className="AssessmentOverviewContent">
            <AssessmentOverviewSummary
              assessmentSummary={assessmentSummary}
              impactCategory={impactCategory}
            />
            <div className="AssessmentOverviewCharts">
              <div className="GridItem__ProcuredItemsByRating">
                {procuredItems !== null && (
                  <ProcuredItemsByRating
                    impactCategory={impactCategory}
                    proportions={getImpactCategoryProportions(
                      assessmentSummary,
                      impactCategory
                    )}
                  />
                )}
              </div>
              <div className="GridItem__HighestImpactProcuredItems">
                {procuredItems !== null && (
                  <HighestImpactProcuredItems
                    assessmentId={assessmentId}
                    impactCategory={impactCategory}
                    items={procuredItems.map((item, index) => ({
                      index: index + 1,
                      ...item,
                    }))}
                  />
                )}
              </div>
              <div className="GridItem__ImpactByFoodType">
                <ImpactByFoodType
                  assessmentId={assessmentId}
                  impactCategory={impactCategory}
                />
              </div>
            </div>
            <ProcurementImpactBreakdownChart
              assessmentId={assessmentId}
              impactCategory={impactCategory}
            />
          </div>
        ) : (
          <FormattedMessage
            id="components/AssessmentOverviewContent:noDataFound"
            defaultMessage="No report data found."
          />
        )
      }
    </StatusDisplay.Many>
  );
}

function impactSortColumn(impactCategory: ImpactCategory) {
  if (impactCategory === ImpactCategory.GHG) return "ghg_kg_co2e";
  else if (impactCategory === ImpactCategory.LAND_USE) {
    return "land_use_m2_year";
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    return "water_use_liters";
  } else {
    assertNever(impactCategory, "Unsupported impact category");
  }
}
