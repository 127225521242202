import averageImpactIntensityByTagStories from "./AverageImpactIntensityByTag.stories";
import productsByDietChartStories from "./ProductsByDietChart.stories";
import productsByImpactRatingStories from "./ProductsByImpactRating.stories";
import productsDashboardPageStories from "./ProductsDashboardPage.stories";

const stories = {
  name: "Dashboard",
  children: [
    averageImpactIntensityByTagStories,
    productsByDietChartStories,
    productsByImpactRatingStories,
    productsDashboardPageStories,
  ],
};

export default stories;
