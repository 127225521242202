import { FormattedMessage, useIntl } from "react-intl";

import { DashboardDietaryCategory } from "../../data-store";
import BarChart2, { Bar } from "../graphs/BarChart2";
import "./ProductsByDietChart.css";

interface ProductsByDietChartProps {
  dietaryCategories: Array<DashboardDietaryCategory>;
}

export default function ProductsByDietChart(props: ProductsByDietChartProps) {
  const { dietaryCategories } = props;

  const intl = useIntl();

  const bars: Array<Bar> = dietaryCategories.map((dietaryCategory) => {
    return {
      label: dietaryCategory.name,
      value: [dietaryCategory.productCount],
    };
  });
  const dependentAxisLabel = intl.formatMessage({
    id: "components/dashboard/ProductsByDietChart:dependentAxisLabel",
    defaultMessage: "Number of Products",
  });

  return (
    <div className="ProductsByDietChart">
      <h4 className="m-0">
        <FormattedMessage
          id="components/dashboard/ProductsByDietChart:title"
          defaultMessage="Products by Diet"
        />
      </h4>
      <BarChart2
        bars={bars}
        dependentAxisLabel={dependentAxisLabel}
        height={300}
        horizontal
        sorted
        maintainAspectRatio={false}
      />
    </div>
  );
}
