import React from "react";

import { ImpactCategory } from "../../domain/impactCategories";
import { usePages } from "../pages";
import { BaseScope3Page } from "./Scope3Page";

export function WaterUsePage() {
  const pages = usePages();

  return (
    <BaseScope3Page
      breadcrumb={pages.WaterUse.breadcrumb()}
      impactCategory={ImpactCategory.WATER_USE}
      title={pages.WaterUse.title}
    />
  );
}
