import { ImpactCategory } from "../../__generated__/globalTypes";
import { AverageImpactIntensityByTag } from "./AverageImpactIntensityByTag";

const stories = {
  name: "AverageImpactIntensityByTag",
  render: () => <Story />,
};

function Story() {
  const tags = [
    {
      name: "Summer Menu",
      productCount: 12,
      averageIntensityGhg: 10.61,
      averageIntensityLandUse: null,
      averageIntensityWaterUse: null,
    },
    {
      name: "Winter Menu",
      productCount: 19,
      averageIntensityGhg: 1.61,
      averageIntensityLandUse: null,
      averageIntensityWaterUse: null,
    },
    {
      name: "Autumn Menu",
      productCount: 1,
      averageIntensityGhg: 100.61,
      averageIntensityLandUse: null,
      averageIntensityWaterUse: null,
    },
    {
      name: "A Chair (too tall to be trusted)",
      productCount: 145,
      averageIntensityGhg: 0.1234,
      averageIntensityLandUse: null,
      averageIntensityWaterUse: null,
    },
    {
      name: "Spring Menu",
      productCount: 54,
      averageIntensityGhg: 23.45,
      averageIntensityLandUse: null,
      averageIntensityWaterUse: null,
    },
  ];

  return (
    <AverageImpactIntensityByTag
      tags={tags}
      impactCategory={ImpactCategory.GHG}
    />
  );
}

export default stories;
