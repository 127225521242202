import classNames from "classnames";
import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import "./InlineTitleToggle.css";

import RotatingChevron from "./RotatingChevron";

interface InlineTitleToggleProps<T> {
  optionIsDisabled?: (option: T) => boolean;
  onChange: (option: T) => void;
  options: T[];
  renderOption: (option: T) => React.ReactNode;
  selectedOption: T;
}

export default function InlineTitleToggle<T>(props: InlineTitleToggleProps<T>) {
  const {
    optionIsDisabled = () => false,
    onChange,
    options,
    renderOption,
    selectedOption,
  } = props;

  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const handleChange = (value: T | null) => {
    if (value === null) {
      throw new Error("value should not be clearable.");
    }
    onChange(value);
  };

  return (
    <Dropdown
      className="InlineTitleToggle"
      show={showDropdown}
      onToggle={(show: boolean) => setShowDropdown(show)}
    >
      <Dropdown.Toggle
        className="InlineTitleToggle__TitleToggle"
        variant="link"
      >
        <h3 className="mb-0">{renderOption(selectedOption)}</h3>
        <div className="my-auto ml-2">
          <RotatingChevron expanded={showDropdown} />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="InlineTitleToggle__Menu">
        {options.map((option, index) => (
          <Dropdown.Item
            disabled={optionIsDisabled(option)}
            key={index}
            className={classNames("InlineTitleToggle__Item", {
              InlineTitleToggle__Item__Active: option === selectedOption,
              InlineTitleToggle__Item__First: index === 0,
              InlineTitleToggle__Item__Last: index === options.length - 1,
            })}
            onSelect={() => handleChange(option)}
          >
            {renderOption(option)}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
