import { useState } from "react";

import {
  ImpactCategory,
  useImpactCategoryLabel,
} from "../../domain/impactCategories";
import InlineTitleToggle from "./InlineTitleToggle";

const stories = {
  name: "InlineTitleToggle",
  render: () => <Story />,
};

function Story() {
  const [impactCategory, setImpactCategory] = useState<ImpactCategory>(
    ImpactCategory.GHG
  );
  const impactCategoryLabel = useImpactCategoryLabel;

  return (
    <InlineTitleToggle<ImpactCategory>
      onChange={setImpactCategory}
      optionIsDisabled={(option: ImpactCategory) =>
        option === ImpactCategory.WATER_USE
      }
      options={Object.values(ImpactCategory)}
      selectedOption={impactCategory}
      renderOption={impactCategoryLabel}
    />
  );
}

export default stories;
