import { ImpactCategory } from "../../domain/impactCategories";
import { ProductsByImpactRating } from "./ProductsByImpactRating";

const stories = {
  name: "ProductsByImpactRating",
  render: () => <Story />,
};

function Story() {
  const impactRatingProportions = {
    ghg: {
      veryLow: 0.2,
      low: 0.1,
      medium: 0,
      high: 0.6,
      veryHigh: 0.1,
    },
    landUse: {
      veryLow: 0.5,
      low: 0.1,
      medium: 0.1,
      high: 0.01,
      veryHigh: 0.19,
    },
  };

  return (
    <ProductsByImpactRating
      impactCategory={ImpactCategory.GHG}
      impactRatingProportions={impactRatingProportions}
    />
  );
}

export default stories;
