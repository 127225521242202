import { FunctionalUnit } from "../../../domain/functionalUnits";
import { ImpactCategory } from "../../../domain/impactCategories";
import assertNever from "../../../util/assertNever";
import { convertSvgToBase64Png } from "../../../util/convertSvgToPng";
import DescriptiveImpactAndScale from "../../utils/Vectors/labels/DescriptiveImpactAndScale";
import DescriptiveRating from "../../utils/Vectors/labels/DescriptiveRating";
import DescriptiveRatingScale from "../../utils/Vectors/labels/DescriptiveRatingScale";
import LetterRating from "../../utils/Vectors/labels/LetterRating";
import RatingScale from "../../utils/Vectors/labels/RatingScale";
import TitledRatingScale from "../../utils/Vectors/labels/TitledRatingScale";
import {
  labelFunctionalUnitToDimensions,
  labelSizeToDimensions,
  labelTypeToDimensions,
} from "../labelDimensions";
import { LabelColourSetting, LabelSizeOption, LabelType } from "../labelTypes";
import getImpactRating from "./getLandUseImpactRating";

interface LandUseLabelProps {
  colourSetting: LabelColourSetting;
  impactMagnitude: number;
  type: LabelType;
  width: number;
  locale: string | null;
}

export default function LandUseLabel(props: LandUseLabelProps) {
  const { colourSetting, impactMagnitude, locale, type, width } = props;

  const impactRating = getImpactRating(impactMagnitude);

  switch (type) {
    case "descriptiveImpactAndScale":
      return (
        <DescriptiveImpactAndScale
          greyscale={colourSetting === "monochrome"}
          locale={locale}
          impactCategory={ImpactCategory.LAND_USE}
          functionalUnit={FunctionalUnit.KG}
          impactMagnitude={impactMagnitude}
          impactRating={impactRating}
          width={width}
        />
      );
    case "descriptiveRating":
      return (
        <DescriptiveRating
          greyscale={colourSetting === "monochrome"}
          impactCategory={ImpactCategory.LAND_USE}
          impactRating={impactRating}
          locale={locale}
          width={width}
        />
      );
    case "descriptiveRatingScale":
      return (
        <DescriptiveRatingScale
          greyscale={colourSetting === "monochrome"}
          impactCategory={ImpactCategory.LAND_USE}
          impactRating={impactRating}
          locale={locale}
          width={width}
        />
      );
    case "letterRating":
      return (
        <LetterRating
          greyscale={colourSetting === "monochrome"}
          impactCategory={ImpactCategory.LAND_USE}
          impactRating={impactRating}
          width={width}
        />
      );
    case "ratingScale":
      return (
        <RatingScale
          greyscale={colourSetting === "monochrome"}
          impactCategory={ImpactCategory.LAND_USE}
          impactRating={impactRating}
          width={width}
        />
      );
    case "titledRatingScale":
      return (
        <TitledRatingScale
          greyscale={colourSetting === "monochrome"}
          impactCategory={ImpactCategory.LAND_USE}
          impactRating={impactRating}
          locale={locale}
          width={width}
        />
      );
  }
}

export async function generateLandUseLabelPngBase64(
  colourSetting: LabelColourSetting,
  impactMagnitude: number,
  type: LabelType,
  functionalUnit: FunctionalUnit,
  size: LabelSizeOption,
  locale: string | null
) {
  let dimensions;

  if (type === "descriptiveImpactAndScale") {
    dimensions = labelFunctionalUnitToDimensions(functionalUnit);
  } else if (
    type === "descriptiveRatingScale" ||
    type === "titledRatingScale"
  ) {
    dimensions = labelTypeToDimensions(type);
  } else if (
    type === "descriptiveRating" ||
    type === "letterRating" ||
    type === "ratingScale"
  ) {
    dimensions = labelSizeToDimensions(type, size);
  } else {
    assertNever(type, "Invalid label type");
  }

  const svgElement = (
    <LandUseLabel
      colourSetting={colourSetting}
      width={dimensions.width}
      type={type}
      impactMagnitude={impactMagnitude}
      locale={locale}
    />
  );

  return await convertSvgToBase64Png({
    height: dimensions.height,
    svgElement,
    width: dimensions.width,
  });
}
