import { gql } from "graphql-tag";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";

import * as comparators from "../../../util/comparators";
import sort from "../../../util/sort";
import * as FloatInput from "../../utils/FloatInput";
import ReadResult from "../../utils/ReadResult";
import Select from "../../utils/Select";
import TableEditor from "../../utils/TableEditor";
import { RecipeStorageEditor_StorageAppliance as StorageAppliance } from "./RecipeStorageEditor.graphql";

interface StoragePenalty {
  daysInStorage: number;
  id: number;
  storageAppliance: StorageAppliance;
  usesRenewableElectricity: boolean;
}

export type Value = Array<EditorStoragePenalty>;

interface EditorStoragePenalty {
  key: string;
  storageAppliance: StorageAppliance | null;
  existingStoragePenalty: StoragePenalty | null;
  daysInStorage: FloatInput.Value;
  invalidStorageAppliance: boolean;
}

let nextNewPenaltyId = 1;
function nameWithRenewableElectricitySuffix(name: string): string {
  return name + " (Renewable Electricity)";
}

export function initialBlankValue() {
  return [blankPenalty()];
}

export function initialValue(
  penalties: Array<StoragePenalty> | undefined
): Value {
  return penalties === undefined || penalties.length === 0
    ? initialBlankValue()
    : penalties.map(existingPenalty);
}

function blankPenalty(): EditorStoragePenalty {
  return {
    key: "new_" + nextNewPenaltyId++,
    storageAppliance: null,
    existingStoragePenalty: null,
    daysInStorage: FloatInput.initialValue(null),
    invalidStorageAppliance: false,
  };
}

function existingPenalty(penalty: StoragePenalty): EditorStoragePenalty {
  return {
    key: "existing_" + penalty.id,
    storageAppliance: {
      ...penalty.storageAppliance,
      name: penalty.usesRenewableElectricity
        ? nameWithRenewableElectricitySuffix(penalty.storageAppliance.name)
        : penalty.storageAppliance.name,
    },
    existingStoragePenalty: penalty,
    daysInStorage: FloatInput.initialValue(penalty.daysInStorage),
    invalidStorageAppliance: false,
  };
}

interface StoragePenaltyInput {
  daysInStorage: number;
  id: number | null;
  storageApplianceId: number;
  usesRenewableElectricity: boolean;
}

export function read(
  value: Value
): ReadResult<Value, Array<StoragePenaltyInput>> {
  let hasError = false;
  const newValue: Array<EditorStoragePenalty> = [];
  const inputs: Array<StoragePenaltyInput> = [];
  for (const penalty of value) {
    const daysInStorage = FloatInput.read({ value: penalty.daysInStorage });
    if (penalty.storageAppliance === null) {
      newValue.push({
        ...penalty,
        invalidStorageAppliance: true,
        daysInStorage: daysInStorage.value,
      });
      hasError = true;
    } else {
      newValue.push({ ...penalty, daysInStorage: daysInStorage.value });

      if (daysInStorage.hasError) {
        hasError = true;
      } else {
        inputs.push({
          storageApplianceId: penalty.storageAppliance.id,
          id:
            penalty.existingStoragePenalty === null
              ? null
              : penalty.existingStoragePenalty.id,
          daysInStorage: daysInStorage.input,
          // TODO: Remove this slightly hacky way when we add a separate
          //  method for the user to select renewable electricity
          usesRenewableElectricity: penalty.storageAppliance.name
            .toLowerCase()
            .includes("renewable"),
        });
      }
    }
  }
  return { hasError, value: newValue, input: inputs };
}

interface RecipeStorageEditorProps {
  storageAppliances: Array<StorageAppliance>;
  onChange: (value: Value) => void;
  value: Value;
}

export function RecipeStorageEditor(props: RecipeStorageEditorProps) {
  const { storageAppliances, onChange, value } = props;

  const handleStoragePenaltyChange = (value: Value) => {
    onChange(
      value.map((penalty) => {
        return {
          ...penalty,
          invalidStorageAppliance: false,
          daysInStorage: { ...penalty.daysInStorage, isInvalid: false },
        };
      })
    );
  };

  return (
    <div className="w-75">
      <TableEditor
        blank={blankPenalty}
        onChange={handleStoragePenaltyChange}
        renderRow={({ onChange, onDelete, rowIndex, value }) => (
          <RecipeStoragePenaltyEditor
            canDelete={rowIndex !== 0 || storageAppliances.length > 1}
            storageAppliances={storageAppliances}
            onChange={onChange}
            onDelete={onDelete}
            value={value}
          />
        )}
        showAddButton={true}
        value={value}
      />
    </div>
  );
}

interface RecipeStoragePenaltyEditorProps {
  canDelete: boolean;
  storageAppliances: Array<StorageAppliance>;
  onChange: (value: EditorStoragePenalty) => void;
  onDelete: () => void;
  value: EditorStoragePenalty;
}

function RecipeStoragePenaltyEditor(props: RecipeStoragePenaltyEditorProps) {
  const { canDelete, storageAppliances, onChange, onDelete, value } = props;

  const deleteColumnWidth = "20px";
  const storageAppliancesToShow: Array<StorageAppliance> = [];
  storageAppliances.forEach((storageAppliance) => {
    if (storageAppliance.name === "Ambient Storage") {
      storageAppliancesToShow.push(storageAppliance);
    } else {
      storageAppliancesToShow.push(
        ...[
          storageAppliance,
          {
            ...storageAppliance,
            name: nameWithRenewableElectricitySuffix(storageAppliance.name),
          },
        ]
      );
    }
  });

  return (
    <tr>
      <td style={{ width: "384px" }}>
        <StorageApplianceSelect
          isInvalid={value.invalidStorageAppliance}
          storageAppliances={storageAppliancesToShow}
          onChange={(storageAppliance) =>
            onChange({ ...value, storageAppliance })
          }
          value={value.storageAppliance}
        />
      </td>
      <td className="pl-3" style={{ width: "160px" }}>
        <div className="input-group">
          <FloatInput.FloatInput
            onChange={(daysInStorage) => onChange({ ...value, daysInStorage })}
            value={value.daysInStorage}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              <FormattedMessage
                id="components/recipes/RecipeEditor/RecipeStorageEditor:days"
                defaultMessage="days"
              />
            </span>
          </div>
        </div>
      </td>
      {canDelete ? (
        <TableEditor.DeleteCell
          className="pl-2"
          onDelete={onDelete}
          width={deleteColumnWidth}
        />
      ) : (
        <td style={{ width: deleteColumnWidth }}></td>
      )}
    </tr>
  );
}

interface StorageApplianceSelectProps {
  isInvalid: boolean;
  storageAppliances: Array<StorageAppliance>;
  onChange: (value: StorageAppliance | null) => void;
  value: StorageAppliance | null;
}

function StorageApplianceSelect(props: StorageApplianceSelectProps) {
  const { isInvalid, storageAppliances, onChange, value } = props;

  const sortedStorageAppliances = useMemo(
    () =>
      sort(
        storageAppliances,
        comparators.map(
          (storageAppliance) => storageAppliance.name,
          comparators.stringSensitivityBase
        )
      ),
    [storageAppliances]
  );

  return (
    <Select
      className={isInvalid ? "is-invalid" : ""}
      onChange={(value) => onChange(value)}
      optionKey={storageApplianceKey}
      options={sortedStorageAppliances}
      renderOption={(storageAppliance) => storageAppliance.name}
      value={value}
    />
  );
}

function storageApplianceKey(storageAppliance: StorageAppliance) {
  return storageAppliance.id.toString() + storageAppliance.name;
}

export const fragments = {
  storageAppliance: gql`
    fragment RecipeStorageEditor_StorageAppliance on StorageAppliance {
      id
      name
    }
  `,
};
