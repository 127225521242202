import { ImpactRating } from "../../domain/impactRatings";

export const impactRatingLetterColor = (
  impactRating: ImpactRating,
  greyscale: boolean = false
) => {
  if (greyscale) {
    return ["VLOW", "LOW"].includes(impactRating) ? "black" : "white";
  } else {
    return ["LOW", "MEDIUM"].includes(impactRating) ? "black" : "white";
  }
};
