import maxBy from "lodash/maxBy";
import minBy from "lodash/minBy";
import sum from "lodash/sum";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useMemoOne } from "use-memo-one";

import useMethodologySummaryUrl from "../../data-store/useMethodologySummaryUrl";
import { impactCategoryToEffectTypePerKg } from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import { useScope3Report } from "../../services/useOrganizationFeatures";
import assertNever from "../../util/assertNever";
import * as statuses from "../../util/statuses";
import Page from "../Page";
import StatusDisplay from "../StatusDisplay";
import { SecondaryButtonLink } from "../utils/Button";
import Card from "../utils/Card";
import DietaryCategoriesImpactChartCard from "./DietaryCategoriesImpactChartCard";
import InformationBanner from "./InformationBanner";
import ProductsDashboardPage from "./ProductsDashboardPage";
import RecipeBreakdown from "./RecipeBreakdown";
import RecipeImpactByCollectionChartCard from "./RecipeImpactByCollectionChartCard";
import RecipeImpactChartCard from "./RecipeImpactChartCard";
import {
  Recipe,
  RecipeCollection,
  useDashboardPageData,
} from "./useDashboardPageData";
import WelcomeBanner from "./WelcomeBanner";

const tracking = { pageName: "home" };

export default function DashboardPage() {
  const scope3Report = useScope3Report();
  return scope3Report ? <ProductsDashboardPage /> : <OldDashboardPage />;
}

function OldDashboardPage() {
  const { status: queryStatus } = useDashboardPageData();
  const collectionsStatus = useMemoOne(
    () =>
      statuses.map(queryStatus, (data) =>
        data.recipeCollections.edges.map((edge) => edge.node)
      ),
    [queryStatus]
  );
  const recipesStatus = useMemoOne(
    () =>
      statuses.map(queryStatus, (data) =>
        data.recipes.edges.map((edge) => edge.node)
      ),
    [queryStatus]
  );

  const [methodologySummaryUrlStatus] = useMethodologySummaryUrl();

  return (
    <Page tracking={tracking}>
      <StatusDisplay.Many<
        [Array<RecipeCollection>, Array<Recipe>, string | null]
      >
        statuses={[
          collectionsStatus,
          recipesStatus,
          methodologySummaryUrlStatus,
        ]}
      >
        {(collections, recipes, methodologySummaryUrl) => (
          <HomeDash
            collections={collections}
            methodologySummaryUrl={methodologySummaryUrl}
            recipes={recipes}
          />
        )}
      </StatusDisplay.Many>
    </Page>
  );
}

interface UserOverviewProps {
  collections: Array<RecipeCollection>;
  highestImpact: Recipe | undefined;
  lowestImpact: Recipe | undefined;
  impactCategory: ImpactCategory;
  meanImpactPerKg: string;
  recipes: Array<Recipe>;
}

export function getImpactPerKg(
  recipe: {
    impactCached: {
      ghgPerKg: number | null;
      landUsePerKg: number | null;
      waterUsePerKg: number | null;
    };
  },
  impactCategory: ImpactCategory
): number | null {
  if (impactCategory === ImpactCategory.GHG) {
    return recipe.impactCached?.ghgPerKg ?? null;
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    return recipe.impactCached?.landUsePerKg ?? null;
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    return recipe.impactCached?.waterUsePerKg ?? null;
  } else {
    assertNever(impactCategory, "invalid ImpactCategory");
  }
}

function UserOverview(props: UserOverviewProps) {
  const {
    highestImpact,
    lowestImpact,
    collections,
    impactCategory,
    meanImpactPerKg,
    recipes,
  } = props;

  const intl = useIntl();

  const recipeLabel = intl.formatMessage({
    id: "components/dashboard/DashboardPage:recipeLabel/meal",
    defaultMessage: "Product",
  });

  const effectType = impactCategoryToEffectTypePerKg(impactCategory);

  return (
    <div className="row mb-4">
      <div className="col">
        {collections.length === 0 ? (
          <RecipeImpactChartCard
            impactCategory={impactCategory}
            recipes={recipes}
            recipeLabel={recipeLabel}
            meanImpactPerKg={meanImpactPerKg}
          />
        ) : (
          <RecipeImpactByCollectionChartCard
            impactCategory={impactCategory}
            meanImpactPerKg={meanImpactPerKg}
            collections={collections}
            recipes={recipes}
          />
        )}
      </div>

      <div className="col-3">
        <Card fullHeight shadow={false}>
          <RecipeBreakdown
            impactAdjective={intl.formatMessage({
              id: "components/dashboard/DashboardPage:impactAdjective/highest",
              defaultMessage: "Highest",
            })}
            recipeId={highestImpact?.id}
            recipeName={highestImpact?.name}
            recipeImpact={
              highestImpact
                ? getImpactPerKg(highestImpact, impactCategory)?.toFixed(
                    effectType.decimals
                  )
                : undefined
            }
            recipeLabel={recipeLabel}
            impactUnit={effectType.unit}
          />
          <hr className="my-3" />
          <RecipeBreakdown
            impactAdjective={intl.formatMessage({
              id: "components/dashboard/DashboardPage:impactAdjective/lowest",
              defaultMessage: "Lowest",
            })}
            recipeId={lowestImpact?.id}
            recipeName={lowestImpact?.name}
            recipeImpact={
              lowestImpact
                ? getImpactPerKg(lowestImpact, impactCategory)?.toFixed(
                    effectType.decimals
                  )
                : undefined
            }
            recipeLabel={recipeLabel}
            impactUnit={effectType.unit}
          />
        </Card>
      </div>

      <DietaryCategoriesImpactChartCard
        impactCategory={impactCategory}
        meanImpactPerKg={meanImpactPerKg}
        recipes={recipes}
      />
    </div>
  );
}

interface HomeDashProps {
  collections: Array<RecipeCollection>;
  methodologySummaryUrl: string | null;
  recipes: Array<Recipe>;
  showWelcomeBanner?: boolean;
}

function HomeDash(props: HomeDashProps) {
  const { collections, methodologySummaryUrl, recipes } = props;

  const [impactCategory, setImpactCategory] = useState(ImpactCategory.GHG);

  const intl = useIntl();

  const getUserStats = (
    impactCategory: ImpactCategory,
    recipes: Array<Recipe>
  ) => {
    const calculatedRecipes = recipes.filter((x) => x.impactCached !== null);

    let vLowScores = calculatedRecipes.filter((x) => {
      return x.impactCached?.impactRatingGhg === "VLOW";
    }).length;
    const stats = {
      meanImpactPerKg:
        sum(
          calculatedRecipes.map((recipe) =>
            getImpactPerKg(recipe, impactCategory)
          )
        ) / calculatedRecipes.length,
      vLowScores,
      recipeHighestImpact: maxBy(calculatedRecipes, (recipe) =>
        getImpactPerKg(recipe, impactCategory)
      ),
      recipeLowestImpact: minBy(calculatedRecipes, (recipe) =>
        getImpactPerKg(recipe, impactCategory)
      ),
    };

    return {
      stats,
      noCalculatedRecipes: calculatedRecipes.length === 0,
      recipeCount: calculatedRecipes.length,
    };
  };

  const { stats, noCalculatedRecipes, recipeCount } = useMemo(
    () => getUserStats(impactCategory, recipes),
    [impactCategory, recipes]
  );

  const effectType = impactCategoryToEffectTypePerKg(impactCategory);

  let meanImpactPerKg;
  if (!noCalculatedRecipes) {
    if (stats.meanImpactPerKg === undefined) {
      meanImpactPerKg = " ";
    } else {
      meanImpactPerKg = stats.meanImpactPerKg.toFixed(effectType.decimals);
    }
  } else {
    meanImpactPerKg = intl.formatMessage({
      id: "components/dashboard/DashboardPage:notApplicable",
      defaultMessage: "n/a",
    });
  }

  return (
    <div className="home-dash">
      {methodologySummaryUrl === null ? null : (
        <div className="mb-4 text-right">
          <SecondaryButtonLink external to={methodologySummaryUrl}>
            <FormattedMessage
              id="components/dashboard/DashboardPage:downloadMethodologySummary"
              defaultMessage="Download Methodology Summary"
            />
          </SecondaryButtonLink>
        </div>
      )}
      <WelcomeBanner
        impactCategory={impactCategory}
        onImpactCategoryChange={setImpactCategory}
      />
      <InformationBanner
        effectType={effectType}
        meanImpactPerUnit={meanImpactPerKg}
        veryLowScores={stats.vLowScores}
        numberOfRecipes={recipeCount}
      />
      <UserOverview
        impactCategory={impactCategory}
        highestImpact={stats.recipeHighestImpact}
        lowestImpact={stats.recipeLowestImpact}
        collections={collections}
        recipes={recipes}
        meanImpactPerKg={meanImpactPerKg}
      />
    </div>
  );
}
