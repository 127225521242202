import gql from "graphql-tag";
import sum from "lodash/sum";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { useMemoOne } from "use-memo-one";

import { ImpactUnit } from "../../__generated__/globalTypes";
import { effectTypes } from "../../domain/EffectType";
import { useFoodManufacturerOrganization } from "../../services/useOrganizationFeatures";
import BarChart from "../graphs/BarChart";
import { foodstepsTurquoiseCol } from "../graphs/colors";
import HelpModalTooltip from "../utils/HelpModalTooltip";
import { Panel } from "../utils/Panel";
import CardTitle from "./CardTitle";
import { LifeCycleChartCard_RecipeImpact } from "./LifeCycleChartCard.graphql";

interface LifeCycleChartCardProps {
  impact: LifeCycleChartCard_RecipeImpact;
}

export default function LifeCycleChartCard(props: LifeCycleChartCardProps) {
  const { impact } = props;

  const effectType = effectTypes.ghgPerServing;
  const intl = useIntl();
  const isFoodManufacturerOrganization = useFoodManufacturerOrganization();

  const lifeCycleData = useMemoOne(() => {
    const stageImpacts = impact.effects?.stages ?? [];
    const stageImpactsByCodeName = new Map(
      stageImpacts.map((stageImpact) => [stageImpact.codename, stageImpact])
    );
    const getStageEffect = (codename: string) => {
      let impact = stageImpactsByCodeName.get(codename) ?? null;
      if (codename === "ghg_processing" && isFoodManufacturerOrganization) {
        const cookingImpact = stageImpactsByCodeName.get("ghg_cooking") ?? null;
        if (impact === null) {
          impact = cookingImpact;
        } else if (cookingImpact !== null) {
          if (impact.impactPerRecipe !== null) {
            impact.impactPerRecipe += cookingImpact.impactPerRecipe ?? 0;
          }
          if (impact.impactPerRootRecipeServing !== null) {
            impact.impactPerRootRecipeServing +=
              cookingImpact.impactPerRootRecipeServing ?? 0;
          }
        }
      }

      const impactUnit = impact?.impactUnit;
      let effects: any;
      if (impactUnit === ImpactUnit.CO2E) {
        effects = {
          ghgPerRecipe: impact?.impactPerRecipe ?? null,
          ghgPerRootRecipeServing: impact?.impactPerRootRecipeServing ?? null,
        };
      } else if (impactUnit === ImpactUnit.M2_YEAR_LAND_USE) {
        effects = {
          landUsePerRecipe: impact?.impactPerRecipe ?? null,
          landUsePerRootRecipeServing:
            impact?.impactPerRootRecipeServing ?? null,
        };
      } else {
        effects = null;
      }

      return effectType.get(
        impact
          ? {
              effects,
            }
          : null
      );
    };

    return processLifeCycleData({
      getStageEffect,
      intl,
      isFoodManufacturerOrganization,
    });
  }, [effectType, intl, impact, isFoodManufacturerOrganization]);

  return impact.effects?.stages ? (
    <Panel className="d-flex flex-column vh-40">
      <div className="position-relative">
        <CardTitle
          title={intl.formatMessage({
            id: "components/recipes/LifeCycleChartCard:title",
            defaultMessage: "Emissions from each life cycle stage",
          })}
        />
        {!isFoodManufacturerOrganization && (
          <div className="position-absolute" style={{ top: 0, right: 0 }}>
            <HelpModalTooltip
              title={
                <FormattedMessage
                  id="components/recipes/LifeCycleChartCard/LifeCycleFaqModal/title"
                  defaultMessage="Life cycle stage FAQs"
                />
              }
            >
              <HelpModalTooltip.Question
                question={
                  <FormattedMessage
                    id="components/recipes/LifeCycleChartCard/LifeCycleFaqModal/question"
                    defaultMessage="Why can I still see a packaging impact after switching the packaging off?"
                  />
                }
                answer={
                  <>
                    <p>
                      <FormattedMessage
                        id="components/recipes/LifeCycleChartCard/LifeCycleFaqModal/answerParagraph1"
                        defaultMessage="The impact of the packaging life cycle stage covers all packaging used throughout the cradle-to-grave life cycle. This is made up of the packaging of the final item, as defined by the packaging components on your product, and an estimate for the packaging of individual ingredients."
                      />
                    </p>
                    <p>
                      <FormattedMessage
                        id="components/recipes/LifeCycleChartCard/LifeCycleFaqModal/answerParagraph2"
                        defaultMessage="Excluding packaging using the toggle removes the impact of your product's final packaging. The remaining impact shown for the packaging lifecycle stage is the estimated impact for the packaging of individual ingredients through the life cycle."
                      />
                    </p>
                  </>
                }
              />
            </HelpModalTooltip>
          </div>
        )}
      </div>
      <div className="flex-grow-1 pt-4">
        <BarChart
          chartData={lifeCycleData}
          effectType={effectType}
          lifeCycle={true}
        />
      </div>
    </Panel>
  ) : null;
}

interface Stage {
  codenames: Array<string>;
  label: string;
}

const processLifeCycleData = ({
  getStageEffect,
  intl,
  isFoodManufacturerOrganization,
}: {
  getStageEffect: (codename: string) => number | null;
  intl: IntlShape;
  isFoodManufacturerOrganization: boolean;
}) => {
  const foodServiceStages: Array<Stage> = [
    {
      codenames: ["ghg_tran_str"],
      label: intl.formatMessage({
        id: "components/graphs/graphDataAndStyles:lifeCycles/transport/label",
        defaultMessage: "Transport",
      }),
    },
    {
      codenames: ["ghg_retail"],
      label: intl.formatMessage({
        id: "components/graphs/graphDataAndStyles:lifeCycles/retail/label",
        defaultMessage: "Retail",
      }),
    },
    {
      codenames: ["ghg_end_mile"],
      label: intl.formatMessage({
        id: "components/graphs/graphDataAndStyles:lifeCycles/endMile/label",
        defaultMessage: "End-mile",
      }),
    },
    {
      codenames: ["ghg_cooking"],
      label: intl.formatMessage({
        id: "components/graphs/graphDataAndStyles:lifeCycles/cooking/label",
        defaultMessage: "Cooking",
      }),
    },
  ];

  const lifeCycleStages: Array<Stage> = [
    {
      codenames: ["ghg_farm", "ghg_feed", "ghg_luc_burn", "ghg_luc_c_stock"],
      label: intl.formatMessage({
        id: "components/graphs/graphDataAndStyles:lifeCycles/farm/label",
        defaultMessage: "Farm",
      }),
    },
    {
      codenames: isFoodManufacturerOrganization
        ? ["ghg_processing", "ghg_cooking"]
        : ["ghg_processing"],
      label: intl.formatMessage({
        id: "components/graphs/graphDataAndStyles:lifeCycles/processing/label",
        defaultMessage: "Processing",
      }),
    },
    {
      codenames: ["ghg_packaging"],
      label: intl.formatMessage({
        id: "components/graphs/graphDataAndStyles:lifeCycles/packaging/label",
        defaultMessage: "Packaging",
      }),
    },
    ...(isFoodManufacturerOrganization ? [] : foodServiceStages),
    {
      codenames: ["ghg_end_of_life", "ghg_loss"],
      label: intl.formatMessage({
        id: "components/graphs/graphDataAndStyles:lifeCycles/foodWaste/label",
        defaultMessage: "Food Waste",
      }),
    },
  ];

  const labelledValues = lifeCycleStages.map((stage) => {
    const value = sum(
      stage.codenames.map((codename) => getStageEffect(codename) ?? 0)
    );
    return {
      label: stage.label,
      value,
    };
  });
  const labels = labelledValues.map(({ label }) => label);

  // We don't use effects.ghg directly since the sum of the effects from each
  // stage may not equal effects.ghg due to gaps in the underlying data.
  // We use abs() to ensure that emissions have positive values,
  // while sequestration has negative values,
  // even if the total impact is negative.
  const totalGhg = Math.abs(sum(labelledValues.map(({ value }) => value)));

  let dataset = {
    data: labelledValues.map(({ value }) => {
      return (value / totalGhg) * 100;
    }),
    backgroundColor: foodstepsTurquoiseCol,
    hoverBackgroundColor: foodstepsTurquoiseCol,
    labels,
    barPercentage: 0.15,
  };
  return { labels, datasets: [dataset] };
};

LifeCycleChartCard.fragments = {
  recipeImpact: gql`
    fragment LifeCycleChartCard_RecipeImpact on RecipeImpact {
      effects {
        stages {
          codename
          impactPerRecipe
          impactPerRootRecipeServing
          impactUnit
        }
      }
    }
  `,
};
