import { ImpactRating } from "../../../../__generated__/globalTypes";
import { ImpactCategory } from "../../../../domain/impactCategories";
import { useImpactRatingToColor } from "../../../../domain/impactRatings";
import { graphikSemiboldWebCssString } from "../../../../fonts/graphik-web/graphikSemiboldWebCssString";
import {
  darkTurquoise,
  foodstepsTurquoise,
  mint,
} from "../../../graphs/colors";
import { impactRatingLetterColor } from "../../impactRatingLetterColor";
import { impactCategoryTitle, impactRatingToLetter } from "./translations";

interface TitledRatingScaleProps {
  greyscale?: boolean;
  impactCategory: ImpactCategory;
  impactRating: ImpactRating;
  width: number;
  locale: string | null;
}

export default function TitledRatingScale(props: TitledRatingScaleProps) {
  const {
    greyscale = false,
    impactCategory,
    impactRating,
    width,
    locale,
  } = props;

  return (
    <svg
      width={width}
      viewBox="0 0 135 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>{graphikSemiboldWebCssString}</style>
      <ImpactCategoryIllustration
        greyscale={greyscale}
        impactCategory={impactCategory}
      />
      <rect x="46" y="1" width="88" height="60" rx="7" fill="white" />
      <rect
        x="46"
        y="1"
        width="88"
        height="60"
        rx="7"
        stroke="black"
        strokeWidth="2"
      />
      <text
        x={90}
        y={17}
        textAnchor="middle"
        color="black"
        fontFamily="Graphik Web"
        fontWeight={500}
        fontSize={impactCategoryTitleFontSize(impactCategory, locale)}
        fill="black"
      >
        {impactCategoryTitle(impactCategory, locale)}
      </text>
      <ImpactRatingScale greyscale={greyscale} impactRating={impactRating} />
      <defs>
        <clipPath id="clip0_148_2255">
          <rect width="67" height="62" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

interface ImpactRatingScaleProps {
  greyscale: boolean;
  impactRating: ImpactRating;
}

function ImpactRatingScale(props: ImpactRatingScaleProps) {
  const { greyscale, impactRating } = props;

  const impactRatingToColor = useImpactRatingToColor(greyscale);

  const ratingLetterColor = impactRatingLetterColor(impactRating, greyscale);
  const ratingLetter = impactRatingToLetter(impactRating);

  const ratingLetterY = 45.5;
  const ratingLetterXA = 66;
  const ratingLetterFontSize = 18;
  const distanceBetweenRatingLetters = 12;

  switch (impactRating) {
    case ImpactRating.VLOW:
      return (
        <>
          <rect
            x="54"
            y="25"
            width="24"
            height="28"
            rx="5"
            fill={impactRatingToColor(ImpactRating.VLOW)}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <path
            d="M82 30H91V48H82V30Z"
            fill={impactRatingToColor(ImpactRating.LOW)}
          />
          <rect
            x="94"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
          />
          <rect
            x="106"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor(ImpactRating.HIGH)}
          />
          <path
            d="M118 30H123C125.209 30 127 31.7909 127 34V44C127 46.2091 125.209 48 123 48H118V30Z"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
          />
        </>
      );
    case ImpactRating.LOW:
      return (
        <>
          <path
            d="M53 34C53 31.7909 54.7909 30 57 30H62V48H57C54.7909 48 53 46.2091 53 44V34Z"
            fill={impactRatingToColor(ImpactRating.VLOW)}
          />
          <rect
            x="66"
            y="25"
            width="24"
            height="28"
            rx="5"
            fill={impactRatingToColor(ImpactRating.LOW)}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <rect
            x="94"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
          />
          <rect
            x="106"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor(ImpactRating.HIGH)}
          />
          <path
            d="M118 30H123C125.209 30 127 31.7909 127 34V44C127 46.2091 125.209 48 123 48H118V30Z"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
          />
        </>
      );
    case ImpactRating.MEDIUM:
      return (
        <>
          <path
            d="M53 34C53 31.7909 54.7909 30 57 30H62V48H57C54.7909 48 53 46.2091 53 44V34Z"
            fill={impactRatingToColor(ImpactRating.VLOW)}
          />
          <rect
            x="65"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor(ImpactRating.LOW)}
          />
          <rect
            x="78"
            y="25"
            width="24"
            height="28"
            rx="5"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 2 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <rect
            x="106"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor(ImpactRating.HIGH)}
          />
          <path
            d="M118 30H123C125.209 30 127 31.7909 127 34V44C127 46.2091 125.209 48 123 48H118V30Z"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
          />
        </>
      );
    case ImpactRating.HIGH:
      return (
        <>
          <path
            d="M53 34C53 31.7909 54.7909 30 57 30H62V48H57C54.7909 48 53 46.2091 53 44V34Z"
            fill={impactRatingToColor(ImpactRating.VLOW)}
          />
          <rect
            x="65"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor(ImpactRating.LOW)}
          />
          <rect
            x="77"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
          />
          <rect
            x="90"
            y="25"
            width="24"
            height="28"
            rx="5"
            fill={impactRatingToColor(ImpactRating.HIGH)}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 3 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <path
            d="M118 30H123C125.209 30 127 31.7909 127 34V44C127 46.2091 125.209 48 123 48H118V30Z"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
          />
        </>
      );
    case ImpactRating.VHIGH:
      return (
        <>
          <path
            d="M53 34C53 31.7909 54.7909 30 57 30H62V48H57C54.7909 48 53 46.2091 53 44V34Z"
            fill={impactRatingToColor(ImpactRating.VLOW)}
          />
          <rect
            x="65"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor(ImpactRating.LOW)}
          />
          <rect
            x="77"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
          />
          <path
            d="M89 30H98V48H89V30Z"
            fill={impactRatingToColor(ImpactRating.HIGH)}
          />
          <rect
            x="102"
            y="25"
            width="24"
            height="28"
            rx="5"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 4 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
        </>
      );
  }
}

interface ImpactCategoryIllustrationProps {
  greyscale: boolean;
  impactCategory: ImpactCategory;
}

function ImpactCategoryIllustration(props: ImpactCategoryIllustrationProps) {
  const { greyscale, impactCategory } = props;

  switch (impactCategory) {
    case ImpactCategory.GHG:
      return (
        <>
          <path
            d="M31.3091 60.1873C47.1075 60.1873 59.9146 47.3802 59.9146 31.5818C59.9146 15.7834 47.1075 2.97632 31.3091 2.97632C15.5107 2.97632 2.70361 15.7834 2.70361 31.5818C2.70361 47.3802 15.5107 60.1873 31.3091 60.1873Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M55.553 26.0874C54.6466 25.6343 53.7403 25.6343 52.3809 26.9937C51.8031 27.5715 51.9277 28.1266 51.9277 29.2595C51.9277 29.613 51.5691 31.2482 51.1499 31.5253C49.5999 32.55 46.9498 32.0419 45.8101 31.5253C44.7769 31.0563 43.1999 31.2194 42.638 32.205C41.7317 33.7911 42.4114 35.3771 43.9975 36.0569C44.9287 36.4557 47.0644 36.425 47.2749 38.75C47.4395 40.5683 44.4506 40.815 44.2241 43.3074C43.9975 45.7997 42.2098 46.9054 41.4032 48.8449C40.5965 50.7844 40.2884 51.8584 39.3209 53.1205C38.0181 54.8175 35.6707 55.6332 33.5953 55.1098C32.8204 54.9127 32.0749 54.5366 31.5357 53.9452C29.9293 52.1892 30.6384 49.4295 30.362 47.064C30.1377 45.1608 29.1747 43.3527 27.7224 42.1042C26.1295 40.7357 24.0631 40.0786 22.2347 39.0477C20.4062 38.0168 19.3299 36.5689 19.3027 34.4686C19.2755 32.3478 20.0391 29.7807 22.0217 29.0307C24.0042 28.2807 28.1212 28.0632 30.1785 28.5775C32.8974 29.2573 36.5589 29.4748 38.1087 27.4446C38.759 26.5927 38.9221 25.1834 37.8821 24.2725C36.0695 22.6865 33.5772 24.7257 31.7645 24.046C30.4232 23.543 30.0901 21.2976 27.9127 21.327C25.7353 21.3565 24.3781 21.463 22.928 22.9131C21.5685 24.2725 18.9244 24.2454 18.1698 22.2333C17.4901 20.4207 18.8405 19.5053 19.7559 18.8347C20.6713 18.164 21.2959 17.2772 21.5655 16.1738C21.7128 15.5688 21.3419 12.9436 23.1546 12.0373C25.9709 10.6303 26.1726 14.9828 28.5924 14.0765C30.4051 13.3968 29.8975 11.566 30.4051 10.6779C31.3114 9.09182 32.4307 8.89923 33.5772 8.41208C36.0106 7.37662 37.6329 6.41593 38.793 3.96436C50.353 7.08887 59.0105 17.2758 59.8489 29.613C59.8489 29.613 56.4615 26.5383 55.5552 26.0852L55.553 26.0874Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.26758 19.7363C5.31516 19.7318 5.36501 19.7295 5.41259 19.7227C7.7758 19.4191 9.74929 19.2061 11.6004 17.7039C13.4788 16.179 14.5505 14.124 15.2257 12.266C15.6947 10.9745 14.9447 9.81898 14.2083 8.6521C10.3701 11.5206 7.27733 15.3294 5.26984 19.7363H5.26758Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.5576 43.9871C9.26531 42.5777 8.93904 41.4788 8.42471 40.1352C7.83334 38.5945 8.0214 36.6618 7.98741 35.0123C7.97155 34.2442 7.74498 32.4316 7.06524 31.5253C5.89157 29.9596 4.79946 29.0329 2.91433 28.1062C2.77612 29.2459 2.70361 30.4037 2.70361 31.5819C2.70361 38.4291 5.11214 44.7144 9.12484 49.6402C9.12484 49.6402 9.24266 49.4838 9.4715 49.1712C10.584 47.5874 9.95185 45.8813 9.5576 43.9871Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
    case ImpactCategory.LAND_USE:
      return (
        <g clipPath="url(#clip0_148_2255)">
          <path
            d="M36.1401 60.79C52.5927 60.79 65.9301 47.4525 65.9301 31C65.9301 14.5474 52.5927 1.20996 36.1401 1.20996C19.6875 1.20996 6.3501 14.5474 6.3501 31C6.3501 47.4525 19.6875 60.79 36.1401 60.79Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M30.6601 1.72998C16.8301 4.29998 6.3501 16.42 6.3501 31C6.3501 47.45 19.6901 60.79 36.1401 60.79C40.4901 60.79 44.6301 59.85 48.3601 58.17L30.6601 1.72998Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M11.7199 48.0501H60.5599C63.6799 43.5901 65.6199 38.2301 65.8899 32.4401C64.4999 32.6501 63.2099 33.0901 62.0499 33.7301C61.5799 33.9901 60.9899 33.9901 60.5199 33.7301C58.8999 32.8301 56.9999 32.3101 54.9699 32.3101C52.9399 32.3101 51.0399 32.8301 49.4199 33.7301C48.9499 33.9901 48.3599 33.9901 47.8899 33.7301C46.2699 32.8301 44.3699 32.3101 42.3399 32.3101C40.3099 32.3101 38.4099 32.8301 36.7899 33.7301C36.3199 33.9901 35.7299 33.9901 35.2599 33.7301C33.6399 32.8301 31.7399 32.3101 29.7099 32.3101C27.6799 32.3101 25.7799 32.8301 24.1599 33.7301C23.6899 33.9901 23.0999 33.9901 22.6299 33.7301C21.0099 32.8301 19.1099 32.3101 17.0799 32.3101C15.0499 32.3101 13.1499 32.8301 11.5299 33.7301C11.0599 33.9901 10.4699 33.9901 9.9999 33.7301C8.9099 33.1301 7.6999 32.7001 6.3999 32.4801C6.6799 38.2601 8.6099 43.6001 11.7299 48.0601L11.7199 48.0501Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M13.2203 47.9701C12.3103 47.9701 11.5703 47.2301 11.5703 46.3201V38.2501C11.5703 37.8501 11.2503 37.5301 10.8503 37.5301H3.01029C2.17029 37.5301 1.43029 37.0301 1.13029 36.2501C0.820286 35.4701 1.02029 34.6001 1.64029 34.0301L7.71029 28.4201L5.33029 28.3901C4.49029 28.3901 3.75029 27.8901 3.45029 27.1101C3.14029 26.3301 3.34029 25.4601 3.96029 24.8901L9.40029 19.8601L7.86029 19.8301C7.02029 19.8301 6.28029 19.3301 5.98029 18.5501C5.68029 17.7701 5.87029 16.9001 6.49029 16.3301L13.9903 9.40011C14.3603 9.05011 14.8503 8.86011 15.3603 8.86011C15.8703 8.86011 16.3603 9.05011 16.7303 9.40011L24.2303 16.3301C24.8403 16.9001 25.0403 17.7701 24.7403 18.5501C24.4403 19.3301 23.7003 19.8301 22.8603 19.8301H21.3403L26.7603 24.8901C27.3703 25.4601 27.5803 26.3301 27.2703 27.1101C26.9603 27.8901 26.2303 28.3901 25.3903 28.3901H23.0203L29.0703 34.0401C29.6803 34.6101 29.8803 35.4801 29.5803 36.2601C29.2703 37.0401 28.5403 37.5401 27.7003 37.5401H19.8503C19.4503 37.5401 19.1303 37.8601 19.1303 38.2601V46.3301C19.1303 47.2401 18.3903 47.9801 17.4803 47.9801H13.2303L13.2203 47.9701Z"
            fill={mint(greyscale)}
          />
          <path
            d="M15.3502 9.8501C15.6002 9.8501 15.8502 9.9401 16.0402 10.1201L23.5402 17.0501C24.2202 17.6801 23.7802 18.8201 22.8502 18.8201H21.3302C20.4002 18.8201 19.9602 19.9601 20.6402 20.5901L26.0802 25.6201C26.7602 26.2501 26.3202 27.3901 25.3902 27.3901H23.0202C22.0902 27.3901 21.6502 28.5301 22.3302 29.1601L28.4002 34.7701C29.0802 35.4001 28.6402 36.5401 27.7102 36.5401H19.8602C18.9102 36.5401 18.1402 37.3101 18.1402 38.2601V46.3301C18.1402 46.6901 17.8502 46.9801 17.4902 46.9801H13.2402C12.8802 46.9801 12.5902 46.6901 12.5902 46.3301V38.2601C12.5902 37.3101 11.8202 36.5401 10.8702 36.5401H3.01017C2.08017 36.5401 1.64017 35.4001 2.32017 34.7701L8.39017 29.1601C9.07017 28.5301 8.63017 27.3901 7.70017 27.3901H5.33017C4.40017 27.3901 3.96017 26.2501 4.64017 25.6201L10.0802 20.5901C10.7602 19.9601 10.3202 18.8201 9.39017 18.8201H7.87017C6.94017 18.8201 6.50017 17.6801 7.18017 17.0501L14.6802 10.1201C14.8802 9.9401 15.1202 9.8501 15.3702 9.8501M15.3502 7.8501C14.5902 7.8501 13.8602 8.1301 13.3002 8.6501L5.80017 15.5901C4.88017 16.4401 4.58017 17.7401 5.04017 18.9101C5.39017 19.8001 6.12017 20.4501 7.00017 20.7101L3.27017 24.1601C2.35017 25.0101 2.05017 26.3101 2.51017 27.4801C2.95017 28.6001 3.99017 29.3401 5.19017 29.4001L0.960173 33.3101C0.0401727 34.1601 -0.259827 35.4601 0.200173 36.6301C0.660173 37.8001 1.76017 38.5501 3.01017 38.5501H10.5802V46.3401C10.5802 47.8001 11.7702 48.9901 13.2302 48.9901H17.4802C18.9402 48.9901 20.1302 47.8001 20.1302 46.3401V38.5501H27.7002C28.9502 38.5501 30.0602 37.8001 30.5102 36.6301C30.9702 35.4601 30.6702 34.1601 29.7502 33.3101L25.5202 29.4001C26.7202 29.3501 27.7602 28.6101 28.2002 27.4801C28.6602 26.3101 28.3602 25.0101 27.4402 24.1601L23.7102 20.7101C24.5902 20.4501 25.3202 19.8001 25.6702 18.9101C26.1302 17.7401 25.8302 16.4401 24.9102 15.5901L17.4102 8.6601C16.8502 8.1401 16.1202 7.8601 15.3602 7.8601L15.3502 7.8501Z"
            fill="black"
          />
        </g>
      );
    case ImpactCategory.WATER_USE:
      return <></>;
  }
}

function impactCategoryTitleFontSize(
  impactCategory: ImpactCategory,
  locale: string | null
): number {
  if (locale === "de-DE" && impactCategory === ImpactCategory.WATER_USE) {
    return 9;
  }
  return 10;
}
