import { ImpactCategory } from "../../../../domain/impactCategories";
import {
  ImpactRating,
  useImpactRatingToColor,
} from "../../../../domain/impactRatings";
import { graphikSemiboldWebCssString } from "../../../../fonts/graphik-web/graphikSemiboldWebCssString";
import {
  darkTurquoise,
  foodstepsTurquoise,
  mint,
} from "../../../graphs/colors";
import { impactRatingLetterColor } from "../../impactRatingLetterColor";
import { impactRatingToLetter } from "./translations";

interface LetterRatingProps {
  greyscale?: boolean;
  impactCategory: ImpactCategory;
  impactRating: ImpactRating;
  width: number;
}

export default function LetterRating(props: LetterRatingProps) {
  const { greyscale = false, impactCategory, impactRating, width } = props;

  const impactRatingToColor = useImpactRatingToColor(greyscale);

  return (
    <svg
      width={width}
      viewBox="0 0 50 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>{graphikSemiboldWebCssString}</style>
      <ImpactCategoryIllustration
        greyscale={greyscale}
        impactCategory={impactCategory}
      />
      <rect
        x="25"
        y="1"
        width="24"
        height="30"
        rx="5"
        fill={impactRatingToColor(impactRating)}
        stroke="black"
        strokeWidth="2"
      />
      <text
        x={37}
        y={23}
        textAnchor="middle"
        fontFamily="Graphik Web"
        fontWeight={600}
        fontSize={20}
        fill={impactRatingLetterColor(impactRating, greyscale)}
      >
        {impactRatingToLetter(impactRating)}
      </text>
      <defs>
        <clipPath id="clip0_534_51">
          <rect width="36" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

interface ImpactCategoryIllustrationProps {
  greyscale: boolean;
  impactCategory: ImpactCategory;
}

function ImpactCategoryIllustration(props: ImpactCategoryIllustrationProps) {
  const { greyscale, impactCategory } = props;

  switch (impactCategory) {
    case ImpactCategory.GHG:
      return (
        <g clip-path="url(#clip0_534_77)">
          <path
            d="M16.1594 31.0644C24.3134 31.0644 30.9236 24.4543 30.9236 16.3003C30.9236 8.14625 24.3134 1.53613 16.1594 1.53613C8.00545 1.53613 1.39532 8.14625 1.39532 16.3003C1.39532 24.4543 8.00545 31.0644 16.1594 31.0644Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            stroke-width="2"
            stroke-miterlimit="10"
          />
          <path
            d="M28.6725 13.4645C28.2048 13.2306 27.737 13.2306 27.0353 13.9322C26.7371 14.2304 26.8014 14.517 26.8014 15.1017C26.8014 15.2841 26.6163 16.1281 26.4 16.2711C25.6 16.8 24.2322 16.5377 23.644 16.2711C23.1107 16.029 22.2968 16.1132 22.0068 16.6219C21.539 17.4405 21.8898 18.2591 22.7084 18.61C23.1891 18.8158 24.2913 18.8 24.4 20C24.485 20.9385 22.9423 21.0658 22.8254 22.3522C22.7084 23.6385 21.7857 24.2092 21.3694 25.2103C20.9531 26.2113 20.7941 26.7656 20.2947 27.417C19.6223 28.2929 18.4107 28.7139 17.3395 28.4438C16.9396 28.342 16.5549 28.1479 16.2765 27.8427C15.4474 26.9364 15.8134 25.512 15.6708 24.2911C15.555 23.3088 15.058 22.3756 14.3084 21.7312C13.4863 21.0249 12.4197 20.6857 11.476 20.1536C10.5323 19.6215 9.97678 18.8743 9.96275 17.7902C9.94871 16.6956 10.3428 15.3706 11.3661 14.9836C12.3893 14.5965 14.5142 14.4842 15.576 14.7497C16.9794 15.1005 18.8692 15.2128 19.6691 14.165C20.0047 13.7252 20.0889 12.9979 19.5521 12.5277C18.6166 11.7091 17.3302 12.7616 16.3946 12.4108C15.7023 12.1512 15.5304 10.9923 14.4066 11.0075C13.2828 11.0227 12.5823 11.0776 11.8338 11.8261C11.1322 12.5277 9.76745 12.5137 9.37803 11.4753C9.0272 10.5397 9.72418 10.0673 10.1966 9.7211C10.6691 9.37495 10.9915 8.91724 11.1306 8.34773C11.2066 8.03549 11.0152 6.68057 11.9508 6.21279C13.4044 5.48657 13.5085 7.73306 14.7574 7.26529C15.693 6.91445 15.431 5.96955 15.693 5.51113C16.1608 4.69253 16.7385 4.59313 17.3302 4.3417C18.5862 3.80727 19.4235 3.31143 20.0222 2.0461C25.9887 3.65875 30.4571 8.91653 30.8898 15.2841C30.8898 15.2841 29.1415 13.6972 28.6737 13.4633L28.6725 13.4645Z"
            fill={mint(greyscale)}
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.71875 10.1865C2.74331 10.1842 2.76904 10.183 2.79359 10.1795C4.01331 10.0228 5.03189 9.91285 5.98732 9.13752C6.95678 8.35049 7.50993 7.28981 7.85842 6.33087C8.10049 5.66429 7.71341 5.06788 7.33334 4.46562C5.35232 5.94613 3.75604 7.91195 2.71992 10.1865H2.71875Z"
            fill={mint(greyscale)}
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.93287 22.703C4.78201 21.9756 4.61361 21.4084 4.34815 20.715C4.04293 19.9198 4.13999 18.9222 4.12245 18.0709C4.11426 17.6744 3.99732 16.7389 3.64649 16.2711C3.04072 15.463 2.47705 14.9847 1.50408 14.5064C1.43275 15.0947 1.39532 15.6922 1.39532 16.3004C1.39532 19.8344 2.63843 23.0784 4.7095 25.6208C4.7095 25.6208 4.77031 25.5401 4.88843 25.3787C5.46262 24.5612 5.13635 23.6807 4.93287 22.703Z"
            fill={mint(greyscale)}
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      );
    case ImpactCategory.LAND_USE:
      return (
        <g clipPath="url(#clip0_534_51)">
          <path
            d="M19.5501 30.98C27.8233 30.98 34.5301 24.2732 34.5301 16C34.5301 7.72679 27.8233 1.02002 19.5501 1.02002C11.2768 1.02002 4.57007 7.72679 4.57007 16C4.57007 24.2732 11.2768 30.98 19.5501 30.98Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.8001 1.28003C9.85008 2.57003 4.58008 8.67003 4.58008 16C4.58008 24.27 11.2901 30.98 19.5601 30.98C21.7501 30.98 23.8301 30.51 25.7001 29.66L16.8001 1.28003Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M7.2701 24.5699H31.8301C33.4001 22.3299 34.3701 19.6299 34.5101 16.7199C33.8101 16.8199 33.1601 17.0499 32.5801 17.3699C32.3401 17.4999 32.0501 17.4999 31.8101 17.3699C30.9901 16.9199 30.0401 16.6599 29.0201 16.6599C28.0001 16.6599 27.0501 16.9199 26.2301 17.3699C25.9901 17.4999 25.7001 17.4999 25.4601 17.3699C24.6401 16.9199 23.6901 16.6599 22.6701 16.6599C21.6501 16.6599 20.7001 16.9199 19.8801 17.3699C19.6401 17.4999 19.3501 17.4999 19.1101 17.3699C18.2901 16.9199 17.3401 16.6599 16.3201 16.6599C15.3001 16.6599 14.3501 16.9199 13.5301 17.3699C13.2901 17.4999 13.0001 17.4999 12.7601 17.3699C11.9401 16.9199 10.9901 16.6599 9.9701 16.6599C8.9501 16.6599 8.0001 16.9199 7.1801 17.3699C6.9401 17.4999 6.6501 17.4999 6.4101 17.3699C5.8601 17.0699 5.2501 16.8499 4.6001 16.7399C4.7401 19.6499 5.7101 22.3299 7.2801 24.5699H7.2701Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M8.02997 24.3299C7.29997 24.3299 6.69997 23.7299 6.69997 22.9999V19.0799H2.88997C2.25997 19.0799 1.70997 18.6999 1.47997 18.1199C1.24997 17.5399 1.39997 16.8799 1.85997 16.4599L3.99997 14.4799C3.38997 14.4599 2.86997 14.0899 2.63997 13.5199C2.40997 12.9399 2.55997 12.2799 3.01997 11.8599L4.89997 10.1199C4.44997 9.98992 4.07997 9.66992 3.90997 9.21992C3.67997 8.63992 3.82997 7.97992 4.28997 7.55992L8.05997 4.06992C8.33997 3.80992 8.70997 3.66992 9.08997 3.66992C9.46997 3.66992 9.83997 3.80992 10.12 4.06992L13.89 7.55992C14.35 7.98992 14.5 8.63992 14.27 9.21992C14.09 9.66992 13.72 9.99992 13.28 10.1199L15.16 11.8599C15.62 12.2899 15.77 12.9399 15.54 13.5199C15.32 14.0899 14.79 14.4599 14.18 14.4799L16.32 16.4599C16.78 16.8899 16.93 17.5399 16.7 18.1199C16.47 18.6999 15.92 19.0799 15.29 19.0799H11.48V22.9999C11.48 23.7299 10.88 24.3299 10.15 24.3299H8.00997H8.02997Z"
            fill={mint(greyscale)}
          />
          <path
            d="M9.09988 4.65991C9.21988 4.65991 9.34988 4.70991 9.44988 4.79991L13.2199 8.28991C13.5599 8.60991 13.3399 9.17991 12.8699 9.17991H12.0999C11.6299 9.17991 11.4099 9.74991 11.7499 10.0699L14.4799 12.5999C14.8199 12.9199 14.5999 13.4899 14.1299 13.4899H12.9399C12.4699 13.4899 12.2499 14.0599 12.5899 14.3799L15.6399 17.1999C15.9799 17.5199 15.7599 18.0899 15.2899 18.0899H11.3499C10.8699 18.0899 10.4799 18.4799 10.4799 18.9599V23.0199C10.4799 23.1999 10.3299 23.3499 10.1499 23.3499H8.00988C7.82988 23.3499 7.67988 23.1999 7.67988 23.0199V18.9599C7.67988 18.4799 7.28988 18.0899 6.80988 18.0899H2.86988C2.39988 18.0899 2.17988 17.5199 2.51988 17.1999L5.56988 14.3799C5.90988 14.0599 5.68988 13.4899 5.21988 13.4899H4.02988C3.55988 13.4899 3.33988 12.9199 3.67988 12.5999L6.40988 10.0699C6.74988 9.74991 6.52988 9.17991 6.05988 9.17991H5.28988C4.81988 9.17991 4.59988 8.60991 4.93988 8.28991L8.70988 4.79991C8.80988 4.70991 8.92988 4.65991 9.05988 4.65991M9.09988 2.65991C8.46988 2.65991 7.85988 2.89991 7.38988 3.32991L3.61988 6.81991C2.85988 7.52991 2.60988 8.60991 2.98988 9.57991C3.07988 9.80991 3.19988 10.0199 3.34988 10.2099L2.35988 11.1299C1.59988 11.8399 1.34988 12.9199 1.72988 13.8899C1.85988 14.2099 2.03988 14.4999 2.26988 14.7399L1.19988 15.7299C0.439878 16.4399 0.189878 17.5199 0.569878 18.4899C0.949878 19.4599 1.86988 20.0899 2.90988 20.0899H5.71988V23.0099C5.71988 24.2899 6.75988 25.3399 8.04988 25.3399H10.1899C11.4699 25.3399 12.5199 24.2999 12.5199 23.0099V20.0899H15.3299C16.3699 20.0899 17.2899 19.4599 17.6699 18.4899C18.0499 17.5199 17.7999 16.4399 17.0399 15.7299L15.9699 14.7399C16.1999 14.4999 16.3899 14.2199 16.5099 13.8899C16.8899 12.9199 16.6399 11.8399 15.8799 11.1299L14.8899 10.2099C15.0399 10.0199 15.1599 9.80991 15.2499 9.57991C15.6299 8.60991 15.3799 7.52991 14.6099 6.81991L10.8399 3.33991C10.3799 2.90991 9.76988 2.66991 9.12988 2.66991L9.09988 2.65991Z"
            fill="black"
          />
        </g>
      );
    case ImpactCategory.WATER_USE:
      return <></>;
  }
}
