import { FormattedMessage } from "react-intl";

import {
  ImpactCategory,
  useImpactCategoryLabel,
} from "../../domain/impactCategories";
import assertNever from "../../util/assertNever";
import ImpactRatingProportionsChart, {
  Proportions,
} from "../scope-3/ImpactRatingProportionsChart";
import "./ProductsByImpactRating.css";

interface ProductsByGHGRatingProps {
  impactCategory: ImpactCategory;
  impactRatingProportions: {
    ghg: Proportions;
    landUse: Proportions;
  };
}

export function ProductsByImpactRating(props: ProductsByGHGRatingProps) {
  const { impactCategory, impactRatingProportions } = props;
  const selectedImpactCategory = useImpactCategoryLabel(impactCategory);

  const proportions = getProportions(impactRatingProportions, impactCategory);

  return (
    <div className="ProductsByImpactRating_DashboardCard">
      <h4 className="m-0">
        <FormattedMessage
          id="components/dashboard/ProductsByGHGRating:title"
          defaultMessage="Products by {impactCategory} Rating"
          values={{
            impactCategory: selectedImpactCategory,
          }}
        />
      </h4>
      {proportions !== null ? (
        <ImpactRatingProportionsChart proportions={proportions} />
      ) : (
        <FormattedMessage
          id="components/dashboard/ProductsByGHGRating:noData"
          defaultMessage="No data available"
        />
      )}
    </div>
  );
}

export function getProportions(
  impactRatingProportions: { ghg: Proportions; landUse: Proportions },
  impactCategory: ImpactCategory
) {
  if (impactCategory === ImpactCategory.GHG) {
    return impactRatingProportions.ghg;
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    return impactRatingProportions.landUse;
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    return null;
  } else {
    assertNever(impactCategory, "Invalid impact category");
  }
}
