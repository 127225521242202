import {
  allImpactRatings,
  ImpactRating,
  impactRatingToColorRgb,
} from "../../domain/impactRatings";
import assertNever from "../../util/assertNever";
import { impactRatingLetterColor } from "../utils/impactRatingLetterColor";
import "./ImpactRatingProportionsChart.css";

export interface Proportions {
  veryLow: number | null;
  low: number | null;
  medium: number | null;
  high: number | null;
  veryHigh: number | null;
}

interface ImpactRatingProportionsChartProps {
  proportions: Proportions;
}

export default function ImpactRatingProportionsChart(
  props: ImpactRatingProportionsChartProps
) {
  const { proportions } = props;

  return (
    <div className="ImpactRatingProportionsChart">
      <Legend />
      <RatingSections proportions={proportions} />
    </div>
  );
}

function Legend() {
  return (
    <div className="ImpactRatingProportionsChart_Legend">
      {[0, 25, 50, 75, 100].map((value, index) => (
        <div
          key={index}
          className="ImpactRatingProportionsChart_LabelledDivider"
          style={{ left: `${value}%` }}
        >
          <div className="ImpactRatingProportionsChart_Divider" />
          <div className="small-copy">{value}%</div>
        </div>
      ))}
    </div>
  );
}

function RatingSections(props: ImpactRatingProportionsChartProps) {
  const { proportions } = props;

  return (
    <div className="ImpactRatingProportionsChart_RatingSections">
      {allImpactRatings.map((impactRating, index) => {
        const proportion = getProportion(proportions, impactRating);
        return proportion !== null && proportion !== 0 ? (
          <RatingSection
            key={index}
            impactRating={impactRating}
            proportion={proportion}
          />
        ) : null;
      })}
    </div>
  );
}

export function getProportion(
  proportions: Proportions,
  impactRating: ImpactRating
) {
  if (impactRating === "VLOW") {
    return proportions.veryLow;
  } else if (impactRating === "LOW") {
    return proportions.low;
  } else if (impactRating === "MEDIUM") {
    return proportions.medium;
  } else if (impactRating === "HIGH") {
    return proportions.high;
  } else if (impactRating === "VHIGH") {
    return proportions.veryHigh;
  } else {
    assertNever(impactRating, "Unsupported impact rating");
  }
}

interface RatingSectionProps {
  impactRating: ImpactRating;
  proportion: number;
}

function RatingSection(props: RatingSectionProps) {
  const { impactRating, proportion } = props;

  const backgroundColor = impactRatingToColorRgb(impactRating);
  const color = impactRatingLetterColor(impactRating);
  const percentage = proportion * 100;
  const text = `${percentage.toFixed(1)}%`;
  const textDisplayThresholdPercentage = 7;

  return (
    <div
      className="ImpactRatingProportionsChart_RatingSection small-copy-medium"
      style={{
        backgroundColor,
        color,
        width: `${percentage}%`,
      }}
    >
      {percentage > textDisplayThresholdPercentage ? text : ""}
    </div>
  );
}
