import React from "react";

import Card from "../utils/Card";

interface DashboardCardProps {
  children: React.ReactNode;
  className?: string;
}

export function DashboardCard(props: DashboardCardProps) {
  const { children, className } = props;
  return <Card className={className}>{children}</Card>;
}
