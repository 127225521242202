import React from "react";

import { ImpactCategory } from "../../domain/impactCategories";
import { usePages } from "../pages";
import { BaseScope3Page } from "./Scope3Page";

export function LandUsePage() {
  const pages = usePages();

  return (
    <BaseScope3Page
      breadcrumb={pages.LandUse.breadcrumb()}
      impactCategory={ImpactCategory.LAND_USE}
      title={pages.LandUse.title}
    />
  );
}
