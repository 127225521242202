import { useCallbackOne } from "use-memo-one";

import { useOrganizationId } from "../components/organizations/OrganizationProvider";
import usePromise from "../components/utils/usePromise";
import { useDataStore } from "./index";
export default function useProcuredItems(
  assessmentId: string,
  pageSize?: number,
  sortColumn?: string,
  sortDirection?: "asc" | "desc"
) {
  const dataStore = useDataStore();
  const [organizationId] = useOrganizationId();

  const load = useCallbackOne(async () => {
    return await dataStore.fetchReportItems(
      assessmentId,
      organizationId,
      pageSize,
      sortColumn,
      sortDirection
    );
  }, [
    dataStore,
    assessmentId,
    organizationId,
    pageSize,
    sortColumn,
    sortDirection,
  ]);

  return usePromise(load);
}
