import { ImpactRating } from "../../../../__generated__/globalTypes";
import { FunctionalUnit } from "../../../../domain/functionalUnits";
import { ImpactCategory } from "../../../../domain/impactCategories";
import { useImpactRatingToColor } from "../../../../domain/impactRatings";
import { graphikSemiboldWebCssString } from "../../../../fonts/graphik-web/graphikSemiboldWebCssString";
import assertNever from "../../../../util/assertNever";
import {
  brightTurquoise,
  darkTurquoise,
  foodstepsTurquoise,
  mint,
} from "../../../graphs/colors";
import { impactRatingLetterColor } from "../../impactRatingLetterColor";
import {
  impactCategoryTitle,
  impactRatingToLetter,
  impactRatingToName,
  serving,
  impactCategoryUnitPerKg,
  impactCategoryUnit,
} from "./translations";

interface DescriptiveImpactAndScaleProps {
  functionalUnit: FunctionalUnit;
  impactCategory: ImpactCategory;
  impactMagnitude: number;
  impactRating: ImpactRating;
  greyscale?: boolean;
  locale: string | null;
  width: number;
}

export default function DescriptiveImpactAndScale(
  props: DescriptiveImpactAndScaleProps
) {
  const {
    functionalUnit,
    impactCategory,
    impactMagnitude,
    impactRating,
    greyscale = false,
    locale,
    width,
  } = props;

  return (
    <svg
      width={width}
      viewBox={`0 0 98 ${adjustHeight(208, functionalUnit)}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>{graphikSemiboldWebCssString}</style>
      <rect
        x="1"
        y="27"
        width="96"
        height={adjustHeight(180, functionalUnit)}
        rx="9"
        fill="white"
        stroke="black"
        strokeWidth="2"
      />
      <text
        x={49}
        y={64}
        textAnchor="middle"
        fontFamily="Graphik Web"
        fontWeight={600}
        fontSize={impactRatingFontSize(impactRating, locale)}
        fill="black"
      >
        {impactRatingToName(impactRating, locale)}
      </text>
      <text
        x={49}
        y={82}
        textAnchor="middle"
        fontFamily="Graphik Web"
        fontWeight={500}
        fontSize={impactCategoryTitleFontSize(impactCategory, locale)}
        fill="black"
      >
        {impactCategoryTitle(impactCategory, locale)}
      </text>

      <rect
        width="94"
        height={adjustHeight(50, functionalUnit)}
        transform="translate(2 92)"
        fill="white"
      />
      <text
        x={49}
        y={115}
        textAnchor="middle"
        fontFamily="Graphik Web"
        fontWeight={600}
        fontSize={26}
        fill="black"
      >
        {impactMagnitude.toFixed(2)}
      </text>

      <Unit
        functionalUnit={functionalUnit}
        impactCategory={impactCategory}
        locale={locale}
      />

      <rect
        width="94"
        height="40"
        transform={`translate(2 ${adjustHeight(142, functionalUnit)})`}
        fill="white"
      />
      <ImpactRatingScale
        functionalUnit={functionalUnit}
        greyscale={greyscale}
        impactRating={impactRating}
      />
      <path
        d="M2 182H96V198C96 202.418 92.4183 206 88 206H10C5.58172 206 2 202.418 2 198V182Z"
        fill={foodstepsTurquoise(greyscale)}
        transform={transform(functionalUnit)}
      />
      <path
        d="M12.9453 197.672H15.0639V194.05H18.1372V192.454H15.0639V189.983H18.948V188.322H12.9453V197.672Z"
        fill="white"
        transform={transform(functionalUnit)}
      />
      <path
        d="M23.0543 196.378C21.9819 196.378 21.3803 195.593 21.3803 194.285V194.18C21.3803 192.873 22.0081 192.114 23.0543 192.114C24.1136 192.114 24.7282 192.899 24.7282 194.207V194.298C24.7282 195.593 24.1136 196.378 23.0543 196.378ZM23.0412 197.816C25.0944 197.816 26.6506 196.443 26.6506 194.285V194.18C26.6506 192.049 25.1075 190.676 23.0543 190.676C21.0011 190.676 19.4448 192.075 19.4448 194.22V194.324C19.4448 196.456 21.0011 197.816 23.0412 197.816Z"
        fill="white"
        transform={transform(functionalUnit)}
      />
      <path
        d="M31.1129 196.378C30.0405 196.378 29.4389 195.593 29.4389 194.285V194.18C29.4389 192.873 30.0667 192.114 31.1129 192.114C32.1722 192.114 32.7868 192.899 32.7868 194.207V194.298C32.7868 195.593 32.1722 196.378 31.1129 196.378ZM31.0998 197.816C33.153 197.816 34.7092 196.443 34.7092 194.285V194.18C34.7092 192.049 33.1661 190.676 31.1129 190.676C29.0597 190.676 27.5034 192.075 27.5034 194.22V194.324C27.5034 196.456 29.0597 197.816 31.0998 197.816Z"
        fill="white"
        transform={transform(functionalUnit)}
      />
      <path
        d="M38.5438 197.816C39.5508 197.816 40.3877 197.228 40.7408 196.534V197.672H42.624V187.733H40.7408V191.853C40.3616 191.16 39.6815 190.676 38.583 190.676C36.8698 190.676 35.5751 191.996 35.5751 194.246V194.35C35.5751 196.626 36.8829 197.816 38.5438 197.816ZM39.1061 196.338C38.1645 196.338 37.5106 195.671 37.5106 194.311V194.207C37.5106 192.847 38.0991 192.127 39.1584 192.127C40.1916 192.127 40.7931 192.82 40.7931 194.18V194.285C40.7931 195.671 40.1 196.338 39.1061 196.338Z"
        fill="white"
        transform={transform(functionalUnit)}
      />
      <path
        d="M46.7921 197.816C48.5968 197.816 49.6692 197.044 49.6692 195.567C49.6692 194.102 48.7276 193.67 47.0144 193.422C46.0205 193.278 45.6936 193.108 45.6936 192.677C45.6936 192.258 46.0467 191.97 46.6613 191.97C47.3152 191.97 47.6291 192.232 47.7337 192.794H49.473C49.303 191.225 48.1653 190.676 46.6483 190.676C45.2489 190.676 43.9412 191.382 43.9412 192.807C43.9412 194.141 44.6474 194.664 46.4652 194.939C47.446 195.083 47.8514 195.279 47.8514 195.75C47.8514 196.221 47.5114 196.495 46.779 196.495C45.9551 196.495 45.6544 196.142 45.5759 195.527H43.8104C43.8758 197.005 44.9482 197.816 46.7921 197.816Z"
        fill="white"
        transform={transform(functionalUnit)}
      />
      <path
        d="M53.3696 197.803C53.8796 197.803 54.2589 197.711 54.5204 197.62V196.155C54.2981 196.247 54.0889 196.286 53.8011 196.286C53.3173 196.286 53.0426 196.024 53.0426 195.475V192.18H54.4812V190.833H53.0426V189.355H51.1595V190.833H50.2832V192.18H51.1595V195.645C51.1595 197.071 51.931 197.803 53.3696 197.803Z"
        fill="white"
        transform={transform(functionalUnit)}
      />
      <path
        d="M58.9531 197.816C60.8232 197.816 62.0002 196.992 62.2226 195.514H60.4309C60.3132 196.09 59.8817 196.469 59.0055 196.469C57.9723 196.469 57.3577 195.815 57.3054 194.677H62.2356V194.154C62.2356 191.709 60.6663 190.676 58.9008 190.676C56.913 190.676 55.3829 192.075 55.3829 194.233V194.337C55.3829 196.521 56.8869 197.816 58.9531 197.816ZM57.3315 193.513C57.4754 192.546 58.0508 191.983 58.9008 191.983C59.8032 191.983 60.3263 192.48 60.3917 193.513H57.3315Z"
        fill="white"
        transform={transform(functionalUnit)}
      />
      <path
        d="M63.4769 200H65.3732V196.626C65.7263 197.293 66.4978 197.816 67.5571 197.816C69.2572 197.816 70.5389 196.548 70.5389 194.285V194.18C70.5389 191.918 69.2311 190.676 67.5571 190.676C66.5502 190.676 65.7524 191.251 65.3732 191.905V190.833H63.4769V200ZM66.9948 196.338C65.9617 196.338 65.3078 195.671 65.3078 194.298V194.194C65.3078 192.82 65.9878 192.127 66.9817 192.127C67.9364 192.127 68.6164 192.807 68.6164 194.194V194.298C68.6164 195.632 68.041 196.338 66.9948 196.338Z"
        fill="white"
        transform={transform(functionalUnit)}
      />
      <path
        d="M74.2885 197.816C76.0932 197.816 77.1656 197.044 77.1656 195.567C77.1656 194.102 76.224 193.67 74.5108 193.422C73.5169 193.278 73.1899 193.108 73.1899 192.677C73.1899 192.258 73.543 191.97 74.1577 191.97C74.8116 191.97 75.1254 192.232 75.2301 192.794H76.9694C76.7994 191.225 75.6616 190.676 74.1446 190.676C72.7453 190.676 71.4375 191.382 71.4375 192.807C71.4375 194.141 72.1437 194.664 73.9615 194.939C74.9424 195.083 75.3478 195.279 75.3478 195.75C75.3478 196.221 75.0077 196.495 74.2754 196.495C73.4515 196.495 73.1507 196.142 73.0722 195.527H71.3068C71.3721 197.005 72.4445 197.816 74.2885 197.816Z"
        fill="white"
        transform={transform(functionalUnit)}
      />
      <path
        d="M81.6594 190.848L81.6591 190.611C81.6592 190.475 81.7241 190.381 81.8534 190.33C82.3715 190.125 82.6021 189.528 82.3508 189.044C82.3036 188.953 82.2427 188.871 82.1695 188.799C81.9584 188.59 81.7455 188.383 81.5343 188.174C81.5114 188.152 81.4974 188.149 81.4726 188.172C81.3439 188.291 81.2137 188.408 81.0837 188.526C80.9907 188.61 80.8958 188.692 80.804 188.777C80.6159 188.952 80.5118 189.165 80.5002 189.419C80.4876 189.697 80.5844 189.936 80.7845 190.133C80.8726 190.22 80.9768 190.285 81.0939 190.33C81.2219 190.379 81.2922 190.48 81.2923 190.613C81.2924 190.778 81.2925 190.943 81.2922 191.108C81.2922 191.13 81.2975 191.156 81.2679 191.165C81.2386 191.174 81.2279 191.15 81.2139 191.132C81.0416 190.915 80.8181 190.789 80.5353 190.767C80.4535 190.76 80.4537 190.762 80.4615 190.84L80.4618 190.843C80.4793 191.018 80.5388 191.177 80.6458 191.319C80.7651 191.476 80.9221 191.584 81.1118 191.646C81.2294 191.685 81.2919 191.769 81.2923 191.891C81.2926 191.979 81.2923 192.068 81.2923 192.157C81.2923 192.415 81.1188 192.571 80.8599 192.518C80.9766 192.625 81.061 192.693 81.1715 192.72C81.4565 192.79 81.6728 192.879 82.0895 192.523C81.874 192.564 81.6815 192.371 81.6803 192.157C81.6799 192.054 81.6803 191.985 81.6803 191.882C81.6803 191.767 81.7423 191.683 81.8578 191.646C82.0279 191.591 82.1505 191.498 82.2657 191.365C82.3966 191.213 82.472 191.038 82.4862 190.839C82.4918 190.762 82.4915 190.761 82.4129 190.767C82.2011 190.782 82.0174 190.861 81.8597 190.999C81.8102 191.043 81.767 191.092 81.7272 191.143C81.715 191.159 81.7025 191.171 81.6803 191.164C81.6593 191.157 81.6593 191.14 81.6594 191.123V190.848Z"
        fill="white"
        transform={transform(functionalUnit)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.4875 192.58C83.221 192.58 84.6262 191.201 84.6262 189.5C84.6262 187.799 83.221 186.42 81.4875 186.42C79.7541 186.42 78.3489 187.799 78.3489 189.5C78.3489 191.201 79.7541 192.58 81.4875 192.58ZM81.4875 193C83.4573 193 85.0542 191.433 85.0542 189.5C85.0542 187.567 83.4573 186 81.4875 186C79.5177 186 77.9209 187.567 77.9209 189.5C77.9209 191.433 79.5177 193 81.4875 193Z"
        fill="white"
        transform={transform(functionalUnit)}
      />

      <defs>
        <clipPath id="clip0_150_3303">
          <rect width="46" height="46" fill="white" transform="translate(26)" />
        </clipPath>
      </defs>

      <ImpactCategoryIllustration
        greyscale={greyscale}
        impactCategory={impactCategory}
      />
    </svg>
  );
}

interface ImpactRatingScaleProps {
  functionalUnit: FunctionalUnit;
  greyscale: boolean;
  impactRating: ImpactRating;
}

function ImpactRatingScale(props: ImpactRatingScaleProps) {
  const { functionalUnit, greyscale, impactRating } = props;

  const impactRatingToColor = useImpactRatingToColor(greyscale);

  const ratingLetter = impactRatingToLetter(impactRating);
  const ratingLetterColor = impactRatingLetterColor(impactRating, greyscale);

  const ratingLetterY = 165;
  const ratingLetterXA = 25;
  const ratingLetterFontSize = 20;
  const distanceBetweenRatingLetters = 12;

  switch (impactRating) {
    case ImpactRating.VLOW:
      return (
        <>
          <rect
            x="12"
            y="143"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor(ImpactRating.VLOW)}
            stroke="black"
            strokeWidth="2"
            transform={transform(functionalUnit)}
          />
          <text
            x={ratingLetterXA}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
            transform={transform(functionalUnit)}
          >
            {ratingLetter}
          </text>
          <path
            d="M42 148H51V168H42V148Z"
            fill={impactRatingToColor(ImpactRating.LOW)}
            transform={transform(functionalUnit)}
          />
          <rect
            x="54"
            y="148"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
            transform={transform(functionalUnit)}
          />
          <rect
            x="66"
            y="148"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.HIGH)}
            transform={transform(functionalUnit)}
          />
          <path
            d="M78 148H83C85.2091 148 87 149.791 87 152V164C87 166.209 85.2091 168 83 168H78V148Z"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
            transform={transform(functionalUnit)}
          />
        </>
      );
    case ImpactRating.LOW:
      return (
        <>
          <path
            d="M11 152C11 149.791 12.7909 148 15 148H20V168H15C12.7909 168 11 166.209 11 164V152Z"
            fill={impactRatingToColor(ImpactRating.VLOW)}
            transform={transform(functionalUnit)}
          />
          <rect
            x="24"
            y="143"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor(ImpactRating.LOW)}
            stroke="black"
            strokeWidth="2"
            transform={transform(functionalUnit)}
          />
          <text
            x={ratingLetterXA + distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
            transform={transform(functionalUnit)}
          >
            {ratingLetter}
          </text>
          <rect
            x="54"
            y="148"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
            transform={transform(functionalUnit)}
          />
          <rect
            x="66"
            y="148"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.HIGH)}
            transform={transform(functionalUnit)}
          />
          <path
            d="M78 148H83C85.2091 148 87 149.791 87 152V164C87 166.209 85.2091 168 83 168H78V148Z"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
            transform={transform(functionalUnit)}
          />
        </>
      );
    case ImpactRating.MEDIUM:
      return (
        <>
          <path
            d="M11 152C11 149.791 12.7909 148 15 148H20V168H15C12.7909 168 11 166.209 11 164V152Z"
            fill={impactRatingToColor(ImpactRating.VLOW)}
            transform={transform(functionalUnit)}
          />
          <rect
            x="23"
            y="148"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.LOW)}
            transform={transform(functionalUnit)}
          />
          <rect
            x="36"
            y="143"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
            stroke="black"
            strokeWidth="2"
            transform={transform(functionalUnit)}
          />
          <text
            x={ratingLetterXA + 2 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
            transform={transform(functionalUnit)}
          >
            {ratingLetter}
          </text>
          <rect
            x="66"
            y="148"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.HIGH)}
            transform={transform(functionalUnit)}
          />
          <path
            d="M78 148H83C85.2091 148 87 149.791 87 152V164C87 166.209 85.2091 168 83 168H78V148Z"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
            transform={transform(functionalUnit)}
          />
        </>
      );
    case ImpactRating.HIGH:
      return (
        <>
          <path
            d="M11 152C11 149.791 12.7909 148 15 148H20V168H15C12.7909 168 11 166.209 11 164V152Z"
            fill={impactRatingToColor(ImpactRating.VLOW)}
            transform={transform(functionalUnit)}
          />
          <rect
            x="23"
            y="148"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.LOW)}
            transform={transform(functionalUnit)}
          />
          <rect
            x="35"
            y="148"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
            transform={transform(functionalUnit)}
          />
          <rect
            x="48"
            y="143"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor(ImpactRating.HIGH)}
            stroke="black"
            strokeWidth="2"
            transform={transform(functionalUnit)}
          />
          <text
            x={ratingLetterXA + 3 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
            transform={transform(functionalUnit)}
          >
            {ratingLetter}
          </text>
          <path
            d="M78 148H83C85.2091 148 87 149.791 87 152V164C87 166.209 85.2091 168 83 168H78V148Z"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
            transform={transform(functionalUnit)}
          />
        </>
      );
    case ImpactRating.VHIGH:
      return (
        <>
          <path
            d="M11 152C11 149.791 12.7909 148 15 148H20V168H15C12.7909 168 11 166.209 11 164V152Z"
            fill={impactRatingToColor(ImpactRating.VLOW)}
            transform={transform(functionalUnit)}
          />
          <rect
            x="23"
            y="148"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.LOW)}
            transform={transform(functionalUnit)}
          />
          <rect
            x="35"
            y="148"
            width="9"
            height="20"
            fill={impactRatingToColor(ImpactRating.MEDIUM)}
            transform={transform(functionalUnit)}
          />
          <path
            d="M47 148H56V168H47V148Z"
            fill={impactRatingToColor(ImpactRating.HIGH)}
            transform={transform(functionalUnit)}
          />
          <rect
            x="60"
            y="143"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor(ImpactRating.VHIGH)}
            stroke="black"
            strokeWidth="2"
            transform={transform(functionalUnit)}
          />
          <text
            x={ratingLetterXA + 4 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
            transform={transform(functionalUnit)}
          >
            {ratingLetter}
          </text>
        </>
      );
  }
}

interface UnitProps {
  functionalUnit: FunctionalUnit;
  impactCategory: ImpactCategory;
  locale: string | null;
}

function Unit(props: UnitProps) {
  const { functionalUnit, impactCategory, locale } = props;

  if (functionalUnit === FunctionalUnit.KG) {
    return (
      <text
        x={49}
        y={131}
        textAnchor="middle"
        fontFamily="Graphik Web"
        fontWeight={500}
        fontSize={11}
        fill="black"
      >
        {impactCategoryUnitPerKg(impactCategory, locale)}
      </text>
    );
  } else if (functionalUnit === FunctionalUnit.PORTION) {
    return (
      <>
        <text
          x={49}
          y={131}
          textAnchor="middle"
          fontFamily="Graphik Web"
          fontWeight={500}
          fontSize={11}
          fill="black"
        >
          {impactCategoryUnit(impactCategory, locale)}
        </text>
        <text
          x={49}
          y={145}
          textAnchor="middle"
          fontFamily="Graphik Web"
          fontWeight={500}
          fontSize={11}
          fill="black"
        >
          /{serving(locale)}
        </text>
      </>
    );
  } else {
    assertNever(functionalUnit, "Unsupported functional unit");
  }
}

const adjustHeight = (height: number, functionalUnit: FunctionalUnit) => {
  // The unit for per portion runs over 2 lines, so we need to add 16px of height.
  return functionalUnit === FunctionalUnit.PORTION ? height + 16 : height;
};

const transform = (functionalUnit: FunctionalUnit) =>
  `translate(0 ${adjustHeight(0, functionalUnit)})`;

interface ImpactCategoryIllustrationProps {
  greyscale: boolean;
  impactCategory: ImpactCategory;
}

function ImpactCategoryIllustration(props: ImpactCategoryIllustrationProps) {
  const { greyscale, impactCategory } = props;

  switch (impactCategory) {
    case ImpactCategory.GHG:
      return (
        <>
          <path
            d="M44.1178 33.3156C53.3176 33.3156 60.7756 25.8577 60.7756 16.6578C60.7756 7.45795 53.3176 0 44.1178 0C34.9179 0 27.46 7.45795 27.46 16.6578C27.46 25.8577 34.9179 33.3156 44.1178 33.3156Z"
            fill={brightTurquoise(greyscale)}
          />
          <path
            d="M51.2999 44.7775C61.9557 44.7775 70.5939 36.1393 70.5939 25.4835C70.5939 14.8277 61.9557 6.18945 51.2999 6.18945C40.6441 6.18945 32.0059 14.8277 32.0059 25.4835C32.0059 36.1393 40.6441 44.7775 51.2999 44.7775Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M67.6523 21.7774C67.041 21.4718 66.4297 21.4718 65.5128 22.3887C65.1231 22.7784 65.2072 23.1529 65.2072 23.917C65.2072 24.1554 64.9653 25.2583 64.6825 25.4452C63.6371 26.1364 61.8496 25.7937 61.0809 25.4452C60.384 25.1289 59.3204 25.2389 58.9414 25.9037C58.3301 26.9735 58.7885 28.0432 59.8583 28.5017C60.4864 28.7707 61.9269 28.75 62.0689 30.3182C62.1799 31.5446 60.164 31.711 60.0111 33.3921C59.8583 35.0731 58.6525 35.8189 58.1085 37.1271C57.5644 38.4352 57.3566 39.1596 56.704 40.0109C55.8253 41.1555 54.242 41.7057 52.8422 41.3527C52.3195 41.2197 51.8167 40.966 51.453 40.5671C50.3695 39.3828 50.8478 37.5214 50.6614 35.9259C50.5101 34.6422 49.8606 33.4226 48.881 32.5806C47.8066 31.6575 46.4129 31.2143 45.1796 30.519C43.9463 29.8236 43.2204 28.8471 43.202 27.4304C43.1837 26 43.6987 24.2685 45.0359 23.7626C46.3731 23.2568 49.1499 23.1101 50.5376 23.457C52.3715 23.9154 54.8411 24.0622 55.8864 22.6929C56.325 22.1182 56.4351 21.1677 55.7336 20.5533C54.511 19.4836 52.8299 20.859 51.6074 20.4005C50.7026 20.0612 50.478 18.5467 49.0093 18.5666C47.5407 18.5865 46.6253 18.6583 45.6472 19.6364C44.7303 20.5533 42.9468 20.535 42.4379 19.1779C41.9794 17.9553 42.8903 17.3379 43.5077 16.8855C44.1251 16.4332 44.1143 15.9034 44.2962 15.1591C44.3955 14.7511 44.5775 12.9121 45.8 12.3008C47.6996 11.3518 47.8357 14.2875 49.4678 13.6762C50.6904 13.2178 50.3481 11.983 50.6904 11.3839C51.3017 10.3141 52.0567 10.1842 52.8299 9.85565C54.4713 9.15724 55.5655 8.50927 56.3479 6.85571C64.145 8.96315 69.9844 15.8341 70.5499 24.1554C70.5499 24.1554 68.2652 22.0816 67.6539 21.7759L67.6523 21.7774Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.7344 17.4937C33.7665 17.4907 33.8001 17.4891 33.8322 17.4846C35.4261 17.2798 36.7572 17.1361 38.0058 16.1229C39.2727 15.0944 39.9956 13.7083 40.451 12.4551C40.7673 11.584 40.2615 10.8046 39.7648 10.0176C37.176 11.9523 35.0899 14.5213 33.7359 17.4937H33.7344Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M36.6288 33.8506C36.4316 32.9 36.2116 32.1588 35.8647 31.2526C35.4658 30.2134 35.5926 28.9098 35.5697 27.7972C35.559 27.2792 35.4062 26.0566 34.9477 25.4453C34.1561 24.3893 33.4195 23.7642 32.148 23.1392C32.0548 23.9079 32.0059 24.6888 32.0059 25.4835C32.0059 30.1018 33.6304 34.3412 36.3369 37.6635C36.4164 37.5596 36.4958 37.4542 36.5707 37.3472C37.3211 36.279 36.8947 35.1282 36.6288 33.8506Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
    case ImpactCategory.LAND_USE:
      return (
        <g clipPath="url(#clip0_150_3303)">
          <path
            d="M41.75 31.5C50.4485 31.5 57.5 24.4485 57.5 15.75C57.5 7.05152 50.4485 0 41.75 0C33.0515 0 26 7.05152 26 15.75C26 24.4485 33.0515 31.5 41.75 31.5Z"
            fill={brightTurquoise(greyscale)}
          />
          fill={darkTurquoise(greyscale)}
          <path
            d="M51.1202 44.96C62.0057 44.96 70.8302 36.1356 70.8302 25.25C70.8302 14.3645 62.0057 5.54004 51.1202 5.54004C40.2346 5.54004 31.4102 14.3645 31.4102 25.25C31.4102 36.1356 40.2346 44.96 51.1202 44.96Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M47.5002 5.87988C38.3402 7.57988 31.4102 15.5999 31.4102 25.2499C31.4102 36.1399 40.2402 44.9599 51.1202 44.9599C54.0002 44.9599 56.7402 44.3399 59.2002 43.2199L47.5002 5.87988Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M34.9604 36.5301H67.2804C69.3504 33.5801 70.6304 30.0301 70.8104 26.2001C69.8904 26.3401 69.0304 26.6301 68.2704 27.0501C67.9604 27.2201 67.5704 27.2201 67.2604 27.0501C66.1904 26.4501 64.9304 26.1101 63.5904 26.1101C62.2504 26.1101 60.9904 26.4601 59.9204 27.0501C59.6104 27.2201 59.2204 27.2201 58.9004 27.0501C57.8304 26.4601 56.5704 26.1101 55.2304 26.1101C53.8904 26.1101 52.6304 26.4601 51.5604 27.0501C51.2504 27.2201 50.8604 27.2201 50.5404 27.0501C49.4704 26.4501 48.2104 26.1101 46.8704 26.1101C45.5304 26.1101 44.2704 26.4601 43.2004 27.0501C42.8904 27.2201 42.5004 27.2201 42.1804 27.0501C41.1104 26.4601 39.8504 26.1101 38.5104 26.1101C37.1704 26.1101 35.9104 26.4601 34.8404 27.0501C34.5304 27.2201 34.1404 27.2201 33.8204 27.0501C33.1004 26.6501 32.3004 26.3701 31.4404 26.2201C31.6304 30.0501 32.9004 33.5801 34.9704 36.5301H34.9604Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M35.5093 36.3C34.7193 36.3 34.0793 35.66 34.0793 34.87V29.53C34.0793 29.45 34.0193 29.39 33.9393 29.39H28.7493C28.0593 29.39 27.4393 28.97 27.1893 28.33C26.9393 27.69 27.0993 26.96 27.6093 26.49L31.0193 23.34H30.2793C29.5893 23.34 28.9693 22.92 28.7193 22.28C28.4693 21.64 28.6293 20.91 29.1393 20.44L32.1293 17.68H31.9493C31.2593 17.68 30.6393 17.26 30.3893 16.62C30.1393 15.97 30.2993 15.25 30.8093 14.78L35.7693 10.19C36.0793 9.89999 36.4793 9.73999 36.9093 9.73999C37.3393 9.73999 37.7393 9.89999 38.0493 10.19L43.0093 14.78C43.5193 15.25 43.6893 15.97 43.4293 16.62C43.1693 17.27 42.5593 17.68 41.8693 17.68H41.6893L44.6793 20.44C45.1893 20.91 45.3593 21.63 45.0993 22.28C44.8493 22.93 44.2293 23.34 43.5393 23.34H42.7993L46.2093 26.49C46.7193 26.96 46.8893 27.68 46.6293 28.33C46.3693 28.98 45.7593 29.39 45.0693 29.39H39.8793C39.7993 29.39 39.7393 29.45 39.7393 29.53V34.87C39.7393 35.66 39.0993 36.3 38.3093 36.3H35.4993H35.5093Z"
            fill={mint(greyscale)}
          />
          <path
            d="M36.9097 10.73C37.0697 10.73 37.2397 10.79 37.3697 10.91L42.3297 15.5C42.7797 15.92 42.4897 16.67 41.8697 16.67H40.8597C40.2497 16.67 39.9497 17.42 40.3997 17.84L43.9997 21.17C44.4497 21.59 44.1597 22.34 43.5397 22.34H41.9697C41.3597 22.34 41.0597 23.09 41.5097 23.51L45.5297 27.22C45.9797 27.64 45.6897 28.39 45.0697 28.39H39.8797C39.2497 28.39 38.7397 28.9 38.7397 29.53V34.87C38.7397 35.11 38.5497 35.3 38.3097 35.3H35.4997C35.2597 35.3 35.0697 35.11 35.0697 34.87V29.53C35.0697 28.9 34.5597 28.39 33.9297 28.39H28.7497C28.1397 28.39 27.8397 27.64 28.2897 27.22L32.3097 23.51C32.7597 23.09 32.4697 22.34 31.8497 22.34H30.2797C29.6697 22.34 29.3697 21.59 29.8197 21.17L33.4197 17.84C33.8697 17.42 33.5797 16.67 32.9597 16.67H31.9497C31.3397 16.67 31.0397 15.92 31.4897 15.5L36.4497 10.91C36.5797 10.79 36.7397 10.73 36.9097 10.73ZM36.9097 8.72998C36.2297 8.72998 35.5897 8.97998 35.0897 9.43998L30.1297 14.03C29.3197 14.78 29.0497 15.94 29.4597 16.97C29.6297 17.4 29.8997 17.77 30.2397 18.06L28.4597 19.7C27.6497 20.45 27.3797 21.61 27.7897 22.64C28.0097 23.2 28.3997 23.65 28.8797 23.95L26.9297 25.76C26.1197 26.51 25.8497 27.67 26.2597 28.7C26.6597 29.73 27.6397 30.4 28.7497 30.4H33.0797V34.88C33.0797 36.22 34.1697 37.31 35.5097 37.31H38.3197C39.6597 37.31 40.7497 36.22 40.7497 34.88V30.4H45.0797C46.1897 30.4 47.1697 29.73 47.5697 28.7C47.9697 27.67 47.7097 26.51 46.8897 25.76L44.9397 23.95C45.4297 23.65 45.8197 23.2 46.0297 22.64C46.4297 21.61 46.1697 20.45 45.3497 19.7L43.5697 18.06C43.9097 17.78 44.1797 17.41 44.3497 16.97C44.7497 15.94 44.4897 14.78 43.6797 14.03L38.7197 9.43998C38.2197 8.97998 37.5797 8.72998 36.8997 8.72998H36.9097Z"
            fill="black"
          />
        </g>
      );
    case ImpactCategory.WATER_USE:
      return <></>;
  }
}

function impactCategoryTitleFontSize(
  impactCategory: ImpactCategory,
  locale: string | null
): number {
  if (locale === "de-DE" && impactCategory === ImpactCategory.WATER_USE) {
    return 10;
  } else if (
    locale === "fr-FR" &&
    (impactCategory === ImpactCategory.LAND_USE ||
      impactCategory === ImpactCategory.WATER_USE)
  ) {
    return 9;
  } else if (
    locale === "nl-NL" &&
    (impactCategory === ImpactCategory.LAND_USE ||
      impactCategory === ImpactCategory.WATER_USE)
  ) {
    return 12;
  }
  return 13;
}

function impactRatingFontSize(
  impactRating: ImpactRating,
  locale: string | null
): number {
  if (locale === "de-DE" && impactRating === ImpactRating.VLOW) {
    return 14;
  }
  return 16;
}
