import VectorProps from "../VectorProps";

export default function Farm(props: VectorProps) {
  const { className = "", width } = props;
  // from https://www.figma.com/design/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Design-Library?node-id=3271-4085&node-type=frame&t=kGFmEBzkCdUXZLHP-0
  return (
    <svg
      className={className}
      width={width}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="FS_2023_icontests_LCA_A-81 1">
        <path
          id="Vector"
          d="M10.7202 21.1202C16.5082 21.1202 21.2002 16.4281 21.2002 10.6402C21.2002 4.85221 16.5082 0.160156 10.7202 0.160156C4.93229 0.160156 0.240234 4.85221 0.240234 10.6402C0.240234 16.4281 4.93229 21.1202 10.7202 21.1202Z"
          fill="#67DCC2"
        />
        <g id="Group">
          <path
            id="Vector_2"
            d="M26.7971 18.1475V28.6707C26.7971 29.8915 25.8083 30.8803 24.5875 30.8803H7.41468C6.19388 30.8803 5.20508 29.8915 5.20508 28.6707V18.1475"
            fill="#00A68F"
          />
          <path
            id="Vector_3"
            d="M24.5876 31.7604H7.4148C5.7108 31.7604 4.3252 30.3748 4.3252 28.6708V18.1476C4.3252 17.6612 4.7188 17.2676 5.2052 17.2676C5.69159 17.2676 6.08519 17.6612 6.08519 18.1476V28.6708C6.08519 29.4036 6.682 30.0004 7.4148 30.0004H24.5876C25.3204 30.0004 25.9172 29.4036 25.9172 28.6708V18.1476C25.9172 17.6612 26.3108 17.2676 26.7972 17.2676C27.2836 17.2676 27.6772 17.6612 27.6772 18.1476V28.6708C27.6772 30.3748 26.2916 31.7604 24.5876 31.7604Z"
            fill="black"
          />
        </g>
        <g id="Group_2">
          <path
            id="Vector_4"
            d="M15.9628 21.4766H16.3148C18.566 21.4766 20.3932 23.3038 20.3932 25.555V30.8094H11.8828V25.555C11.8828 23.3038 13.71 21.4766 15.9612 21.4766H15.9628Z"
            fill="#DCFFD1"
          />
          <path
            id="Vector_5"
            d="M20.3953 31.6914H11.8849C11.3985 31.6914 11.0049 31.2978 11.0049 30.8114V25.557C11.0049 22.8226 13.2289 20.5986 15.9633 20.5986H16.3153C19.0497 20.5986 21.2737 22.8226 21.2737 25.557V30.8114C21.2737 31.2978 20.8801 31.6914 20.3937 31.6914H20.3953ZM12.7649 29.9314H19.5153V25.557C19.5153 23.7938 18.0801 22.3586 16.3169 22.3586H15.9649C14.2017 22.3586 12.7665 23.7938 12.7665 25.557V29.9314H12.7649Z"
            fill="black"
          />
        </g>
        <g id="Group_3">
          <path
            id="Vector_6"
            d="M9.48611 16.1945C9.48611 17.9929 8.02851 19.4521 6.22851 19.4521C6.16451 19.4521 6.10051 19.4505 6.0365 19.4473C3.95331 19.3273 2.61731 17.1593 3.33571 15.1993L6.37091 6.9193C6.73251 5.9353 7.66851 5.2793 8.71811 5.2793H23.5165C24.5853 5.2793 25.5373 5.9593 25.8829 6.9721L28.7181 15.2873C29.2653 16.8937 28.4685 18.6889 26.8701 19.2617C26.5261 19.3849 26.1565 19.4521 25.7693 19.4521C23.9709 19.4521 22.5117 17.9945 22.5117 16.1945"
            fill="#00A68F"
          />
          <path
            id="Vector_7"
            d="M25.7724 20.3326C23.4909 20.3326 21.6348 18.4766 21.6348 16.195C21.6348 15.7086 22.0285 15.315 22.5149 15.315C23.0012 15.315 23.3948 15.7086 23.3948 16.195C23.3948 17.5054 24.462 18.5726 25.7724 18.5726C26.0492 18.5726 26.3197 18.5262 26.5757 18.4334C27.6989 18.0302 28.2877 16.7454 27.8877 15.571L25.0525 7.25577C24.8285 6.59977 24.2125 6.15817 23.5196 6.15817H8.71965C8.04285 6.15817 7.43165 6.58537 7.19965 7.22057L4.16445 15.5006C3.91325 16.187 4.00285 16.9646 4.40605 17.5806C4.79165 18.1678 5.40445 18.5278 6.08925 18.5678C6.13565 18.571 6.18205 18.5726 6.23005 18.5726C7.54045 18.5726 8.60765 17.5054 8.60765 16.195C8.60765 15.7086 9.00125 15.315 9.48765 15.315C9.97405 15.315 10.3677 15.7086 10.3677 16.195C10.3677 18.4766 8.51165 20.3326 6.23005 20.3326C6.14845 20.3326 6.06845 20.331 5.98845 20.3262C4.74205 20.2542 3.62845 19.6062 2.93405 18.5486C2.22685 17.4702 2.06845 16.1054 2.51005 14.8974L5.54525 6.61737C6.03165 5.29097 7.30685 4.40137 8.71805 4.40137H23.5164C24.9628 4.40137 26.2477 5.32137 26.7148 6.68937L29.5501 15.0046C30.2589 17.083 29.1901 19.3662 27.1677 20.091C26.7197 20.251 26.2493 20.3326 25.7692 20.3326H25.7724Z"
            fill="black"
          />
        </g>
        <g id="Group_4">
          <path
            id="Vector_8"
            d="M20.7216 5.28027L22.5152 16.1955C22.5152 17.9939 21.0576 19.4531 19.2576 19.4531C17.4576 19.4531 16 17.9955 16 16.1955V5.28027H20.7216Z"
            fill="#10675B"
          />
          <path
            id="Vector_9"
            d="M19.2577 20.3332C16.9761 20.3332 15.1201 18.4772 15.1201 16.1956V5.28039C15.1201 4.79399 15.5137 4.40039 16.0001 4.40039H20.7217C21.1521 4.40039 21.5201 4.71239 21.5905 5.13799L23.3841 16.0532C23.3921 16.0996 23.3953 16.1476 23.3953 16.1956C23.3953 18.4772 21.5393 20.3332 19.2577 20.3332ZM16.8801 6.16039V16.1956C16.8801 17.506 17.9473 18.5732 19.2577 18.5732C20.5681 18.5732 21.5985 17.5428 21.6337 16.2628L19.9745 6.16039H16.8801Z"
            fill="black"
          />
        </g>
        <g id="Group_5">
          <path
            id="Vector_10"
            d="M9.48633 16.1945C9.48633 17.9929 10.9439 19.4521 12.7439 19.4521C14.5439 19.4521 16.0015 17.9945 16.0015 16.1945"
            fill="#00A68F"
          />
          <path
            id="Vector_11"
            d="M12.744 20.3325C10.4672 20.3325 8.61445 18.4845 8.60645 16.2093C8.60645 16.1741 8.60645 16.1389 8.61125 16.1037L9.75845 5.18846C9.80965 4.70526 10.2432 4.35646 10.7248 4.40606C11.1776 4.45406 11.5136 4.83486 11.512 5.27966C11.512 5.31006 11.512 5.34046 11.5072 5.37246L10.3648 16.2381C10.3872 17.5293 11.4448 18.5725 12.7424 18.5725C14.04 18.5725 15.12 17.5053 15.12 16.1949C15.12 15.7085 15.5136 15.3149 16 15.3149C16.4864 15.3149 16.88 15.7085 16.88 16.1949C16.88 18.4765 15.024 20.3325 12.7424 20.3325H12.744Z"
            fill="black"
          />
        </g>
        <g id="Group_6">
          <path
            id="Vector_12"
            d="M9.5281 16.1945C9.5281 17.9929 8.0705 19.4521 6.2705 19.4521C6.2065 19.4521 6.1425 19.4505 6.0785 19.4473C3.9953 19.3273 2.6593 17.1593 3.3777 15.1993L6.4129 6.9193C6.7745 5.9353 7.7105 5.2793 8.7601 5.2793H10.6481L9.5265 16.1945H9.5281Z"
            fill="#10675B"
          />
          <path
            id="Vector_13"
            d="M6.27009 20.3326C6.18849 20.3326 6.10849 20.331 6.02849 20.3262C4.78209 20.2542 3.66849 19.6062 2.97409 18.5486C2.26689 17.4702 2.10849 16.1054 2.55009 14.8974L5.58529 6.61737C6.07169 5.29097 7.34689 4.40137 8.75809 4.40137H10.6461C10.8957 4.40137 11.1325 4.50697 11.2989 4.69097C11.4653 4.87657 11.5469 5.12297 11.5213 5.37097L10.4045 16.2446C10.3789 18.5038 8.53249 20.3342 6.26689 20.3342L6.27009 20.3326ZM8.76129 6.15977C8.08449 6.15977 7.47329 6.58697 7.24129 7.22217L4.20609 15.5022C3.95489 16.1886 4.04449 16.9662 4.44769 17.5822C4.83329 18.1694 5.44609 18.5294 6.13089 18.5694C6.17729 18.5726 6.22369 18.5742 6.27169 18.5742C7.58209 18.5742 8.64929 17.507 8.64929 16.1966C8.64929 16.1662 8.64929 16.1358 8.65409 16.107L9.67489 6.16137H8.76129V6.15977Z"
            fill="black"
          />
        </g>
      </g>
    </svg>
  );
}
