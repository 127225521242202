import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import useProductsDashboardOrganizationOptions from "../../data-store/useProductsDashboardOrganizationOptions";
import FilterButton from "../filter-dropdown/FilterButton";
import { useOrganization } from "../organizations/OrganizationProvider";
import StatusDisplay from "../StatusDisplay";
import { PrimaryButton, SecondaryButton } from "../utils/Button";
import SearchBox from "../utils/SearchBox";
import {
  DashboardOrganization,
  useDashboardOrganization,
} from "./DashboardOrganizationProvider";
import "./DashboardOrganizationFilter.css";
import "../utils/RadioButtons.css";

export function DashboardOrganizationFilter() {
  const [productsDashboardOrganizationOptionsStatus] =
    useProductsDashboardOrganizationOptions();
  const [organization] = useOrganization();
  const [dashboardOrganization, setDashboardOrganization] =
    useDashboardOrganization();
  const [selectedOrganization, setSelectedOrganization] =
    useState<DashboardOrganization | null>(null);

  const removeAppliedFiltersRef = React.createRef<HTMLDivElement>();
  const productFilterSelectRef = useRef<HTMLDivElement>(null);

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    // Don't open / close menu if the remove filter 'x' part of the button is clicked
    e.currentTarget.blur();
    if (
      removeAppliedFiltersRef.current == null ||
      (removeAppliedFiltersRef.current &&
        !removeAppliedFiltersRef.current.contains(e.target as Node))
    ) {
      if (!menuIsOpen) {
        setMenuIsOpen(true);
      } else {
        setMenuIsOpen(false);
      }
    }
  };

  // Close the menu when the user clicks outside of it
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        productFilterSelectRef.current &&
        !productFilterSelectRef.current.contains(event.target as Node) &&
        !document.body.classList.contains("modal-open")
      ) {
        setMenuIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setMenuIsOpen]);

  const applyFilter = () => {
    if (selectedOrganization === null) {
      throw new Error("selectedOrganization should not be null");
    }
    setDashboardOrganization(selectedOrganization);
    setMenuIsOpen(false);
  };

  const clearFilter = () => {
    setSelectedOrganization(null);
    setDashboardOrganization(organization);
    setMenuIsOpen(false);
  };

  return (
    <StatusDisplay status={productsDashboardOrganizationOptionsStatus}>
      {(productsDashboardOrganizationOptions) => (
        <div ref={productFilterSelectRef} className="react-select-container">
          <FilterButton
            currentNumberOfFilters={
              organization.id === dashboardOrganization?.id ? 0 : 1
            }
            handleClick={handleClick}
            menuIsOpen={menuIsOpen}
            onRemoveAppliedFiltersClick={clearFilter}
            removeAppliedFiltersRef={removeAppliedFiltersRef}
          />
          {menuIsOpen && (
            <Menu
              applyFilter={applyFilter}
              clearFilter={clearFilter}
              onChange={setSelectedOrganization}
              options={productsDashboardOrganizationOptions}
              selectedOrganization={selectedOrganization}
            />
          )}
        </div>
      )}
    </StatusDisplay>
  );
}

interface MenuProps {
  applyFilter: () => void;
  clearFilter: () => void;
  options: Array<DashboardOrganization>;
  onChange: (organization: DashboardOrganization) => void;
  selectedOrganization: DashboardOrganization | null;
}

function Menu(props: MenuProps) {
  const { applyFilter, clearFilter, onChange, options, selectedOrganization } =
    props;

  const intl = useIntl();

  const [search, setSearch] = useState("");

  const filteredOptions = options.filter((option) =>
    option.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="DashboardOrganizationFilter_Menu react-select__menu">
      <div className="DashboardOrganizationFilter_Menu_Header">
        <h4>
          <FormattedMessage
            id="components/dashboard/DashboardOrganizationFilter:filterByOrganization"
            defaultMessage="Filter by organisation"
          />
        </h4>
      </div>
      <div className="DashboardOrganizationFilter_Menu_Search">
        <SearchBox
          onChange={setSearch}
          placeholder={intl.formatMessage({
            id: "components/dashboard/DashboardOrganizationFilter:searchOrganizations",
            defaultMessage: "Search organisations",
          })}
          value={search}
        />
      </div>
      {filteredOptions.map((option) => (
        <Option
          checked={selectedOrganization?.id === option.id}
          option={option}
          onSelect={onChange}
        />
      ))}
      <hr className="DashboardOrganizationFilter_Divider" />
      <div className="DashboardOrganizationFilter_Menu_Controls">
        <PrimaryButton onClick={applyFilter}>
          <FormattedMessage
            id="components/dashboard/DashboardOrganizationFilter:apply"
            defaultMessage="Apply"
          />
        </PrimaryButton>
        <SecondaryButton onClick={clearFilter}>
          <FormattedMessage
            id="components/dashboard/DashboardOrganizationFilter:clearFilters"
            defaultMessage="Clear filters"
          />
        </SecondaryButton>
      </div>
    </div>
  );
}

interface OptionProps {
  checked: boolean;
  option: DashboardOrganization;
  onSelect: (organization: DashboardOrganization) => void;
}

function Option(props: OptionProps) {
  const { checked, onSelect, option } = props;

  const [hover, setHover] = useState(false);

  const inputClassNames = classNames("radio-button-input", {
    "radio-button-input-hover": hover,
  });

  return (
    <div
      className={classNames("DashboardOrganizationFilter_Option", {
        DashboardOrganizationFilter_Option__checked: checked,
        DashboardOrganizationFilter_Option__hover: hover,
      })}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => onSelect(option)}
    >
      <input
        checked={checked}
        className={inputClassNames}
        type="radio"
        id={option.id}
        value={option.id}
        name={option.name}
      />
      <label
        className={classNames("DashboardOrganizationFilter_Option_Label", {
          DashboardOrganizationFilter_Option__hover: hover,
        })}
        htmlFor={option.id}
      >
        {option.name}
      </label>
    </div>
  );
}
