import { FormattedMessage } from "react-intl";

import LargeNumber from "../typography/LargeNumber";
import SummaryCard from "../utils/SummaryCard";
import "./ReportSummaryCard.css";
import { ShortDownArrow, ShortUpArrow } from "../utils/Vectors";

interface ReportSummaryCardProps {
  icon: React.ReactNode;
  title: React.ReactNode;
  unit?: React.ReactNode;
  value: number | string;
  comparisonAssessmentName?: string | null;
  proportionChange?: number | null;
}

export default function ReportSummaryCard(props: ReportSummaryCardProps) {
  const {
    icon,
    title,
    unit,
    value,
    comparisonAssessmentName,
    proportionChange,
  } = props;

  const showComparison = comparisonAssessmentName && proportionChange;

  return (
    <SummaryCard
      className={
        showComparison
          ? "ReportSummaryCard_WithComparison"
          : "ReportSummaryCard"
      }
    >
      <div className="ReportSummaryCard_Icon">{icon}</div>
      <div>
        <div className="medium-font">{title}</div>
        <div className="ReportSummaryCard_TotalImpact">
          <LargeNumber>{value}</LargeNumber>
          <small>{unit}</small>
        </div>
      </div>
      {showComparison && (
        <div className="ReportSummaryCard_Comparison">
          <PercentageLozenge value={proportionChange * 100} />
          <div className="ReportSummaryCard_ComparisonText small-copy">
            <FormattedMessage
              id="components/scope-3/ReportSummaryCard:comparisonText"
              defaultMessage="since {comparisonAssessmentName}"
              values={{ comparisonAssessmentName }}
            />
          </div>
        </div>
      )}
    </SummaryCard>
  );
}

interface PercentageLozengeProps {
  value: number;
}

function PercentageLozenge(props: PercentageLozengeProps) {
  const { value } = props;
  const Icon = value > 0 ? ShortUpArrow : ShortDownArrow;

  return (
    <div className="PercentageLozenge">
      <Icon className="Arrow" width={16} />
      <div className="small-copy-medium">{value.toFixed(0)}%</div>
    </div>
  );
}
