import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";

import { RecipeOrder } from "../../__generated__/globalTypes";
import { DashboardProduct } from "../../data-store";
import { impactCategoryToEffectTypePerKg } from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import { useTags } from "../../services/useOrganizationFeatures";
import assertNever from "../../util/assertNever";
import { buildPath, usePages } from "../pages";
import { parseRecipeOrder } from "../recipes/RecipeQueryControls";
import { impactRating } from "../scope-3/helperFunctions";
import { LinkButton } from "../utils/Button";
import { ArrowRight } from "../utils/Vectors";
import { impactIntensityLetterRatingLabel } from "./helperFunctions";
import "./HighestAndLowestImpactProducts.css";

interface HighestAndLowestImpactProductsProps {
  highestImpactProduct: DashboardProduct | null;
  impactCategory: ImpactCategory;
  lowestImpactProduct: DashboardProduct | null;
}

export default function HighestAndLowestImpactProducts(
  props: HighestAndLowestImpactProductsProps
) {
  const { highestImpactProduct, impactCategory, lowestImpactProduct } = props;
  return (
    <div className="HighestAndLowestImpactProductsCard">
      {highestImpactProduct !== null && (
        <HighestOrLowestProductImpactCard
          impactCategory={impactCategory}
          product={highestImpactProduct}
          productsPageLinkOrderBy={recipeOrderByImpactIntensity(
            impactCategory,
            false
          )}
          title={
            <FormattedMessage
              id="components/scope-3/HighestAndLowestImpactProducts:highestImpactProduct"
              defaultMessage="Highest Impact Product"
            />
          }
        />
      )}
      {lowestImpactProduct !== null && (
        <HighestOrLowestProductImpactCard
          impactCategory={impactCategory}
          product={lowestImpactProduct}
          productsPageLinkOrderBy={recipeOrderByImpactIntensity(
            impactCategory,
            true
          )}
          title={
            <FormattedMessage
              id="components/scope-3/HighestAndLowestImpactProducts:lowestImpactProduct"
              defaultMessage="Lowest Impact Product"
            />
          }
        />
      )}
    </div>
  );
}

interface HighestOrLowestProductImpactCardProps {
  impactCategory: ImpactCategory;
  product: DashboardProduct;
  productsPageLinkOrderBy: RecipeOrder;
  title: React.ReactNode;
}

function HighestOrLowestProductImpactCard(
  props: HighestOrLowestProductImpactCardProps
) {
  const { impactCategory, product, productsPageLinkOrderBy, title } = props;
  const history = useHistory();
  const pages = usePages();
  const hasFeatureTags = useTags();

  const [recipePageParams] = pages.RecipesListPage.useQueryParams();
  const path = buildPath(
    pages.Recipes(hasFeatureTags).url,
    pages.RecipesListPage.stringifyQueryParams({
      ...recipePageParams,
      orderBy: parseRecipeOrder(productsPageLinkOrderBy)!,
      view: "list",
    })
  );
  return (
    <div className="HighestOrLowestProductImpactCard">
      <div className="medium-font">{title}</div>
      <ProductImpactCard impactCategory={impactCategory} product={product} />
      <div className="HighestOrLowestProductImpactCard__ViewMoreLinkContainer">
        <LinkButton
          className="HighestOrLowestProductImpactCard__ViewMoreLink"
          onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            e.preventDefault();
            history.push(path);
          }}
        >
          <FormattedMessage
            id="components/scope-3/HighestAndLowestImpactProducts:viewMore"
            defaultMessage="View More"
          />
          <ArrowRight width={16} />
        </LinkButton>
      </div>
    </div>
  );
}

interface ProductImpactCardProps {
  impactCategory: ImpactCategory;
  product: DashboardProduct;
}

function ProductImpactCard(props: ProductImpactCardProps) {
  const { impactCategory, product } = props;
  const pages = usePages();
  const hasFeatureTags = useTags();

  const effectType = impactCategoryToEffectTypePerKg(impactCategory);
  const productImpactIntensity = impactIntensity(
    product.impactCached,
    impactCategory
  );
  const productImpactRating = impactRating(
    product.impactCached,
    impactCategory
  );
  return (
    <div className="ProductImpactCard">
      <LinkButton
        className="ProductImpactCard__RecipeLink"
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          e.preventDefault();
          window.open(pages.Recipe(hasFeatureTags).url(product), "_blank");
        }}
      >
        {product.name}
      </LinkButton>
      {productImpactIntensity !== null && (
        <div className="ProductImpactCard__ImpactInfo">
          <div className="ProductImpactCard__ImpactInfo__ValueAndUnit">
            <div className="ProductImpactCard__ImpactInfo__value">
              {productImpactIntensity.toFixed(2)}
            </div>
            <div className="ProductImpactCard__ImpactInfo__unit small-copy">
              {effectType.unit}
            </div>
          </div>
          <div className="ProductImpactCard__ImpactInfo__icon">
            {productImpactRating !== null &&
              impactIntensityLetterRatingLabel(
                impactCategory,
                productImpactRating
              )}
          </div>
        </div>
      )}
    </div>
  );
}

function recipeOrderByImpactIntensity(
  impactCategory: ImpactCategory,
  ascending: boolean
): RecipeOrder {
  switch (impactCategory) {
    case ImpactCategory.GHG:
      return ascending
        ? RecipeOrder.CACHED_GHG_PER_KG_ASC
        : RecipeOrder.CACHED_GHG_PER_KG_DESC;
    case ImpactCategory.LAND_USE:
      return ascending
        ? RecipeOrder.CACHED_LAND_USE_PER_KG_ASC
        : RecipeOrder.CACHED_LAND_USE_PER_KG_DESC;
    case ImpactCategory.WATER_USE:
      return ascending
        ? RecipeOrder.CACHED_WATER_USE_PER_KG_ASC
        : RecipeOrder.CACHED_WATER_USE_PER_KG_DESC;
  }
}

function impactIntensity(
  impact: {
    ghgPerKg: number | null;
    landUsePerKg: number | null;
    waterUsePerKg: number | null;
  },
  impactCategory: ImpactCategory
) {
  if (impactCategory === ImpactCategory.GHG) {
    return impact.ghgPerKg;
  } else if (impactCategory === ImpactCategory.LAND_USE) {
    return impact.landUsePerKg;
  } else if (impactCategory === ImpactCategory.WATER_USE) {
    return impact.waterUsePerKg;
  } else {
    assertNever(impactCategory, "Unsupported impact category");
  }
}
