import ProductsByDietChart from "./ProductsByDietChart";

const stories = {
  name: "ProductsByDietChart",
  render: () => <Story />,
};

function Story() {
  const dietaryCategories = [
    {
      name: "Vegan",
      productCount: 20,
    },
    {
      name: "Red Meat",
      productCount: 40,
    },
    {
      name: "Vegetarian",
      productCount: 2,
    },
    {
      name: "Surrealism",
      productCount: 102,
    },
    {
      name: "Beefy",
      productCount: 15,
    },
  ];

  return <ProductsByDietChart dietaryCategories={dietaryCategories} />;
}

export default stories;
