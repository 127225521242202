import { useCallbackOne } from "use-memo-one";

import { useOrganizationId } from "../components/organizations/OrganizationProvider";
import usePromise from "../components/utils/usePromise";
import { useDataStore } from "./index";

export default function useProductsDashboardOrganizationOptions() {
  const dataStore = useDataStore();
  const [organizationId] = useOrganizationId();

  const load = useCallbackOne(async () => {
    return await dataStore.fetchProductsDashboardOrganizationOptions(
      organizationId
    );
  }, [dataStore, organizationId]);

  return usePromise(load);
}
